import numeral from 'numeral';

import { MatchLevel, MatchSessionStatus } from '@/models/MatchSession';
import { SetGender } from '@/models/Set';

export const getMatchLevelName = (level: MatchLevel) => {
  switch (level) {
    case MatchLevel.happyHammy:
      return 'Happy Hammy Match';
    case MatchLevel.mini:
      return 'Mini Match';
    case MatchLevel.longDistance:
      return 'Long Distance Match';
    default:
      return 'Perfect Match';
  }
};

export const getMatchLevelDescription = (level: MatchLevel) => {
  switch (level) {
    case MatchLevel.happyHammy:
      return 'Almost perfect, but with a touch of flexibility! The happy match considers all aspects, except for the style of clothing and swapping method. You’ll still be delighted with the variety and quality of the clothes you receive or give.';
    case MatchLevel.mini:
      return 'The mini match option gives you some leeway by excluding certain criteria. It matches everything except the number of items, types of clothing, style of clothing, and swapping method.';
    case MatchLevel.longDistance:
      return 'Crossing boundaries and connecting hearts! The long distance match caters to those who are open to exchanging clothes beyond their immediate location. It excludes location and swapping method, meaning your match will be either Interstate, or not in your post code or surrounding suburbs. Postage costs included in Connection charge (A$24.99).';
    default:
      return 'It’s a match made in heaven! With the perfect match, you’ll find all clothing criteria is aligned. Whether you’re giving or receiving, rest assured that all your clothing needs will be fulfilled.';
  }
};

export const getSetGender = (gender: SetGender): string => {
  switch (gender) {
    case SetGender.male:
      return 'Male';
    case SetGender.female:
      return 'Female';
    case SetGender.neutral:
    default:
      return 'Neutral';
  }
};

export const getMatchSessionStatus = (status: MatchSessionStatus): string => {
  switch (status) {
    case MatchSessionStatus.waitingApproval:
      return 'Waiting to Accept';
    case MatchSessionStatus.swapping:
      return 'Swapping';
    case MatchSessionStatus.done:
      return 'Done';
    case MatchSessionStatus.canceled:
    case MatchSessionStatus.decline:
      return 'Canceled';
    case MatchSessionStatus.waiting:
    default:
      return 'Waiting';
  }
};

export const getAusCurrency = (inp: number, fmt: string = '$0.00') => {
  return `A${numeral(inp).format(fmt)}`;
};
