import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { autorun } from 'mobx';
import { forwardRef, ReactNode, useEffect, useState } from 'react';
import { Badge, Container, Dropdown, Nav, Navbar, NavLinkProps } from 'react-bootstrap';
import { NavbarToggleProps } from 'react-bootstrap/esm/NavbarToggle';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import ImgLogo from '@/assets/images/logo.png';
import { PROFILE } from '@/constants/apis';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';

const LogoText = styled.div`
  font-family: 'Lobster Two', sans-serif;
`;

const CustomNavLink = styled(Nav.Link)<NavLinkProps>`
  text-decoration: none;

  &.active {
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: #f8c400;
    }
  }

  @media (max-width: 767.98px) {
    &.active {
      font-weight: 700;

      ::after {
        width: 3px;
        height: 100%;
      }
    }
  }
`;

const NavbarToggle = styled(Navbar.Toggle)<NavbarToggleProps>`
  font-size: 2rem;
  box-shadow: none !important;
`;

const CustomBadge = styled(Badge)`
  top: -8px;
  left: 8px;
  padding: 2px 4px;
  font-size: 10px;
`;

const CustomToggle = forwardRef<
  unknown,
  {
    children: ReactNode;
    onClick: (e: any) => void;
  }
>(({ children, onClick }, ref: any) => (
  <span ref={ref} onClick={onClick} role="button">
    {children}
  </span>
));

const HeaderProfile = ({ onLogout }: { onLogout: () => void }) => {
  const { wsStore } = useStore();

  const [totalNotificaitons, setTotalNotificaitons] = useState<number>(0);

  const [loadingNotifications, getNotifications] = useApi<
    {
      total: number;
    },
    unknown
  >({
    url: `${PROFILE}/_notifications`,
  });

  useEffect(() => {
    autorun(() => {
      if (_.isNil(wsStore.triggerNotifications)) {
        return;
      }

      loadTotalNotifications();

      wsStore.setTriggerNotifications(undefined);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadTotalNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTotalNotifications = async () => {
    if (loadingNotifications) {
      return;
    }

    try {
      const { data } = await getNotifications();

      setTotalNotificaitons(data.total);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Navbar bg="light" expand="lg" className="py-4 bg-white">
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex">
          <div className="me-0 me-md-3">
            <img src={ImgLogo} width={66} alt="" />
          </div>
          <LogoText className="d-none d-md-inline">
            High End
            <br />
            Hammy downs
          </LogoText>
        </Navbar.Brand>
        <NavbarToggle className="border-0 p-0" />
        <Navbar.Collapse id="navbar">
          <Nav className="mt-4 mt-md-0 ms-auto">
            <CustomNavLink className="position-relative px-4 py-2 text-black" as={NavLink} to="/">
              Home
            </CustomNavLink>
            <CustomNavLink
              className="position-relative px-4 py-2 text-black"
              as={NavLink}
              to="/matches"
            >
              Matches
            </CustomNavLink>
            <Dropdown className="position-relative px-4 py-2 text-black">
              <Dropdown.Toggle as={CustomToggle}>About</Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 mt-md-0 shadow-sm bg-light">
                <Dropdown.Item as={NavLink} to="/about">
                  About Us
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/events">
                  Events
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/blog">
                  Blog / News
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <CustomNavLink
              className="position-relative px-4 py-2 text-black"
              as={NavLink}
              to="/terms-and-conditions"
            >
              T&amp;Cs
            </CustomNavLink>
            <CustomNavLink
              className="position-relative px-4 py-2 text-black"
              as={NavLink}
              to="/faqs"
            >
              FAQs
            </CustomNavLink>
            <Dropdown className="d-block d-md-none position-relative px-4 py-2 text-black">
              <Dropdown.Toggle as={CustomToggle}>Profile</Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 mt-md-0 shadow-sm bg-light">
                <Dropdown.Item as={NavLink} to="/me/overview">
                  Overview
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/me/match-sessions">
                  Notifications
                </Dropdown.Item>
                <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <div className="d-none d-md-flex ms-3">
            <div className="position-relative me-4">
              <Link to="/me/match-sessions" className="text-dark">
                <FontAwesomeIcon icon="message" />
                {totalNotificaitons > 0 && (
                  <CustomBadge bg="warning" className="position-absolute rounded-0">
                    {totalNotificaitons}
                  </CustomBadge>
                )}
              </Link>
            </div>
            <Dropdown align="end">
              <Dropdown.Toggle as={CustomToggle}>
                <FontAwesomeIcon icon="user" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-0 shadow-sm bg-light">
                <Dropdown.Item as={Link} to="/me/overview">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={onLogout}>Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderProfile;
