import { Col, Container, Image, Ratio, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ImgClothesBox from '@/assets/images/clothes_box.png';
import ImgClothesHanger from '@/assets/images/clothes_hanger.png';
import ImgBanner from '@/assets/images/yellow_clothes.png';

const Banner = styled.div`
  padding: 4em 0;
  color: #ffffff;
  background-color: #fee164;
  background-image: url(${ImgBanner});
  background-position: right 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const BannerTitle = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 4.5rem;
  line-height: normal;
`;

const BannerSubTitle = styled.div`
  font-size: 2.5rem;
  line-height: normal;
`;

const EventsPage = () => {
  return (
    <>
      <Banner>
        <Container>
          <div className="d-inline-block text-dark">
            <BannerTitle className="mb-1 fw-bold">Events</BannerTitle>
            <BannerSubTitle className="fw-normal">Some of our events</BannerSubTitle>
          </div>
        </Container>
      </Banner>
      <Container>
        <div className="my-5 py-5">
          <div className="mb-5 h5">
            Hammys offers a range of events that facilitate and support clothes swapping. From
            pop-up swaps to educational events, there is an event for you! Check out our upcoming
            and past events.
          </div>
          {/* <div className="mb-5">
            <div className="mb-4 h5 fw-bold">See some snippets from our past events</div>
            <Row>
              <Col>
                <Ratio aspectRatio="16x9">
                  <iframe
                    src="https://www.youtube.com/embed/ZsTmMiD2bYo"
                    title="High End Hammy Downs"
                    className="rounded bg-black"
                  />
                </Ratio>
              </Col>
              <Col>
                <Ratio aspectRatio="16x9">
                  <iframe
                    src="https://www.youtube.com/embed/6KgU81ILSuk"
                    title="High End Hammy Downs"
                    className="rounded bg-black"
                  />
                </Ratio>
              </Col>
              <Col>
                <Ratio aspectRatio="16x9">
                  <iframe
                    src="https://www.youtube.com/embed/xVVurVNciG4"
                    title="High End Hammy Downs"
                    className="rounded bg-black"
                  />
                </Ratio>
              </Col>
            </Row>
          </div> */}
          <div className="mb-5">
            <div className="mb-3 h5 fw-bold">Upcoming Events</div>
            <Row className="g-0 shadow-sm position-relative bg-white">
              <Col xs={4}>
                <Ratio aspectRatio="4x3">
                  <Image src={ImgClothesBox} alt="" className="object-fit-cover" />
                </Ratio>
              </Col>
              <Col className="p-4 d-flex flex-column position-static">
                <div className="mb-3 fw-bold h3">Swapping Clothes For Children</div>
                <div className="mb-1 text-dark">Wednesday, 15th of March 2023</div>
                <div className="mb-3 text-dark">
                  7:00AM – 10:00AM - ＠Emporium Hotels, South Brisbane
                </div>
                {/* <p className="card-text mb-auto">
                  It’s that time again! Our sell-out International Women’s Day event is just around
                  the corner and we would love to celebrate with you.
                </p>
                <Link to="/events" className="stretched-link text-dark">
                  Learn more »
                </Link> */}
              </Col>
            </Row>
          </div>
          <div className="mt-0">
            <div className="mb-3 h5 fw-bold">Past Events</div>
            <Row className="g-0 shadow-sm position-relative bg-white">
              <Col xs={4}>
                <Ratio aspectRatio="4x3">
                  <Image src={ImgClothesHanger} alt="" className="object-fit-cover" />
                </Ratio>
              </Col>
              <Col className="p-4 d-flex flex-column position-static">
                <div className="mb-3 fw-bold h3">Warming Winter</div>
                <div className="mb-1 text-dark">Friday, 4th of November 2022</div>
                <div className="mb-3 text-dark">
                  6:00PM – 11:00PM ＠Brisbane Convention & Exhibition Centre
                </div>
                {/* <p className="card-text mb-auto">
                  The Women in Digital Awards were founded in 2018 based on the idea that ‘you can’t
                  be what you can’t see’. Since 2018 we have championed over 51 WID award winners,
                  325 finalists and 980 nominees in their careers, and we’re just getting started.
                  Join us on the 4th of November and champion some of the best in the business.
                </p>
                <Link to="/events" className="stretched-link text-dark">
                  Learn more »
                </Link> */}
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EventsPage;
