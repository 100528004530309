import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const app = initializeApp({
  apiKey: 'AIzaSyBNbc1wDZtM1AmBrKicDZ8HaVkiQ0WSbjw',
  authDomain: 'hehd-2022.firebaseapp.com',
  projectId: 'hehd-2022',
  storageBucket: 'hehd-2022.appspot.com',
  messagingSenderId: '209881278113',
  appId: '1:209881278113:web:2364c896dcb9a8d67872e0',
});

export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);

export default app;
