import _ from 'lodash';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';

import ImgBgSignUpInfo from '@/assets/images/bg_signup_info.png';
import { Country } from '@/enums/CountryState';
import { SwappingMethod, UserAboutForm } from '@/models/User';

const LeftImage = styled.div`
  height: 100%;
  max-height: 600px;
  background-color: #dddddd;
  background-image: url(${ImgBgSignUpInfo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const FormWrapper = styled.div`
  min-height: 300px;
`;

const AboutYou = ({
  data,
  swappingMethods,
  onNextClicked,
}: {
  data: UserAboutForm;
  swappingMethods: SwappingMethod[];
  onNextClicked: () => void;
}) => {
  const [isRequiredAddress, setIsRequiredAddress] = useState<boolean>(false);

  const handleSwappingMethodChange = useCallback(() => {
    const val = _.some(
      swappingMethods,
      (s) => _.includes(data.swappingMethodIds, s.id) && s.isRequiredAddress
    );

    setIsRequiredAddress(val);
  }, [data.swappingMethodIds, swappingMethods]);

  useEffect(() => {
    handleSwappingMethodChange();
  }, [handleSwappingMethodChange]);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    onNextClicked();
  };

  return (
    <Row>
      <Col>
        <LeftImage />
      </Col>
      <Col>
        <Form onSubmit={handleFormSubmit}>
          <FormWrapper>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    defaultValue={data.country}
                    onChange={(e) => (data.country = e.target.value as Country)}
                    required
                  >
                    {_(Country)
                      .map((val: string, i) => (
                        <option value={val} key={i}>
                          {val}
                        </option>
                      ))
                      .value()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Postcode</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={data.postcode}
                    onChange={(e) => (data.postcode = e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              {!_.isEmpty(swappingMethods) && (
                <Form.Group className="mb-4">
                  <Form.Label>Swapping Method</Form.Label>
                  <Select
                    defaultValue={_(swappingMethods)
                      .filter((s) => (data.swappingMethodIds || []).includes(s.id))
                      .map((val) => ({
                        value: val.id,
                        label: val.name,
                      }))
                      .value()}
                    options={_(swappingMethods)
                      .map((val) => ({
                        value: val.id,
                        label: val.name,
                      }))
                      .value()}
                    onChange={(e) => {
                      data.swappingMethodIds = e.map((s) => s.value);

                      handleSwappingMethodChange();
                    }}
                    isMulti
                  />
                </Form.Group>
              )}
              {isRequiredAddress && (
                <Form.Group className="mb-4">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    defaultValue={data.address}
                    onChange={(e) => (data.address = e.target.value)}
                  />
                </Form.Group>
              )}
              <Col lg={12}>
                <Form.Group className="mb-4">
                  <div className="mb-3">
                    <Form.Label>Would you like to give, receive or both?</Form.Label>
                  </div>
                  <div className="mb-3">
                    <Form.Check
                      id="rg_0"
                      type="radio"
                      name="rg"
                      label="Receive clothes only"
                      defaultChecked={data.receiveGive === 'receive'}
                      onChange={() => (data.receiveGive = 'receive')}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Check
                      id="rg_1"
                      type="radio"
                      name="rg"
                      label="Give clothes only"
                      defaultChecked={data.receiveGive === 'give'}
                      onChange={() => (data.receiveGive = 'give')}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Check
                      id="rg_2"
                      type="radio"
                      name="rg"
                      label="Both give and receive clothes"
                      defaultChecked={data.receiveGive === 'both'}
                      onChange={() => (data.receiveGive = 'both')}
                      required
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </FormWrapper>
          <div className="mt-3 text-end">
            <Button type="submit" variant="dark">
              Continue
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default AboutYou;
