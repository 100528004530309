import { action, makeObservable, observable } from 'mobx';

import { HEHD_STORAGE_TOKEN } from '@/constants/skeys';

export default class AuthStore {
  @observable
  public token?: string = localStorage.getItem(HEHD_STORAGE_TOKEN) || undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  public setToken(token: string): void {
    this.token = token;
  }

  @action
  public clearToken(): void {
    localStorage.removeItem(HEHD_STORAGE_TOKEN);

    this.token = undefined;
  }
}
