export enum Sizes {
  s0000 = '0000',
  s000 = '000',
  s00 = '00',
  s0 = '0',
  s1 = '1',
  s2 = '2',
  s3 = '3',
  s4 = '4',
  s5 = '5',
  s6 = '6',
  s7 = '7',
  s8 = '8',
  s9 = '9',
  s10 = '10',
  s11 = '11',
  s12 = '12',
  s13 = '13',
  s14 = '14',
  s15 = '15',
  s16 = '16',
  s17 = '17',
  s18 = '18',
}

export enum NumOfItems {
  from1to3 = '1-3',
  from4to7 = '4-7',
  from8to10 = '8-10',
  from11to15 = '11-15',
  from16to19 = '16-19',
  more20 = '20+',
}

export enum TypesOfClothing {
  Summer = 'Summer',
  Everyday = 'Everyday',
  Bottoms = 'Bottoms',
  Costumes = 'Costumes',
  Dresses = 'Dresses',
  Swimwear = 'Swimwear',
  Active = 'Active',
  Winter = 'Winter',
  Occasional = 'Occasional',
  Tops = 'Tops',
  Denim = 'Denim',
  Jackets = 'Jackets',
  Onesies = 'Onesies',
  Other = 'Other',
}

export enum ClothingStyles {
  Neutral = 'Neutral',
  Sparkles = 'Sparkles',
  TomBoy = 'Tom Boy',
  Dinosaurs = 'Dinosaurs',
  NoSpecificStyle = 'No specific style',
  Princess = 'Princess',
  Disney = 'Disney',
  Trucks = 'Trucks',
  Space = 'Space',
  Other = 'Other',
}
