import _ from 'lodash';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useClearCacheCtx } from 'react-clear-cache';
import { useToken } from 'react-firebase-hooks/messaging';
import { useLoading } from 'react-hook-loading';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { PROFILE } from '@/constants/apis';
import { Country } from '@/enums/CountryState';
import AuthLayout from '@/layouts/Auth';
import AuthenticatedLayout, { ProfileMenuLayout } from '@/layouts/Authenticated';
import DefaultLayout from '@/layouts/Default';
import SignUpInfoLayout from '@/layouts/SignUpInfo';
import { UserPlan } from '@/models/User';
import AboutPage from '@/pages/About';
import BlogPostsPage from '@/pages/About/BlogPosts';
import EventsPage from '@/pages/About/Events';
import DashboardPage from '@/pages/Dashboard';
import DashboardSetPage from '@/pages/DashboardSet';
import DashboardUserDetailPage from '@/pages/DashboardUserDetail';
import FAQPage from '@/pages/FAQ';
import ForgotPasswordPage from '@/pages/ForgotPassword';
import HomePage from '@/pages/Home';
import MatchSessionPage from '@/pages/MatchSession';
import MatchSessionDetailPage from '@/pages/MatchSessionDetail';
import MatchSessionMessagePage from '@/pages/MatchSessionMessage';
import ProfilePage from '@/pages/Profile';
import ProfileEditPage from '@/pages/ProfileEdit';
import ProfileMatchSessionPage from '@/pages/ProfileMatchSession';
import ProfilePlanPage from '@/pages/ProfilePlan';
import ProfileSetPage from '@/pages/ProfileSet';
import ProfileStatsPage from '@/pages/ProfileStats';
import ResetPasswordPage from '@/pages/ResetPassword';
import SignInPage from '@/pages/SignIn';
import SignUpPage from '@/pages/SignUp';
import TermsAndConditionsPage from '@/pages/TermsAndConditions';
import UserInfoFirstTime from '@/pages/UserInfoFistTime';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';
import { messaging } from '@/utils/firebase';

import DashboardMatchDetailPage from './pages/DashboardMatchDetail';

export default observer(function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  const navigate = useNavigate();

  const [, setGlobalLoading] = useLoading();

  const { authStore, profileStore, wsStore } = useStore();

  const [fcmToken] = useToken(messaging);

  const [loadingProfile, getProfile] = useApi<
    {
      id: number;
      full_name: string;
      email: string;
      plan: UserPlan;
      country: Country;
      postcode: string;
      is_info_entered: boolean;
      is_admin: boolean;
      swapping_methods: {
        id: number;
        name: string;
        is_required_address: boolean;
      }[];
      address: string;
    },
    unknown
  >({
    url: PROFILE,
  });

  const [loadingSaveFcmToken, saveFcmToken] = useApi<
    {
      success: boolean;
    },
    { token: string }
  >({
    method: 'POST',
    url: `${PROFILE}/_fcm`,
  });

  useEffect(() => {
    autorun(() => {
      if (_.isNil(authStore.token)) {
        return;
      }

      loadProfile();
    });

    autorun(() => {
      if (!_.isNil(profileStore.currentUser)) {
        wsStore.connect();
      } else {
        wsStore.disconnect();
      }
    });

    if (!isLatestVersion) {
      emptyCacheStorage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isNil(fcmToken) && !_.isNil(authStore.token)) {
      const func = async () => {
        if (loadingSaveFcmToken) {
          return;
        }

        await saveFcmToken({
          data: { token: fcmToken },
        });
      };

      func();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcmToken, authStore.token]);

  const loadProfile = async () => {
    if (loadingProfile) {
      return;
    }

    setGlobalLoading(true);

    try {
      const { data } = await getProfile();

      profileStore.setCurrentUser({
        id: data.id,
        fullName: data.full_name,
        email: data.email,
        plan: data.plan,
        country: data.country,
        postcode: data.postcode,
        isInfoEntered: data.is_info_entered,
        isAdmin: data.is_admin,
        swappingMethods: _(data.swapping_methods)
          .map((s) => ({
            id: s.id,
            name: s.name,
            isRequiredAddress: s.is_required_address,
          }))
          .value(),
        address: data.address,
      });

      if (!profileStore.currentUser!.isInfoEntered) {
        navigate('/sign-up/info');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <Routes>
      <Route
        path="*"
        element={
          // <div className="d-flex justify-content-center align-items-center py-5 h3">
          //   Page not found!
          // </div>
          <div />
        }
      />
      {!_.isNil(authStore.token) ? (
        !_.isNil(profileStore.currentUser) && (
          <Route element={<AuthenticatedLayout />}>
            <Route index element={<HomePage />} />
            <Route path="/matches" element={<MatchSessionPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/blog" element={<BlogPostsPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
            <Route path="/faqs" element={<FAQPage />} />
            <Route path="/match-sessions/:id" element={<MatchSessionDetailPage />} />
            <Route path="/match-sessions/:id/messages" element={<MatchSessionMessagePage />} />
            <Route element={<ProfileMenuLayout />}>
              <Route path="/me/overview" element={<ProfilePage />} />
              <Route path="/me/edit" element={<ProfileEditPage />} />
              <Route path="/me/bundles" element={<ProfileSetPage />} />
              <Route path="/me/match-sessions" element={<ProfileMatchSessionPage />} />
              <Route path="/me/sustainability-stats" element={<ProfileStatsPage />} />
              <Route path="/me/subscription" element={<ProfilePlanPage />} />
              {profileStore.currentUser.isAdmin && (
                <>
                  <Route path="/admin/matches" element={<DashboardPage />} />
                  <Route path="/admin/matches/:id" element={<DashboardMatchDetailPage />} />
                  <Route path="/admin/users/:id/sets" element={<DashboardSetPage />} />
                  <Route path="/admin/users/:id" element={<DashboardUserDetailPage />} />
                </>
              )}
            </Route>
          </Route>
        )
      ) : (
        <Route element={<DefaultLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/blog" element={<BlogPostsPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/faqs" element={<FAQPage />} />
        </Route>
      )}
      {!_.isNil(authStore.token) ? (
        <Route element={<SignUpInfoLayout />}>
          <Route path="/sign-up/info" element={<UserInfoFirstTime />} />
        </Route>
      ) : (
        <Route element={<AuthLayout />}>
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        </Route>
      )}
    </Routes>
  );
});
