import Bluebird from 'bluebird';
import _ from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Form, Stack } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import AlertDialog from '@/components/AlertDialog';
import { GiveSetInfo, ReceiveSetInfo } from '@/components/FirstTimeSetItem';
import { SET } from '@/constants/apis';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { Set, SetForm, SetGender, SetGive, SetGiveForm, SetType } from '@/models/Set';
import CreateSetForm from '@/pages/ProfileSet/CreateSetForm';
import { useApi } from '@/utils/axios';
import { getSetGender } from '@/utils/hehd';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const ProfileSetPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const alert = useAlert();

  const [, setGlobalLoading] = useLoading();

  const [params] = useSearchParams();
  const type = params.get('type') as SetType;

  const [isShowCreateDialog, setIsShowCreateDialog] = useState<boolean>(false);

  const [sets, setSets] = useState<Set[] | SetGive[]>([]);

  const [currentSet, setCurrentSet] = useState<Set | SetGive>();
  const [currentSetForm, setCurrentSetForm] = useState<SetForm | SetGiveForm>({});

  const [loadingSets, getSets] = useApi<
    {
      id: number;
      type: SetType;
      gender: SetGender;
      num_of_items: NumOfItems;
      sizes: Sizes[];
      types_of_clothing: TypesOfClothing[];
      clothing_styles: ClothingStyles[];
      give_galleries?: { id: number; url: string }[];
      is_hehd: boolean;
    }[],
    unknown
  >({
    url: SET,
  });

  const [loadingUpdateSet, doUpdateSet] = useApi<
    { success: boolean },
    | {
        type: SetType;
        gender: string;
        sizes: string[];
        num_of_items: string;
        types_of_clothing: string[];
        clothing_styles: string[];
        is_hehd: boolean;
      }
    | FormData
  >({
    method: 'PUT',
  });

  const [loadingDeleteSet, doDeleteSet] = useApi<{ success: boolean }, unknown>({
    method: 'DELETE',
  });

  useEffect(() => {
    if (!_.includes([SetType.receive, SetType.give], type)) {
      navigate('/me/overview');

      return;
    }

    loadSets();

    if (!_.isNil(location.state) && (location.state as { open?: boolean }).open === true) {
      setIsShowCreateDialog(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, location.key]);

  const loadSets = async () => {
    if (loadingSets) {
      return;
    }

    setSets([]);
    setCurrentSet(undefined);
    setCurrentSetForm({});

    await Bluebird.delay(10);

    setGlobalLoading(true);

    const { data } = await getSets({
      params: { type },
    });

    setGlobalLoading(false);

    if (type === SetType.receive) {
      setSets(
        _(data)
          .map<Set>((set) => ({
            id: set.id,
            type: set.type,
            gender: set.gender,
            numOfItems: set.num_of_items,
            sizes: set.sizes,
            typesOfClothing: set.types_of_clothing,
            clothingStyles: set.clothing_styles,
            isHEHD: set.is_hehd,
          }))
          .value()
      );
    } else {
      setSets(
        _(data)
          .map<SetGive>((set) => ({
            id: set.id,
            type: set.type,
            gender: set.gender,
            numOfItems: set.num_of_items,
            sizes: set.sizes,
            typesOfClothing: set.types_of_clothing,
            clothingStyles: set.clothing_styles,
            giveGalleries: (set.give_galleries || []).map((g) => ({
              id: g.id,
              url: g.url,
            })),
            isHEHD: set.is_hehd,
          }))
          .value()
      );
    }
  };

  const handleChooseSetChanged = async (id: number) => {
    setCurrentSet(undefined);
    setCurrentSetForm({});

    const set = _.find<Set | SetGive>(sets, (s) => s.id === id);

    if (_.isNil(set)) {
      return;
    }

    await Bluebird.delay(10);

    setCurrentSet(set);

    if (type === SetType.receive) {
      setCurrentSetForm({
        gender: set.gender,
        numOfItems: set.numOfItems,
        sizes: set.sizes,
        typesOfClothing: set.typesOfClothing,
        clothingStyles: set.clothingStyles,
        isHEHD: set.isHEHD,
      });
    } else {
      const setx = set as SetGive;

      setCurrentSetForm({
        gender: setx.gender,
        numOfItems: setx.numOfItems,
        sizes: setx.sizes,
        typesOfClothing: setx.typesOfClothing,
        clothingStyles: setx.clothingStyles,
        giveGalleries: [],
        oldGiveGalleries: setx.giveGalleries,
        destroyGiveGalleries: [],
        isHEHD: set.isHEHD,
      });
    }
  };

  const handleUpdateSetFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (loadingUpdateSet) {
      return;
    }

    const hasSizesEmpty = _(currentSetForm.sizes).isEmpty();
    const hasTypesOfClothingEmpty = _(currentSetForm.typesOfClothing).isEmpty();
    const hasClothingStylesEmpty = _(currentSetForm.clothingStyles).isEmpty();

    if (hasSizesEmpty) {
      alert.info('You need to choose at least one Sizes.');

      return;
    }

    if (hasTypesOfClothingEmpty) {
      alert.info('You need to choose at least one Types Of Clothing.');

      return;
    }

    if (hasClothingStylesEmpty) {
      alert.info('You need to choose at least one Clothing Styles.');

      return;
    }

    setGlobalLoading(true);

    if (type === SetType.receive) {
      await doUpdateSet({
        url: `${SET}/${currentSet!.id}`,
        data: {
          type: SetType.receive,
          gender: currentSetForm.gender!,
          sizes: currentSetForm.sizes!,
          num_of_items: currentSetForm.numOfItems!,
          types_of_clothing: currentSetForm.typesOfClothing!,
          clothing_styles: currentSetForm.clothingStyles!,
          is_hehd: currentSetForm.isHEHD!,
        },
      });
    } else {
      const setx = currentSet as SetGive;
      const formx = currentSetForm as SetGiveForm;

      const hasDestroyGiveGalleriesEmpty = _(formx.destroyGiveGalleries).isEmpty();

      const totalGiveGalleries =
        setx.giveGalleries.length +
        formx.giveGalleries!.length -
        formx.destroyGiveGalleries!.length;

      if (!hasDestroyGiveGalleriesEmpty && totalGiveGalleries === 0) {
        alert.info('You need to upload at least 1 photo for each set!');

        return;
      }

      const formData = new FormData();

      formData.append('type', 'give');
      formData.append('gender', formx.gender!);

      formx.sizes!.forEach((s) => {
        formData.append('sizes[]', s);
      });

      formData.append('num_of_items', formx.numOfItems!);

      formx.typesOfClothing!.forEach((s) => {
        formData.append('types_of_clothing[]', s);
      });

      formx.clothingStyles!.forEach((s) => {
        formData.append('clothing_styles[]', s);
      });

      formx.giveGalleries!.forEach((s) => {
        formData.append('give_galleries[]', s);
      });

      formx.destroyGiveGalleries!.forEach((s) => {
        formData.append('destroy_give_galleries[]', _.toString(s));
      });

      formData.append('is_hehd', _.toString(formx.isHEHD));

      await doUpdateSet({
        data: formData,
        url: `${SET}/${currentSet!.id}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }

    alert.success('Successfully updated!');

    setGlobalLoading(false);
  };

  const handleDeleteSetClicked = async () => {
    if (loadingDeleteSet) {
      return;
    }

    const isOk = window.confirm('Are you sure you want to delete this bundle?');

    if (!isOk) {
      return;
    }

    setGlobalLoading(true);

    await doDeleteSet({
      url: `${SET}/${currentSet!.id}`,
    });

    setGlobalLoading(false);

    loadSets();
  };

  return (
    <div className="p-5">
      <div className="mb-4">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">
          {type === SetType.receive && 'Receiving Bundles'}
          {type === SetType.give && 'Giving Bundles'}
        </PageTitle>
      </div>
      <div className="mb-4">
        {_.isNil(currentSet) && (
          <Button type="submit" variant="dark" onClick={() => setIsShowCreateDialog(true)}>
            Create New
          </Button>
        )}
      </div>
      <Form.Group>
        <Form.Label>
          Select from existing bundles to <b>View</b> or <b>Edit</b>
        </Form.Label>
        <Stack direction="horizontal" gap={3}>
          <Form.Select onChange={(e) => handleChooseSetChanged(_.toInteger(e.currentTarget.value))}>
            <option>Choose one</option>
            {sets.map((set, i) => (
              <option value={set.id} key={i}>
                Bundle #{i + 1} - {getSetGender(set.gender)} - Size {set.sizes.join(', ')}
              </option>
            ))}
          </Form.Select>
        </Stack>
      </Form.Group>
      {currentSet && (
        <div className="mt-5">
          <Form onSubmit={handleUpdateSetFormSubmit}>
            {type === SetType.receive ? (
              <ReceiveSetInfo data={currentSetForm} />
            ) : (
              <GiveSetInfo data={currentSetForm} />
            )}
            <div className="d-flex justify-content-between mt-4">
              <Button type="button" variant="outline-danger" onClick={handleDeleteSetClicked}>
                Remove
              </Button>
              <Button type="submit" variant="dark">
                Save Change
              </Button>
            </div>
          </Form>
        </div>
      )}
      <AlertDialog show={isShowCreateDialog} onHide={() => setIsShowCreateDialog(false)} size="xl">
        <CreateSetForm
          type={type || SetType.receive}
          onDone={() => {
            setIsShowCreateDialog(false);

            loadSets();
          }}
          onCancel={() => setIsShowCreateDialog(false)}
        />
      </AlertDialog>
    </div>
  );
};

export default ProfileSetPage;
