export const AUTH_SIGNIN = '/public/auth/signin';
export const AUTH_SIGNUP = '/public/auth/signup';

export const FORGOT_PASSWORD = '/public/forgot-password';
export const FORGOT_PASSWORD_VERIFY = '/public/forgot-password/verify';
export const FORGOT_PASSWORD_RESET_PASSWORD = '/public/forgot-password/password';

export const CONTACT = '/public/contact';

export const PROFILE = '/private/profile';
export const PROFILE_CHANGE_PASSWORD = '/private/profile/_password';
export const PROFILE_MATCH_SESSION = '/private/profile/match-sessions';

export const ADMIN_MATCH_SESSION = '/private/admin/match-sessions';
export const ADMIN_USER = '/private/admin/users';
export const ADMIN_SET = '/private/admin/sets';

export const MATCH_SESSION = '/private/match-sessions';

export const SET = '/private/sets';

export const SWAPPING_METHOD = '/public/swapping-methods';
export const MATCH_SESSION_STATS = '/public/match-sessions/_stats';
