import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Badge, Button, Table } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PROFILE_MATCH_SESSION } from '@/constants/apis';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { MatchLevel, MatchSession, MatchSessionStatus } from '@/models/MatchSession';
import { SetGender, SetType } from '@/models/Set';
import { useApi } from '@/utils/axios';
import { getMatchSessionStatus } from '@/utils/hehd';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const ProfileMatchSessionPage = () => {
  const [, setGlobalLoading] = useLoading();

  const [matchSessions, setMatchSessions] = useState<MatchSession[]>([]);

  const [loading, getMatchSessions] = useApi<
    {
      data: {
        id: number;
        level: MatchLevel;
        status: MatchSessionStatus;
        type: SetType;
        set_receive: {
          id: number;
          type: SetType;
          gender: SetGender;
          num_of_items: NumOfItems;
          sizes: Sizes[];
          types_of_clothing: TypesOfClothing[];
          clothing_styles: ClothingStyles[];
          is_hehd: boolean;
          user: {
            id: number;
            full_name: string;
            email: string;
          };
        };
        set_give: {
          id: number;
          type: SetType;
          gender: SetGender;
          num_of_items: NumOfItems;
          sizes: Sizes[];
          types_of_clothing: TypesOfClothing[];
          clothing_styles: ClothingStyles[];
          is_hehd: boolean;
          user: {
            id: number;
            full_name: string;
            email: string;
          };
          give_galleries: { id: number; url: string }[];
        };
        total_messages: number;
        total_all_messages: number;
        created_at: string;
      }[];
    },
    unknown
  >({
    url: PROFILE_MATCH_SESSION,
  });

  useEffect(() => {
    loadMatchSessions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMatchSessions = async () => {
    if (loading) {
      return;
    }

    setMatchSessions([]);

    setGlobalLoading(true);

    const { data } = await getMatchSessions();

    setGlobalLoading(false);

    setMatchSessions(
      _(data.data)
        .map<MatchSession>(
          ({
            //
            id,
            level,
            status,
            type,
            set_receive: setReceive,
            set_give: setGive,
            total_messages: totalMessages,
            total_all_messages: totalAllMessages,
            created_at: createdAt,
          }) => ({
            id,
            level,
            status,
            type,
            setReceive: {
              id: setReceive.id,
              type: setReceive.type,
              gender: setReceive.gender,
              numOfItems: setReceive.num_of_items,
              sizes: setReceive.sizes,
              typesOfClothing: setReceive.types_of_clothing,
              clothingStyles: setReceive.clothing_styles,
              isHEHD: setReceive.is_hehd,
              user: {
                id: setReceive.user.id,
                fullName: setReceive.user.full_name,
                email: setReceive.user.email,
              },
            },
            setGive: {
              id: setGive.id,
              type: setGive.type,
              gender: setGive.gender,
              numOfItems: setGive.num_of_items,
              sizes: setGive.sizes,
              typesOfClothing: setGive.types_of_clothing,
              clothingStyles: setGive.clothing_styles,
              isHEHD: setGive.is_hehd,
              user: {
                id: setGive.user.id,
                fullName: setGive.user.full_name,
                email: setGive.user.email,
              },
              giveGalleries: setGive.give_galleries,
            },
            totalMessages,
            totalAllMessages,
            createdAt: moment(createdAt),
          })
        )
        .value()
    );
  };

  return (
    <div className="p-5">
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Swap History</PageTitle>
      </div>

      <Table>
        <thead>
          <tr>
            <td className="border-0 px-4">Date</td>
            <td className="border-0 px-4">Type</td>
            <td className="border-0 px-4">Status</td>
            <td className="border-0 px-4"></td>
          </tr>
        </thead>
        <tbody>
          {matchSessions.map((matchSession, i) => (
            <tr key={i} className="mb-1">
              <td className="bg-light border-0 align-middle px-4 py-3">
                {matchSession.createdAt!.format('lll')}
              </td>
              <td className="bg-light border-0 align-middle px-4 py-3">
                {matchSession.type === SetType.receive ? 'Receive' : 'Give'}
              </td>
              <td className="bg-light border-0 align-middle px-4 py-3">
                {getMatchSessionStatus(matchSession.status)}
              </td>
              <td className="bg-light border-0 align-middle px-4 py-3">
                <Link to={`/match-sessions/${matchSession.id}/messages`} className="me-2">
                  <Button variant="outline-dark" size="sm">
                    <span>Messages</span>
                    {!_.isNil(matchSession.totalMessages) && matchSession.totalMessages > 0 && (
                      <Badge bg="warning" className="ms-2">
                        {matchSession.totalMessages}
                      </Badge>
                    )}
                    {!_.isNil(matchSession.totalAllMessages) &&
                      matchSession.totalAllMessages > 0 && (
                        <Badge bg="danger" className="ms-2">
                          {matchSession.totalAllMessages}
                        </Badge>
                      )}
                  </Button>
                </Link>
                <Link to={`/match-sessions/${matchSession.id}`}>
                  <Button variant="dark" size="sm">
                    View Details
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProfileMatchSessionPage;
