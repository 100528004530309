import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, ReactNode } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import styled from 'styled-components';

import Footer from '@/components/Footer';
import HeaderProfile from '@/components/HeaderProfile';
import { PROFILE } from '@/constants/apis';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';

const Header = styled.div`
  border-bottom-color: #555555 !important;
`;

const MenuItemLink = styled(NavLink)`
  &::before {
    position: absolute;
    content: '';
    left: 0px;
    top: 14px;
    bottom: 16px;
    width: 3px;
  }

  &.active {
    font-weight: bold;

    &::before {
      background-color: #f8c400;
    }
  }
`;

const MenuItemDropdown = MenuItemLink.withComponent(Dropdown);

const CustomToggle = forwardRef<
  unknown,
  {
    children: ReactNode;
    onClick: (e: any) => void;
  }
>(({ children, onClick }, ref: any) => (
  <span ref={ref} onClick={onClick} role="button">
    {children}
  </span>
));

export const ProfileMenuLayout = () => {
  const location = useLocation();

  const { profileStore } = useStore();

  return (
    <>
      <Header className="bg-white border-bottom">
        <Container>
          <div className="py-5">
            <div className="mb-2 h2 fw-bold">Profile</div>
            <div>Update your settings and information</div>
          </div>
        </Container>
      </Header>
      <div className="py-3">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="py-4">
                <div className="position-relative py-4 ps-4">
                  <MenuItemLink
                    to="/me/overview"
                    className="d-block text-dark text-decoration-none"
                  >
                    <FontAwesomeIcon icon="home" />
                    <span className="ms-3">Overview</span>
                  </MenuItemLink>
                </div>
                <div className="position-relative py-4 ps-4">
                  <MenuItemLink to="/me/edit" className="d-block text-dark text-decoration-none">
                    <FontAwesomeIcon icon="user" />
                    <span className="ms-3">Profile Data</span>
                  </MenuItemLink>
                </div>
                <div className="position-relative py-4 ps-4">
                  <MenuItemDropdown
                    drop="end"
                    className={`position-initial ${
                      location.pathname.includes('/me/bundles') ? 'active' : ''
                    }`}
                  >
                    <Dropdown.Toggle as={CustomToggle}>
                      <div className="d-flex align-items-center">
                        <div className="me-auto">
                          <FontAwesomeIcon icon="arrow-right-arrow-left" />
                          <span className="ms-3">Swap Bundles</span>
                        </div>
                        <FontAwesomeIcon icon="angle-right" />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="border-0 shadow-sm bg-light">
                      <Dropdown.Item as={Link} to="/me/bundles?type=receive" className="py-3">
                        Receiving Bundles
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/me/bundles?type=give" className="py-3">
                        Giving Bundles
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </MenuItemDropdown>
                </div>
                <div className="position-relative py-4 ps-4">
                  <MenuItemLink
                    to="/me/match-sessions"
                    className="d-block text-dark text-decoration-none"
                  >
                    <FontAwesomeIcon icon="archive" />
                    <span className="ms-3">Swap History</span>
                  </MenuItemLink>
                </div>
                <div className="position-relative py-4 ps-4">
                  <MenuItemLink
                    to="/me/sustainability-stats"
                    className="d-block text-dark text-decoration-none"
                  >
                    <FontAwesomeIcon icon="leaf" />
                    <span className="ms-3">Sustainability Stats</span>
                  </MenuItemLink>
                </div>
                {profileStore.currentUser && profileStore.currentUser.isAdmin && (
                  <div className="position-relative py-4 ps-4">
                    <MenuItemLink
                      to="/admin/matches"
                      className="d-block text-dark text-decoration-none"
                    >
                      <FontAwesomeIcon icon="dashboard" />
                      <span className="ms-3">Dashboard</span>
                    </MenuItemLink>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={9}>
              <div className="shadow-sm bg-white">
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ScrollToTop smooth />
    </>
  );
};

const AuthenticatedLayout = () => {
  const navigate = useNavigate();

  const { profileStore } = useStore();

  const [, setGlobalLoading] = useLoading();

  const [loading, doLogout] = useApi({
    method: 'DELETE',
    url: PROFILE,
  });

  const handleLogoutClicked = async () => {
    if (loading) {
      return;
    }

    const isOk = window.confirm('Are you sure you want to log out?');

    if (!isOk) {
      return;
    }

    setGlobalLoading(true);

    try {
      await doLogout();

      profileStore.clearCurrentUser();

      navigate('/');
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="bg-light">
      <HeaderProfile onLogout={handleLogoutClicked} />
      <Outlet />
      <Footer />
      <ScrollToTop smooth />
    </div>
  );
};

export default AuthenticatedLayout;
