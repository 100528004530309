import _ from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import Select from 'react-select';
import styled from 'styled-components';

import AlertDialog from '@/components/AlertDialog';
import { PROFILE, SWAPPING_METHOD } from '@/constants/apis';
import { Country } from '@/enums/CountryState';
import { SwappingMethod, UserAboutForm } from '@/models/User';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';

import ChangePasswordDialog from './ChangePasswordDialog';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const ProfileEditPage = () => {
  const { profileStore } = useStore();

  const alert = useAlert();

  const [, setGlobalLoading] = useLoading();

  const [swappingMethods, setSwappingMethods] = useState<SwappingMethod[]>([]);

  const [isShowPasswordDialog, setIsShowPasswordDialog] = useState<boolean>(false);

  const [form, setForm] = useState<UserAboutForm>({});

  const [loadingUpdateProfile, doUpdateProfile] = useApi<
    { success: boolean },
    {
      full_name?: string | undefined;
      country?: string | undefined;
      postcode?: string | undefined;
      swapping_method_ids?: number[] | undefined;
      address?: string;
    }
  >({
    method: 'PUT',
    url: PROFILE,
  });

  const [loadingSwappingMethod, getSwappingMethod] = useApi<
    {
      id: number;
      name: string;
      is_required_address: boolean;
    }[],
    unknown
  >({
    url: SWAPPING_METHOD,
  });

  useEffect(() => {
    setForm({
      fullName: profileStore.currentUser!.fullName,
      country: profileStore.currentUser!.country,
      postcode: profileStore.currentUser!.postcode,
      swappingMethodIds: profileStore.currentUser!.swappingMethods!.map((s) => s.id),
      address: profileStore.currentUser!.address,
    });

    loadSwappingMethod();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSwappingMethod = async () => {
    if (loadingSwappingMethod) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getSwappingMethod();

    setGlobalLoading(false);

    setSwappingMethods(
      _.map(data, (s) => ({
        id: s.id,
        name: s.name,
        isRequiredAddress: s.is_required_address,
      }))
    );
  };

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (loadingUpdateProfile) {
      return;
    }

    alert.removeAll();

    setGlobalLoading(true);

    await doUpdateProfile({
      data: {
        full_name: form.fullName,
        country: form.country,
        postcode: form.postcode,
        swapping_method_ids: form.swappingMethodIds,
        address: form.address,
      },
    });

    setGlobalLoading(false);

    profileStore.setCurrentUser({
      ...profileStore.currentUser!,
      fullName: form.fullName || '',
      postcode: form.postcode || '',
      swappingMethods: swappingMethods.filter((s) => (form.swappingMethodIds || []).includes(s.id)),
      address: form.address,
    });

    alert.success('Personal information update was successful');
  };

  return (
    <div className="p-5">
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Profile Data</PageTitle>
      </div>
      <Form onSubmit={handleFormSubmit}>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                defaultValue={profileStore.currentUser!.email}
                required
                disabled
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value="what_see"
                required
                disabled
              />
              <div className="mt-3">
                <Button
                  variant="outline-dark"
                  size="sm"
                  className="px-3"
                  onClick={() => setIsShowPasswordDialog(true)}
                >
                  Change Password
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                defaultValue={profileStore.currentUser!.fullName}
                onChange={(e) =>
                  setForm({
                    ...form,
                    fullName: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col>
            {!_.isEmpty(swappingMethods) && (
              <Form.Group className="mb-4">
                <Form.Label>Swapping Method</Form.Label>
                <Select
                  value={_(swappingMethods)
                    .filter((s) => (form.swappingMethodIds || []).includes(s.id))
                    .map((val) => ({
                      value: val.id,
                      label: val.name,
                    }))
                    .value()}
                  options={_(swappingMethods)
                    .map((val) => ({
                      value: val.id,
                      label: val.name,
                    }))
                    .value()}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      swappingMethodIds: e.map((s) => s.value),
                    })
                  }
                  isMulti
                />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Select
                defaultValue={profileStore.currentUser!.country}
                onChange={(e) =>
                  setForm({
                    ...form,
                    country: e.target.value as Country,
                  })
                }
                required
              >
                {_(Country)
                  .map((val: string, i) => (
                    <option value={val} key={i}>
                      {val}
                    </option>
                  ))
                  .value()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postcode"
                defaultValue={profileStore.currentUser!.postcode}
                onChange={(e) =>
                  setForm({
                    ...form,
                    postcode: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
        </Row>
        {_.some(
          swappingMethods,
          (s) => _.includes(form.swappingMethodIds, s.id) && s.isRequiredAddress
        ) && (
          <Form.Group className="mb-4">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address"
              defaultValue={profileStore.currentUser!.address}
              onChange={(e) =>
                setForm({
                  ...form,
                  address: e.target.value,
                })
              }
            />
          </Form.Group>
        )}
        <div className="mt-5 d-flex justify-content-end">
          <Button type="reset" variant="outline-dark" className="me-3">
            Reset
          </Button>
          <Button
            type="submit"
            variant="warning"
            className="fw-bold"
            disabled={loadingUpdateProfile}
          >
            Save
          </Button>
        </div>
      </Form>
      <AlertDialog show={isShowPasswordDialog} onHide={() => setIsShowPasswordDialog(false)}>
        <ChangePasswordDialog />
      </AlertDialog>
    </div>
  );
};

export default ProfileEditPage;
