import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Icon = styled.div`
  width: 40px;
  line-height: 40px;
  background-color: #f8c400;
`;

const NoMatchSession = () => {
  return (
    <div className="py-5 d-flex flex-column align-items-center">
      <Icon className="mb-4 text-center rounded-circle">
        <FontAwesomeIcon icon="search" size="lg" />
      </Icon>
      <div className="h4 fw-bold">No matches right now.</div>
    </div>
  );
};

export default NoMatchSession;
