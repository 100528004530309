import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AUTH_SIGNUP } from '@/constants/apis';
import { HEHD_STORAGE_TOKEN } from '@/constants/skeys';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';

type SignUpFormData = {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  isTerms: boolean;
};

const Wrapper = styled.div`
  background-color: #f7d9d3;
`;

const SignUpForm = () => {
  const alert = useAlert();

  const navigate = useNavigate();

  const { authStore } = useStore();

  const [formData, setFormData] = useState<SignUpFormData>({
    fullName: process.env.NODE_ENV === 'development' ? 'Toan Doan' : '',
    email: process.env.NODE_ENV === 'development' ? 'toandev.95@gmail.com' : '',
    password: process.env.NODE_ENV === 'development' ? '123456' : '',
    passwordConfirmation: process.env.NODE_ENV === 'development' ? '123456' : '',
    isTerms: false,
  });
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordConfirmation, setIsShowPasswordConfirmation] = useState<boolean>(false);

  const [loading, doSignUp] = useApi<
    {
      success: boolean;
      token: {
        type: string;
        token: string;
      };
    },
    {
      full_name: string;
      email: string;
      password: string;
      password_confirmation: string;
    }
  >({
    method: 'POST',
    url: AUTH_SIGNUP,
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    try {
      const { data } = await doSignUp({
        data: {
          full_name: formData.fullName,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.passwordConfirmation,
        },
      });

      if (data.success) {
        authStore.setToken(data.token.token);

        localStorage.setItem(HEHD_STORAGE_TOKEN, data.token.token);

        navigate('/sign-up/info');
      }
    } catch (error: any) {
      console.error(error);

      if (!_.isNil(error.response) && !_.isNil(error.response.data.errors)) {
        const errors: {
          rule: string;
          field: string;
        }[] = error.response.data.errors;

        switch (errors[0].rule) {
          case 'email':
            switch (errors[0].field) {
              default:
                alert.error('Email address is not valid');

                break;
            }
            break;
          case 'confirmed':
            switch (errors[0].field) {
              case 'password_confirmation':
                alert.error('Confirmation password does not match');

                break;
              default:
                break;
            }
            break;
          case 'unique':
            switch (errors[0].field) {
              case 'email':
                alert.error('Email already used');

                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <Wrapper className="pt-5 p-4 shadow">
      <Form onSubmit={handleFormSubmit}>
        <div className="mb-4 h3 text-center fw-bold">Sign Up Today</div>
        <Form.Group className="mb-4">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={(e) =>
              setFormData({
                ...formData,
                fullName: e.target.value,
              })
            }
            disabled={loading}
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({
                ...formData,
                email: e.target.value,
              })
            }
            disabled={loading}
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={isShowPassword ? 'text' : 'password'}
              placeholder="Password"
              value={formData.password}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  password: e.target.value,
                })
              }
              disabled={loading}
              required
            />
            <InputGroup.Text role="button" onClick={() => setIsShowPassword(!isShowPassword)}>
              <FontAwesomeIcon icon={isShowPassword ? 'eye' : 'eye-slash'} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={isShowPasswordConfirmation ? 'text' : 'password'}
              placeholder="Password Confirmation"
              value={formData.passwordConfirmation}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  passwordConfirmation: e.target.value,
                })
              }
              disabled={loading}
              required
            />
            <InputGroup.Text
              role="button"
              onClick={() => setIsShowPasswordConfirmation(!isShowPasswordConfirmation)}
            >
              <FontAwesomeIcon icon={isShowPasswordConfirmation ? 'eye' : 'eye-slash'} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <div className="d-flex mb-4">
          <Form.Check
            type="checkbox"
            label={
              <div className="ms-2">
                By siging up, you agree to the{' '}
                <span className="fw-bold text-decoration-underline">Terms and Conditions</span>
              </div>
            }
            checked={formData.isTerms}
            onChange={(e) =>
              setFormData({
                ...formData,
                isTerms: e.target.checked,
              })
            }
          />
        </div>
        <div className="text-center">
          <Button type="submit" variant="dark" disabled={loading || !formData.isTerms}>
            Sign Up
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default SignUpForm;
