import Bluebird from 'bluebird';
import _ from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { io, Socket } from 'socket.io-client';

import { MatchSessionStatus } from '@/models/MatchSession';
import { User } from '@/models/User';
import { RootStore } from '@/stores';

export type MatchSessionNewStatusPayload = {
  id: number;
  status: MatchSessionStatus;
};

export type MatchSessionMessagePayload = {
  id: number;
  body: string;
  user: User;
  isCurrentUser: boolean;
  createdAt: moment.Moment;
};

export default class WsStore {
  public socket?: Socket;

  @observable
  public connected: boolean = false;

  @observable
  public joined: boolean = false;

  @observable
  public newStatus?: MatchSessionNewStatusPayload;

  @observable
  public newMessage?: MatchSessionMessagePayload;

  @observable
  public triggerNotifications?: 'on';

  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  @action
  public setConnected(connected: boolean): void {
    this.connected = connected;
  }

  @action
  public setJoined(joined: boolean): void {
    this.joined = joined;
  }

  @action
  public setNewStatus(newStatus: MatchSessionNewStatusPayload | undefined): void {
    this.newStatus = newStatus;
  }

  @action
  public setNewMessage(newMessage: MatchSessionMessagePayload | undefined): void {
    this.newMessage = newMessage;
  }

  @action
  public setTriggerNotifications(tn: 'on' | undefined): void {
    this.triggerNotifications = tn;
  }

  @action
  public async connect(): Promise<void> {
    if (this.connected) {
      return;
    }

    const { profileStore } = this.rootStore;

    while (true) {
      if (!_.isNil(profileStore.currentUser)) {
        break;
      }

      await Bluebird.delay(1 * 500);
    }

    console.info('Ws:', 'Connect');

    const userId = profileStore.currentUser!.id;

    this.socket = io(process.env.REACT_APP_WS_URL, {
      auth: { userId },
      path: process.env.REACT_APP_WS_PREFIX,
    });

    this.socket.on('connect_error', (err: Error): void => {
      this.setConnected(false);

      console.error(err);
    });

    this.socket.on('connect', (): void => {
      this.setConnected(true);
    });

    this.socket.on('disconnect', () => {
      this.setConnected(false);
    });

    this.socket.on('joined', ({ sid }: { sid: string }): void => {
      if (sid === this.socket!.id) {
        this.setJoined(true);
      }
    });

    this.socket.on('leaved', ({ sid }: { sid: string }): void => {
      if (sid === this.socket!.id) {
        this.setJoined(false);
      }
    });

    this.socket.on('status_updated', ({ id, status }: MatchSessionNewStatusPayload): void => {
      console.info('Ws:', 'New Status Updated');

      this.setNewStatus({
        id,
        status,
      });
    });

    this.socket.on('notifications', () => {
      this.setTriggerNotifications('on');
    });

    this.socket.on(
      'new_message',
      ({
        id,
        body,
        user,
        created_at,
      }: {
        id: number;
        body: string;
        user: {
          id: number;
          full_name: string;
          email: string;
        };
        created_at: string;
      }): void => {
        console.info('Ws:', 'New Message');

        this.setNewMessage({
          id,
          body,
          user: {
            id: user.id,
            fullName: user.full_name,
            email: user.email,
          },
          isCurrentUser: userId === user.id,
          createdAt: moment(created_at),
        });
      }
    );
  }

  @action
  public async disconnect(): Promise<void> {
    if (!this.connected || _.isNil(this.socket)) {
      return;
    }

    this.socket.disconnect();
  }

  @action
  public join(id: number): void {
    console.info('Ws:', 'Join');

    this.socket!.emit('join_room', { id });
  }

  @action
  public leave(id: number): void {
    console.info('Ws:', 'Leave');

    this.socket!.emit('leave_room', { id });
  }

  @action
  public sendMessage(id: number, body: string): void {
    console.info('Ws:', 'Send Message');

    this.socket!.emit('message', { id, body });
  }
}
