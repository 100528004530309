import '@/theme/fortawesome';

import {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro';

import AvenirLTStdBlackTTF from '@/assets/fonts/AvenirLTStd-Black.ttf';
import AvenirLTStdBookTTF from '@/assets/fonts/AvenirLTStd-Book.ttf';
import AvenirLTStdRomanTTF from '@/assets/fonts/AvenirLTStd-Roman.ttf';
import LobsterTwoBoldItalicTTF from '@/assets/fonts/LobsterTwo-BoldItalic.ttf';
import { Colors } from '@/theme/styled';

const colors = (): Colors => ({});

const getTheme = (): DefaultTheme => ({
  ...colors(),
});

export const ThemedGlobalStyle = createGlobalStyle`${css`
  @font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdBlackTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdRomanTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdBookTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Lobster Two';
    src: url(${LobsterTwoBoldItalicTTF}) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  body {
    font-family: 'Avenir LT Std', sans-serif;
  }

  .form-control,
  .form-select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-color: rgba(0, 0, 0, 0.5);
    border-radius: 2px;

    &[readonly] {
      border-color: #f0f0f0;
      background-color: #f0f0f0;
    }
  }

  .form-check-input:checked {
    border-color: var(--bs-warning);
    background-color: var(--bs-warning);
  }

  .btn:not(.btn-sm):not(.btn-lg) {
    padding: 0.5rem 3rem;
  }

  .position-initial {
    position: initial;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: var(--bs-warning);
  }
`}`;

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledComponentsThemeProvider theme={getTheme()}>
      {/* Hmm */}
      {children}
    </StyledComponentsThemeProvider>
  );
};

export default ThemeProvider;
