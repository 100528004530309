import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';

import { PROFILE_CHANGE_PASSWORD } from '@/constants/apis';
import { useApi } from '@/utils/axios';

type ChangePasswordFormData = {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};

const ChangePasswordDialog = () => {
  const [, setGlobalLoading] = useLoading();

  const alert = useAlert();

  const [formData, setFormData] = useState<ChangePasswordFormData>({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const [isShowOldPassword, setIsShowOldPassword] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordConfirmation, setIsShowPasswordConfirmation] = useState<boolean>(false);

  const [loading, doChangePassword] = useApi<
    { success: boolean },
    {
      old_password: string;
      password: string;
      password_confirmation: string;
    }
  >({
    method: 'PUT',
    url: PROFILE_CHANGE_PASSWORD,
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setGlobalLoading(true);

    try {
      const { data } = await doChangePassword({
        data: {
          old_password: formData.oldPassword,
          password: formData.password,
          password_confirmation: formData.passwordConfirmation,
        },
      });

      if (!data.success) {
        throw new Error('Wrong old password.');
      }

      alert.error('Change password successfully!');
    } catch (error: any) {
      console.error(error);

      if (!_.isNil(error.response) && !_.isNil(error.response.data.errors)) {
        const errors: {
          rule: string;
          field: string;
        }[] = error.response.data.errors;

        switch (errors[0].rule) {
          case 'confirmed':
            switch (errors[0].field) {
              case 'password_confirmation':
                alert.error('Confirmation password does not match.');

                break;
              default:
                break;
            }
            break;
          default:
            break;
        }

        alert.error('There is something wrong with the information entered!');

        return;
      }

      alert.error(error.message || 'Password change problem, please try again later.');
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <div className="h4 fw-bold">Change Password</div>
      <div className="my-5">
        <Form.Group className="mb-4">
          <InputGroup>
            <Form.Control
              type={isShowOldPassword ? 'text' : 'password'}
              placeholder="Password"
              value={formData.oldPassword}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  oldPassword: e.target.value,
                })
              }
              disabled={loading}
              required
            />
            <InputGroup.Text role="button" onClick={() => setIsShowOldPassword(!isShowOldPassword)}>
              <FontAwesomeIcon icon={isShowOldPassword ? 'eye' : 'eye-slash'} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-4">
          <InputGroup>
            <Form.Control
              type={isShowPassword ? 'text' : 'password'}
              placeholder="New Password"
              value={formData.password}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  password: e.target.value,
                })
              }
              disabled={loading}
              required
            />
            <InputGroup.Text role="button" onClick={() => setIsShowPassword(!isShowPassword)}>
              <FontAwesomeIcon icon={isShowPassword ? 'eye' : 'eye-slash'} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-4">
          <InputGroup>
            <Form.Control
              type={isShowPasswordConfirmation ? 'text' : 'password'}
              placeholder="Retype New Password"
              value={formData.passwordConfirmation}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  passwordConfirmation: e.target.value,
                })
              }
              disabled={loading}
              required
            />
            <InputGroup.Text
              role="button"
              onClick={() => setIsShowPasswordConfirmation(!isShowPasswordConfirmation)}
            >
              <FontAwesomeIcon icon={isShowPasswordConfirmation ? 'eye' : 'eye-slash'} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </div>
      <div className="text-end">
        <Button type="submit" variant="dark">
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export default ChangePasswordDialog;
