import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ImgLogo from '@/assets/images/logo.png';

const LogoText = styled.div`
  font-family: 'Lobster Two', sans-serif;
`;

const HeaderLogoOnly = () => {
  return (
    <Navbar bg="light" expand="lg" className="py-4">
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex">
          <div className="me-3">
            <img src={ImgLogo} width={66} alt="" />
          </div>
          <LogoText>
            High End
            <br />
            Hammy downs
          </LogoText>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default HeaderLogoOnly;
