import styled from 'styled-components';

import MatchSessionTable from './MatchSession';
import SetTable from './Set';
import UserTable from './User';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const DashboardPage = () => {
  return (
    <div className="p-5">
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Matches</PageTitle>
      </div>
      <MatchSessionTable />
      <div className="pt-5 my-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Sets</PageTitle>
      </div>
      <SetTable />
      <div className="pt-5 my-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Users</PageTitle>
      </div>
      <UserTable />
    </div>
  );
};

export default DashboardPage;
