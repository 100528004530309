import _ from 'lodash';
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { CONTACT } from '@/constants/apis';
import { useApi } from '@/utils/axios';

type ContactFormData = {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
};

const ContactForm = () => {
  const alert = useAlert();

  const [formData, setFormData] = useState<ContactFormData>({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const [loading, doSendContact] = useApi<
    {},
    {
      first_name: string;
      last_name: string;
      email: string;
      message: string;
    }
  >({
    method: 'POST',
    url: CONTACT,
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    try {
      await doSendContact({
        data: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          message: formData.message,
        },
      });

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      });

      alert.success('The mail has been sent successfully, we will respond as soon. Thank you!');
    } catch (error: any) {
      console.error(error);

      if (!_.isNil(error.response) && !_.isNil(error.response.data.errors)) {
        alert.error('Please fill in all the information.');
      } else {
        alert.error('There is an error from the server.');
      }
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <div className="mb-4">
        <Row>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder="First Name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    firstName: e.target.value,
                  })
                }
                disabled={loading}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder="Last Name"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    lastName: e.target.value,
                  })
                }
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="mb-4">
        <Row>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="mb-5">
        <Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Message"
            rows={5}
            value={formData.message}
            onChange={(e) =>
              setFormData({
                ...formData,
                message: e.target.value,
              })
            }
            disabled={loading}
          />
        </Form.Group>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          type="submit"
          variant="warning"
          disabled={
            loading ||
            _.isEmpty(formData.firstName) ||
            _.isEmpty(formData.lastName) ||
            _.isEmpty(formData.email) ||
            _.isEmpty(formData.message)
          }
        >
          Send
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
