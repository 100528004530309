import 'bootstrap/scss/bootstrap.scss';

import React from 'react';
import { Provider as AlertProvider } from 'react-alert';
import { Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { ClearCacheProvider } from 'react-clear-cache';
import ReactDOM from 'react-dom/client';
import { LoadingProvider } from 'react-hook-loading';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
import { StoreProvider } from '@/store';
import ThemeProvider, { ThemedGlobalStyle } from '@/theme';

const LoaderWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
`;

const AlertTemplate = ({
  message,
  close,
  options,
}: {
  message: any;
  close: () => void;
  options: any;
}) => (
  <ToastContainer position="top-end" className="p-3">
    <Toast onClose={close}>
      <Toast.Header>
        <strong className="me-auto">
          {options.type === 'info' && 'Info'}
          {options.type === 'success' && 'Success'}
          {options.type === 'error' && 'Error'}
        </strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  </ToastContainer>
);

const LoadingTemplate = () => (
  <LoaderWrapper className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
    <Spinner animation="border" variant="warning" />
  </LoaderWrapper>
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ClearCacheProvider duration={5000}>
      <BrowserRouter>
        <StoreProvider>
          <ThemeProvider>
            <ThemedGlobalStyle />
          </ThemeProvider>
          <AlertProvider template={AlertTemplate} timeout={4000} containerStyle={{ zIndex: 9999 }}>
            <LoadingProvider loading={<LoadingTemplate />}>
              <App />
            </LoadingProvider>
          </AlertProvider>
        </StoreProvider>
      </BrowserRouter>
    </ClearCacheProvider>
  </React.StrictMode>
);

reportWebVitals();
