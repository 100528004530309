import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { Link } from 'react-router-dom';

import Paginate from '@/components/Paginate';
import { ADMIN_USER } from '@/constants/apis';
import { User } from '@/models/User';
import { useApi } from '@/utils/axios';

const UserTable = () => {
  const [, setGlobalLoading] = useLoading();

  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [loading, getUsers] = useApi<
    {
      meta: { last_page: number };
      data: {
        id: number;
        full_name: string;
        email: string;
        postcode: string;
        created_at: string;
      }[];
    },
    unknown
  >({
    url: ADMIN_USER,
    params: { page },
  });

  useEffect(() => {
    loadUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadUsers = async () => {
    if (loading) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getUsers();

    setGlobalLoading(false);

    setTotalPage(data.meta.last_page);
    setUsers(
      _(data.data)
        .map<User>(({ id, full_name: fullName, email, postcode, created_at: createdAt }) => ({
          id,
          fullName,
          email,
          postcode,
          createdAt: moment(createdAt),
        }))
        .value()
    );
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <td className="border-0 px-4">Name</td>
            <td className="border-0 px-4">Email</td>
            <td className="border-0 px-4">Postcode</td>
            <td className="border-0 px-4">Registration Date</td>
            <td className="border-0 px-4"></td>
          </tr>
        </thead>
        <tbody>
          {_(users)
            .orderBy((user) => user.createdAt, 'desc')
            .map((user, i) => (
              <tr key={i} className="mb-1">
                <td className="bg-light border-0 align-middle px-4 py-3">{user.fullName}</td>
                <td className="bg-light border-0 align-middle px-4 py-3">{user.email}</td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {user.postcode || '---'}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {user.createdAt!.format('lll')}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  <div className="d-flex gap-2">
                    <Link to={`/admin/users/${user.id}`}>
                      <Button variant="outline-dark" size="sm">
                        Details
                      </Button>
                    </Link>
                    <Link to={`/admin/users/${user.id}/sets`}>
                      <Button variant="outline-dark" size="sm">
                        Bundles
                      </Button>
                    </Link>
                  </div>
                </td>
              </tr>
            ))
            .value()}
        </tbody>
      </Table>
      {users.length > 0 && totalPage > 0 && (
        <div className="mt-5 d-flex justify-content-center">
          <Paginate
            pageCount={totalPage}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      )}
    </>
  );
};

export default UserTable;
