import { Outlet } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import HeaderLogoOnly from '@/components/HeaderLogoOnly';

const SignUpInfoLayout = () => {
  return (
    <>
      <HeaderLogoOnly />
      <Outlet />
      <ScrollToTop smooth />
    </>
  );
};

export default SignUpInfoLayout;
