import { Image as BsImage } from 'react-bootstrap';
import styled from 'styled-components';

import ImgBlank from '@/assets/images/blank.png';

const Element = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Image = ({ src, className }: { src: string; className?: string }) => {
  return (
    <Element src={src} className={className}>
      <BsImage src={ImgBlank} className="w-100 h-100" />
    </Element>
  );
};

export default Image;
