import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { User } from '@/models/User';

import { MatchSessionStatus } from './MatchSession';

export enum SetType {
  receive = 'receive',
  give = 'give',
}

export enum SetGender {
  male = 'male',
  female = 'female',
  neutral = 'neutral',
}

export type Set = {
  id: number;
  type: SetType;
  gender: SetGender;
  numOfItems: NumOfItems;
  sizes: Sizes[];
  typesOfClothing: TypesOfClothing[];
  clothingStyles: ClothingStyles[];
  isHEHD: boolean;
  user?: User;
  createdAt?: moment.Moment;
};

export type SetWithMatchSession = Set & {
  giveMatchSessions?: { status: MatchSessionStatus }[];
  receiveMatchSessions?: { status: MatchSessionStatus }[];
};

export type SetGiveGallery = {
  id: number;
  url: string;
};

export type SetGive = Set & {
  giveGalleries: SetGiveGallery[];
};

export type SetForm = {
  index?: number;
  name?: string;
  gender?: string;
  numOfItems?: string;
  sizes?: string[];
  typesOfClothing?: string[];
  clothingStyles?: string[];
  isHEHD?: boolean;
};

export type SetGiveForm = SetForm & {
  giveGalleries?: File[];
  oldGiveGalleries?: SetGiveGallery[];
  destroyGiveGalleries?: number[];
};
