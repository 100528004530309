import _ from 'lodash';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useStore } from '@/store';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const ProfilePage = () => {
  const { profileStore } = useStore();

  return (
    <div className="p-5">
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Overview</PageTitle>
      </div>
      <Row>
        <Col>
          <div className="mb-4 fw-bold">Profile Data</div>
          <Row className="mb-3">
            <Col lg={4}>Name</Col>
            <Col className="text-truncate">{profileStore.currentUser!.fullName}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Email</Col>
            <Col className="text-truncate">{profileStore.currentUser!.email}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Country</Col>
            <Col className="text-truncate">{profileStore.currentUser!.country || '---'}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Postcode</Col>
            <Col className="text-truncate">{profileStore.currentUser!.postcode || '---'}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Swapping Method</Col>
            <Col className="text-truncate">
              {profileStore.currentUser!.swappingMethods!.map((s) => s.name).join(', ')}
            </Col>
          </Row>
          {_.some(profileStore.currentUser!.swappingMethods, (s) => s.isRequiredAddress) && (
            <Row className="mb-3">
              <Col lg={4}>Address</Col>
              <Col className="text-truncate">{profileStore.currentUser!.address || '---'}</Col>
            </Row>
          )}
          <div className="mt-5">
            <Link to="/me/edit">
              <Button variant="outline-dark">Edit Data</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProfilePage;
