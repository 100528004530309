import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Container, Stack } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AlertDialog from '@/components/AlertDialog';
import { PROFILE, SET, SWAPPING_METHOD } from '@/constants/apis';
import { Country } from '@/enums/CountryState';
import { SetForm, SetGiveForm, SetType } from '@/models/Set';
import { SwappingMethod, UserAboutForm } from '@/models/User';
import AboutYou from '@/pages/UserInfoFistTime/AboutYou';
import CompletedInfo from '@/pages/UserInfoFistTime/CompletedInfo';
import EnterSetInfo from '@/pages/UserInfoFistTime/EnterSetInfo';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';

const Banner = styled.div`
  background-color: #f7d9d3;
`;

const StepItemWrapper = styled.div`
  border-top-color: #707070 !important;
`;

const UserInfoFirstTimePage = () => {
  const navigate = useNavigate();

  const { profileStore } = useStore();

  const [, setGlobalLoading] = useLoading();

  // 0: About
  // 1: Receive
  // 2: Give
  const [step, setStep] = useState<number>(0);

  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const [isFirstTimeShowModal, setIsFirstTimeShowModal] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const [userAbout, setUserAbout] = useState<UserAboutForm>({});
  const [receiveSets, setReceiveSets] = useState<SetForm[]>([]);
  const [giveSets, setGiveSets] = useState<SetGiveForm[]>([]);

  const [swappingMethods, setSwappingMethods] = useState<SwappingMethod[]>([]);

  const [loading, getSwappingMethod] = useApi<
    {
      id: number;
      name: string;
      is_required_address: boolean;
    }[],
    unknown
  >({
    url: SWAPPING_METHOD,
  });

  useEffect(() => {
    loadSwappingMethod();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSwappingMethod = async () => {
    if (loading) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getSwappingMethod();

    setSwappingMethods(
      _.map(data, (s) => ({
        id: s.id,
        name: s.name,
        isRequiredAddress: s.is_required_address,
      }))
    );

    setGlobalLoading(false);
  };

  const [loadingProfile, doUpdateProfile] = useApi<
    { success: boolean },
    {
      country?: string | undefined;
      postcode?: string | undefined;
      user_receive_give?: 'receive' | 'give' | 'both';
      swapping_method_ids?: number[] | undefined;
      address?: string;
    }
  >({
    method: 'PUT',
    url: PROFILE,
  });

  const [loadingCreateSet, doCreateSet] = useApi<
    { success: boolean },
    | {
        type: SetType;
        gender: string;
        sizes: string[];
        num_of_items: string;
        types_of_clothing: string[];
        clothing_styles: string[];
        is_hehd: boolean;
      }
    | FormData
  >({
    method: 'POST',
    url: SET,
  });

  useEffect(() => {
    setUserAbout({
      country: Country.Australia,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReceiveSetNumberChanged = (n: number) => {
    if (n > receiveSets.length) {
      setReceiveSets((prevSets) => [
        ...prevSets,
        ..._.range(n - prevSets.length).map((i) => ({
          index: prevSets.length + i + 1,
          name: `Bundle ${prevSets.length + i + 1}`,
        })),
      ]);
    } else {
      setReceiveSets((prevSets) => _(prevSets).take(n).value());
    }
  };

  const handleGiveSetNumberChanged = (n: number) => {
    if (n > giveSets.length) {
      setGiveSets((prevSets) => [
        ...prevSets,
        ..._.range(n - prevSets.length).map((i) => ({
          index: prevSets.length + i + 1,
          name: `Bundle ${prevSets.length + i + 1}`,
        })),
      ]);
    } else {
      setGiveSets((prevSets) => _(prevSets).take(n).value());
    }
  };

  const handleSubmitProfileData = async () => {
    setGlobalLoading(true);

    try {
      await doUpdateProfile({
        data: {
          country: userAbout.country,
          postcode: userAbout.postcode,
          user_receive_give: userAbout.receiveGive,
          swapping_method_ids: userAbout.swappingMethodIds,
          address: userAbout.address,
        },
      });

      profileStore.setCurrentUser({
        ...profileStore.currentUser!,
        postcode: userAbout.postcode || '',
        country: userAbout.country,
        swappingMethods: swappingMethods.filter((s) =>
          (userAbout.swappingMethodIds || []).includes(s.id)
        ),
        address: userAbout.address,
      });
    } catch (error) {
      console.error(error);
    }

    setGlobalLoading(false);
  };

  const handleSubmitData = async () => {
    if (loadingProfile || loadingCreateSet) {
      return;
    }

    setGlobalLoading(true);

    try {
      await doUpdateProfile({
        data: {
          country: userAbout.country,
          postcode: userAbout.postcode,
          user_receive_give: userAbout.receiveGive,
          swapping_method_ids: userAbout.swappingMethodIds,
          address: userAbout.address,
        },
      });

      profileStore.setCurrentUser({
        ...profileStore.currentUser!,
        postcode: userAbout.postcode || '',
        swappingMethods: swappingMethods.filter((s) =>
          (userAbout.swappingMethodIds || []).includes(s.id)
        ),
        address: userAbout.address,
      });
    } catch (error) {
      console.error(error);
    }

    try {
      await Promise.all(
        receiveSets.map(async (set) => {
          try {
            await doCreateSet({
              data: {
                type: SetType.receive,
                gender: set.gender!,
                sizes: set.sizes!,
                num_of_items: set.numOfItems!,
                types_of_clothing: set.typesOfClothing!,
                clothing_styles: set.clothingStyles!,
                is_hehd: set.isHEHD!,
              },
            });
          } catch (error) {
            console.error(error);
          }
        })
      );
    } catch (error) {
      console.error(error);
    }

    try {
      if (!(userAbout.receiveGive === 'both' && step === 2)) {
        await Promise.all(
          giveSets.map(async (set) => {
            try {
              const formData = new FormData();

              formData.append('type', 'give');
              formData.append('gender', set.gender!);

              set.sizes!.forEach((s) => {
                formData.append('sizes[]', s);
              });

              formData.append('num_of_items', set.numOfItems!);

              set.typesOfClothing!.forEach((s) => {
                formData.append('types_of_clothing[]', s);
              });

              set.clothingStyles!.forEach((s) => {
                formData.append('clothing_styles[]', s);
              });

              set.giveGalleries!.forEach((s) => {
                formData.append('give_galleries[]', s);
              });

              formData.append('is_hehd', _.toString(set.isHEHD));

              await doCreateSet({
                data: formData,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            } catch (error) {
              console.error(error);
            }
          })
        );
      }

      if (userAbout.receiveGive === 'both') {
        if (step === 2) {
          setIsCompleted(true);
        } else {
          setIsCompleted(false);
        }
      } else {
        setIsCompleted(true);
      }
    } catch (error) {
      console.error(error);
    }

    setGlobalLoading(false);
  };

  const handleBackStepClicked = () => {
    switch (step) {
      case 1:
        setStep(0);

        break;
      case 2:
        if (userAbout.receiveGive === 'receive') {
          setStep(0);
        } else {
          setStep(1);
        }

        break;
      default:
        break;
    }
  };

  const handleNextStepClicked = () => {
    switch (step) {
      case 0:
        if (userAbout.receiveGive === 'receive') {
          handleSubmitProfileData();

          setStep(2);
        } else {
          setStep(1);

          handleSubmitProfileData();
        }

        break;
      case 1:
        if (userAbout.receiveGive === 'both') {
          handleSubmitData();

          setStep(2);
        } else {
          handleSubmitData();
        }

        break;
      case 2:
        handleSubmitData();

        break;
      default:
        break;
    }
  };

  const handleModalConfirmClicked = () => {
    setIsShowModal(false);

    handleNextStepClicked();
  };

  const handleModalLaterClicked = async () => {
    setIsShowModal(false);

    await handleSubmitProfileData();

    navigate('/me/overview');
  };

  return (
    <>
      <Banner>
        <Container>
          <div className="py-4 text-center">
            {step === 0 && (
              <>
                <div className="mb-3 h2 fw-bold">About You</div>
                <div className="h5">About You</div>
              </>
            )}
            {step === 1 && (
              <>
                <div className="mb-3 h2 fw-bold">Giving Clothes</div>
                <div className="h5">Giving Clothes</div>
              </>
            )}
            {step === 2 && (
              <>
                <div className="mb-3 h2 fw-bold">Receiving Clothes</div>
                <div className="h5">Receiving Clothes</div>
              </>
            )}
          </div>
        </Container>
      </Banner>
      <StepItemWrapper className="border-top">
        <Container>
          <div className="py-5">
            {!isCompleted ? (
              <>
                {step === 0 && (
                  <AboutYou
                    data={userAbout}
                    swappingMethods={swappingMethods}
                    onNextClicked={() => {
                      if (!isFirstTimeShowModal) {
                        setIsShowModal(true);
                        setIsFirstTimeShowModal(true);
                      } else {
                        handleNextStepClicked();
                      }
                    }}
                  />
                )}
                {step === 1 && (
                  <EnterSetInfo
                    type={SetType.give}
                    data={giveSets}
                    onSetNumberChanged={handleGiveSetNumberChanged}
                    onBackClicked={handleBackStepClicked}
                    onNextClicked={handleNextStepClicked}
                  />
                )}
                {step === 2 && (
                  <EnterSetInfo
                    type={SetType.receive}
                    data={receiveSets}
                    onSetNumberChanged={handleReceiveSetNumberChanged}
                    onBackClicked={handleBackStepClicked}
                    onNextClicked={handleNextStepClicked}
                  />
                )}
              </>
            ) : (
              <CompletedInfo />
            )}
          </div>
        </Container>
      </StepItemWrapper>
      <AlertDialog show={isShowModal} onHide={handleModalLaterClicked}>
        <div className="h4 fw-bold">Thanks for signing up!</div>
        <div className="my-4">
          Click <span className="text-decoration-underline">Create Now</span> to create a clothes
          bundle and be matched instantly or click{' '}
          <span className="text-decoration-underline">Later</span> to redirect to your Profile.
        </div>
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          <Button variant="dark" onClick={handleModalConfirmClicked}>
            Create Now
          </Button>
          <Button variant="outline-dark" onClick={handleModalLaterClicked}>
            Later
          </Button>
        </Stack>
      </AlertDialog>
    </>
  );
};

export default UserInfoFirstTimePage;
