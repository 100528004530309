import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import FooterWithLogo from '@/components/FooterWithLogo';

const Wrapper = styled.div`
  background-color: #f7d9d3;
  height: 100vh;
`;

const Container = styled.div`
  height: 90vh;
`;

const AuthLayout = () => {
  return (
    <Wrapper>
      <Container>
        <Outlet />
      </Container>
      <FooterWithLogo />
    </Wrapper>
  );
};

export default AuthLayout;
