import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleRight,
  faAnglesRight,
  faArchive,
  faArrowRightArrowLeft,
  faBell,
  faCamera,
  faCheck,
  faCheckCircle,
  faClose,
  faCreditCard,
  faDashboard,
  faEye,
  faEyeSlash,
  faFilePdf,
  faHome,
  faLeaf,
  faMessage,
  faQuoteLeft,
  faSearch,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

// Add icons
library.add(
  faAngleRight,
  faCheck,
  faSearch,
  faUser,
  faCheckCircle,
  faArchive,
  faArrowRightArrowLeft,
  faHome,
  faCreditCard,
  faLeaf,
  faClose,
  faAnglesRight,
  faCamera,
  faEye,
  faEyeSlash,
  faBell,
  faMessage,
  faQuoteLeft,
  faFilePdf,
  faDashboard
);
