import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import ImgIcCheck from '@/assets/images/ic_check.png';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const PlanItem = styled.div<{ type: 'basic' | 'premium' }>`
  background-color: ${(props) => (props.type === 'basic' ? '#f7d9d3' : '#F8C400')};

  &.active {
    border-width: 3px !important;
    border-color: #f7d9d3 !important;
  }
`;

const PlanCurrentCheck = styled.div`
  top: 50%;
  left: calc(-46px / 2);
  margin-top: calc(-46px / 2);
  width: 46px;
  height: 46px;
  line-height: 48px;
  background-color: #313638;
`;

const ProfilePlanPage = () => {
  return (
    <div className="p-5 position-relative">
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Your Plan</PageTitle>
      </div>
      <PlanItem
        className="position-relative mb-4 shadow-sm border border-start-0 active"
        type="basic"
      >
        <PlanCurrentCheck className="position-absolute rounded-circle text-center text-white">
          <FontAwesomeIcon icon="check" />
        </PlanCurrentCheck>
        <Row>
          <Col lg={4}>
            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4">
              <div className="mb-3">Current Plan</div>
              <div className="mb-1 h5 fw-bold">Basic</div>
              <div className="text-center">Free account</div>
            </div>
          </Col>
          <Col>
            <div className="p-4 h-100 bg-white">
              <Row className="align-items-center">
                <Col lg={12} className="mb-3">
                  <div className="d-flex align-items-start">
                    <img src={ImgIcCheck} alt="" width={20} className="mt-1" />
                    <div className="ms-3">
                      All matches are free for everyday/non-branded clothing
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="d-flex align-items-start">
                    <img src={ImgIcCheck} alt="" width={20} className="mt-1" />
                    <div className="ms-3">Register up to 2 children </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </PlanItem>
      <PlanItem className="shadow-sm border" type="premium">
        <Row>
          <Col lg={4}>
            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4">
              <div className="mb-1 h5 fw-bold">Premium</div>
              <div className="text-center">Paid account</div>
            </div>
          </Col>
          <Col>
            <div className="p-4 h-100 bg-white">
              <Row className="align-items-center">
                <Col lg={6} className="mb-3">
                  <div className="d-flex align-items-start">
                    <img src={ImgIcCheck} alt="" width={20} className="mt-1" />
                    <div className="ms-3">High end brands only</div>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="d-flex align-items-start">
                    <img src={ImgIcCheck} alt="" width={20} className="mt-1" />
                    <div className="ms-3">$19.99/yr for unlimited matches</div>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="d-flex align-items-start">
                    <img src={ImgIcCheck} alt="" width={20} className="mt-1" />
                    <div className="ms-3">Register more than 2 children</div>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="d-flex align-items-start">
                    <img src={ImgIcCheck} alt="" width={20} className="mt-1" />
                    <div className="ms-3">Connect with more than 1 match at a time</div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="d-flex align-items-start">
                    <img src={ImgIcCheck} alt="" width={20} className="mt-1" />
                    <div className="ms-3">
                      Browse all your matches for free, only pay to connect with receiving matches
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </PlanItem>
    </div>
  );
};

export default ProfilePlanPage;
