import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const RemoveFileBtn = styled.button`
  margin-top: -13px;
  margin-right: -13px;
  width: 36px;
  height: 36px;
  line-height: 39px;
  color: #f7d9d3;
  outline: none;
`;

const AlertDialog = ({
  show,
  children,
  onHide,
  size,
}: {
  show: boolean;
  children: React.ReactNode;
  onHide: () => void;
  size?: 'sm' | 'lg' | 'xl';
}) => {
  return (
    <Modal centered show={show} contentClassName="border-0" onHide={onHide} size={size}>
      <Modal.Body className="position-relative p-5">
        <RemoveFileBtn
          type="button"
          className="position-absolute top-0 end-0 bg-black border-0 rounded-circle"
          onClick={onHide}
        >
          <FontAwesomeIcon icon="close" />
        </RemoveFileBtn>
        <div>{children}</div>
      </Modal.Body>
    </Modal>
  );
};

export default AlertDialog;
