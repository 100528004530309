import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

import ImgIcMsCheck from '@/assets/images/ic_ms_check.png';
import ImgIcMsUncheck from '@/assets/images/ic_ms_uncheck.png';
import ImgIcMsYellowCheck from '@/assets/images/ic_ms_yellow_check.png';
import Image from '@/components/Image';
import { MatchLevel, MatchSession, MatchSessionStatus } from '@/models/MatchSession';
import { SetType } from '@/models/Set';
import { getAusCurrency, getMatchLevelName, getSetGender } from '@/utils/hehd';

const Wrapper = styled.div`
  &.bordered {
    border: 2px solid #f8c400;
  }

  &.rwa-bordered {
    border: 2px solid var(--bs-gray-500);
  }
`;

const ImageBox = styled.div`
  &:last-child {
    margin-right: 0px !important;
  }
`;

const CheckItem = styled.div`
  padding-left: 2.2rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-image: url(${ImgIcMsCheck});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.yellow {
    &::before {
      background-image: url(${ImgIcMsYellowCheck});
    }
  }

  &.uncheck {
    &::before {
      background-image: url(${ImgIcMsUncheck});
    }
  }
`;

const Button = styled.div`
  margin: 0 -2px -2px;
  background-color: #f7d9d3;

  &.yellow {
    background-color: #f8c400;
  }
`;

const HEHDBadge = styled(Badge)`
  top: 6px;
  right: 6px;
`;

const HEHDBadgeBasic = styled(HEHDBadge)`
  background-color: #f7d9d3 !important;
`;

const MatchSessionCheckList = ({ data }: { type: SetType; data: MatchSession }) => {
  const getMatchedItems = (v1: any[], v2: any[]) => {
    if (v1.length > v2.length) {
      return _(v1)
        .filter((s) => _.includes(v2, s))
        .join(', ');
    } else {
      return _(v2)
        .filter((s) => _.includes(v1, s))
        .join(', ');
    }
  };

  return (
    <>
      {data.level === MatchLevel.perfect && (
        <>
          <CheckItem className="position-relative mb-1 yellow">
            {getSetGender(data.setGive.gender)}
          </CheckItem>
          <CheckItem className="position-relative mb-1 yellow">
            Size {getMatchedItems(data.setGive.sizes, data.setReceive.sizes)}
          </CheckItem>
          <CheckItem className="position-relative mb-1 yellow">
            {data.setGive.numOfItems} items
          </CheckItem>
          <CheckItem className="position-relative mb-1 yellow">
            {data.setGive.typesOfClothing.join(', ')}
          </CheckItem>
          <CheckItem className="position-relative mb-1 yellow">
            {getMatchedItems(data.setGive.clothingStyles, data.setReceive.clothingStyles)}
          </CheckItem>
          <CheckItem className="position-relative mb-1 yellow">
            {data.setGive.user!.country}, {data.setGive.user!.postcode}
          </CheckItem>
          {_(data.setGive.user!.swappingMethods!)
            .orderBy((swappingMethod) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return !isMatch;
            })
            .map((swappingMethod, i) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return (
                <CheckItem
                  className={classNames('position-relative mb-1 yellow', !isMatch && 'uncheck')}
                  key={i}
                >
                  {swappingMethod.name}
                </CheckItem>
              );
            })
            .value()}
        </>
      )}
      {data.level === MatchLevel.happyHammy && (
        <>
          <CheckItem className="position-relative mb-1">
            {getSetGender(data.setGive.gender)}
          </CheckItem>
          <CheckItem className="position-relative mb-1">
            Size{' '}
            {_(data.setGive.sizes)
              .filter((s) => _.includes(data.setReceive.sizes, s))
              .join(', ')}
          </CheckItem>
          <CheckItem className="position-relative mb-1">{data.setGive.numOfItems} items</CheckItem>
          <CheckItem className="position-relative mb-1">
            {data.setGive.typesOfClothing.join(', ')}
          </CheckItem>
          <CheckItem className="position-relative mb-1 uncheck">
            {getMatchedItems(data.setGive.clothingStyles, data.setReceive.clothingStyles)}
          </CheckItem>
          <CheckItem className="position-relative mb-1">
            {data.setGive.user!.country}, {data.setGive.user!.postcode}
          </CheckItem>
          {_(data.setGive.user!.swappingMethods!)
            .orderBy((swappingMethod) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return !isMatch;
            })
            .map((swappingMethod, i) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return (
                <CheckItem
                  className={classNames('position-relative mb-1', !isMatch && 'uncheck')}
                  key={i}
                >
                  {swappingMethod.name}
                </CheckItem>
              );
            })
            .value()}
        </>
      )}
      {data.level === MatchLevel.mini && (
        <>
          <CheckItem className="position-relative mb-1">
            {getSetGender(data.setGive.gender)}
          </CheckItem>
          <CheckItem className="position-relative mb-1">
            Size{' '}
            {_(data.setGive.sizes)
              .filter((s) => _.includes(data.setReceive.sizes, s))
              .join(', ')}
          </CheckItem>
          <CheckItem
            className={classNames(
              'position-relative mb-1',
              data.setGive.numOfItems !== data.setReceive.numOfItems && 'uncheck'
            )}
          >
            {data.setGive.numOfItems} items
          </CheckItem>
          <CheckItem className="position-relative mb-1">
            {data.setGive.typesOfClothing.join(', ')}
          </CheckItem>
          <CheckItem className="position-relative mb-1 uncheck">
            {getMatchedItems(data.setGive.clothingStyles, data.setReceive.clothingStyles)}
          </CheckItem>
          <CheckItem className="position-relative mb-1">
            {data.setGive.user!.country}, {data.setGive.user!.postcode}
          </CheckItem>
          {_(data.setGive.user!.swappingMethods!)
            .orderBy((swappingMethod) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return !isMatch;
            })
            .map((swappingMethod, i) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return (
                <CheckItem
                  className={classNames('position-relative mb-1', !isMatch && 'uncheck')}
                  key={i}
                >
                  {swappingMethod.name}
                </CheckItem>
              );
            })
            .value()}
        </>
      )}
      {data.level === MatchLevel.longDistance && (
        <>
          <CheckItem className="position-relative mb-1">
            {getSetGender(data.setGive.gender)}
          </CheckItem>
          <CheckItem className="position-relative mb-1">
            Size{' '}
            {_(data.setGive.sizes)
              .filter((s) => _.includes(data.setReceive.sizes, s))
              .join(', ')}
          </CheckItem>
          <CheckItem className="position-relative mb-1">{data.setGive.numOfItems} items</CheckItem>
          <CheckItem className="position-relative mb-1">
            {data.setGive.typesOfClothing.join(', ')}
          </CheckItem>
          <CheckItem className="position-relative mb-1">
            {getMatchedItems(data.setGive.clothingStyles, data.setReceive.clothingStyles)}
          </CheckItem>
          <CheckItem className="position-relative mb-1 uncheck">
            {data.setGive.user!.country}, {data.setGive.user!.postcode}
          </CheckItem>
          {_(data.setGive.user!.swappingMethods!)
            .orderBy((swappingMethod) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return !isMatch;
            })
            .map((swappingMethod, i) => {
              const isMatch = data.setReceive
                .user!.swappingMethods!.map((s) => s.id)
                .includes(swappingMethod.id);

              return (
                <CheckItem
                  className={classNames('position-relative mb-1', !isMatch && 'uncheck')}
                  key={i}
                >
                  {swappingMethod.name}
                </CheckItem>
              );
            })
            .value()}
        </>
      )}
    </>
  );
};

const MatchSessionItem = ({
  type,
  data,
  onButtonClicked,
}: {
  type: SetType;
  data: MatchSession;
  onButtonClicked: () => void;
}) => {
  return (
    <Wrapper
      className={classNames(
        'd-flex flex-column position-relative shadow h-100',
        data.level === MatchLevel.perfect && ' bordered',
        type === SetType.receive &&
          data.status === MatchSessionStatus.waitingApproval &&
          ' rwa-bordered'
      )}
    >
      {data.setGive.isHEHD ? (
        <HEHDBadge bg="warning" className="position-absolute pt-2 px-2 text-black rounded-0">
          High End
        </HEHDBadge>
      ) : (
        <HEHDBadgeBasic className="position-absolute pt-2 px-2 text-black rounded-0">
          Basic
        </HEHDBadgeBasic>
      )}
      <div className="p-4 pb-0">
        <div className="mb-3 fw-bold">
          {type === SetType.receive ? data.setGive.user!.fullName : data.setReceive.user!.fullName}
        </div>
        <div className="mb-4 d-flex gap-3">
          {_(data.setGive.giveGalleries)
            .take(2)
            .map((gallery, i) => (
              <div className="w-50" key={i}>
                <ImageBox className="ratio ratio-1x1 bg-light" key={i}>
                  <Image src={gallery.url} className="w-100" />
                </ImageBox>
              </div>
            ))
            .value()}
        </div>
      </div>
      <div className="p-4 pt-0 flex-fill">
        <MatchSessionCheckList type={type} data={data} />
      </div>
      <Button
        role="button"
        className={`d-flex align-items-center px-4 py-3${
          data.level === MatchLevel.perfect ? ' yellow' : ''
        }`}
        onClick={onButtonClicked}
      >
        <div className="me-auto fw-bold text-truncate pe-2">{getMatchLevelName(data.level)}</div>
        {data.isPayRequired && data.unitAmount! > 0 && (
          <div className="fw-bold me-2">{getAusCurrency(data.unitAmount! / 100)}</div>
        )}
        <FontAwesomeIcon icon="angle-right" />
      </Button>
    </Wrapper>
  );
};

export { MatchSessionCheckList };

export default MatchSessionItem;
