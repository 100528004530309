import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _, { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Paginate from '@/components/Paginate';
import { ADMIN_SET } from '@/constants/apis';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { MatchSessionStatus } from '@/models/MatchSession';
import { SetGender, SetType, SetWithMatchSession } from '@/models/Set';
import { useApi } from '@/utils/axios';
import { getMatchSessionStatus, getSetGender } from '@/utils/hehd';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const DashboardSetPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, setGlobalLoading] = useLoading();

  const [sets, setSets] = useState<SetWithMatchSession[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [loading, getSets] = useApi<
    {
      meta: { last_page: number };
      data: {
        id: number;
        type: SetType;
        gender: SetGender;
        num_of_items: NumOfItems;
        sizes: Sizes[];
        types_of_clothing: TypesOfClothing[];
        clothing_styles: ClothingStyles[];
        is_hehd: boolean;
        created_at: string;
        giveMatchSessions: { status: MatchSessionStatus }[];
        receiveMatchSessions: { status: MatchSessionStatus }[];
      }[];
    },
    unknown
  >({
    url: ADMIN_SET,
    params: { page, user_id: id },
  });

  useEffect(() => {
    loadSets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadSets = async () => {
    if (loading) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getSets();

    setGlobalLoading(false);

    setTotalPage(data.meta.last_page);
    setSets(
      _(data.data)
        .map<SetWithMatchSession>(
          ({
            id,
            type,
            gender,
            num_of_items: numOfItems,
            sizes,
            types_of_clothing: typesOfClothing,
            clothing_styles: clothingStyles,
            is_hehd: isHEHD,
            created_at: createdAt,
            giveMatchSessions,
            receiveMatchSessions,
          }) => ({
            id,
            type,
            gender,
            numOfItems,
            sizes,
            typesOfClothing,
            clothingStyles,
            isHEHD,
            createdAt: moment(createdAt),
            giveMatchSessions,
            receiveMatchSessions,
          })
        )
        .value()
    );
  };

  const getSetStatus = (set: SetWithMatchSession) => {
    let matchSession;

    if (set.type === SetType.give) {
      if (isNil(set.giveMatchSessions) || isEmpty(set.giveMatchSessions)) {
        return 'Pending';
      }

      [matchSession] = set.giveMatchSessions;
    } else {
      if (isNil(set.receiveMatchSessions) || isEmpty(set.receiveMatchSessions)) {
        return 'Pending';
      }

      [matchSession] = set.receiveMatchSessions;
    }

    return getMatchSessionStatus(matchSession.status);
  };

  return (
    <div className="p-5">
      <div role="button" className="mb-5 d-flex align-items-center" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="ms-2 fw-bold">Back</span>
      </div>
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Bundles</PageTitle>
      </div>
      <Table>
        <thead>
          <tr>
            <td className="border-0 px-4">Type</td>
            <td className="border-0 px-4">Gender</td>
            <td className="border-0 px-4">Number Of Items</td>
            <td className="border-0 px-4">Sizes</td>
            <td className="border-0 px-4">Type Of Clothing</td>
            <td className="border-0 px-4">Clothing Styles</td>
            <td className="border-0 px-4">Status</td>
            <td className="border-0 px-4">Created At</td>
          </tr>
        </thead>
        <tbody>
          {_(sets)
            .orderBy((sets) => sets.createdAt, 'desc')
            .map((set, i) => (
              <tr key={i} className="mb-1">
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {set.type === SetType.give ? 'Giving' : 'Receiving'}
                  {set.isHEHD && <Badge bg="warning">(High End)</Badge>}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {getSetGender(set.gender)}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">{set.numOfItems} items</td>
                <td className="bg-light border-0 align-middle px-4 py-3">{set.sizes.join(', ')}</td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {set.typesOfClothing.join(', ')}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {set.clothingStyles.join(', ')}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">{getSetStatus(set)}</td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {set.createdAt!.format('lll')}
                </td>
              </tr>
            ))
            .value()}
        </tbody>
      </Table>
      {sets.length > 0 && totalPage > 0 && (
        <div className="mt-5 d-flex justify-content-center">
          <Paginate
            pageCount={totalPage}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardSetPage;
