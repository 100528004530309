import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Promise from 'bluebird';
import _ from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { HEHD_STORAGE_TOKEN } from '@/constants/skeys';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const useApi = <T, D>(
  baseConfig?: AxiosRequestConfig<D>
): [boolean, (config?: AxiosRequestConfig<D>) => Promise<AxiosResponse<T, D>>] => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const request = async (config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> => {
    try {
      setLoading(true);

      await Promise.delay(200);

      instance.interceptors.request.use((cfg: AxiosRequestConfig<D>) => {
        const token = localStorage.getItem(HEHD_STORAGE_TOKEN);

        if (!_.isNil(token) && !_.isEmpty(token)) {
          cfg.headers = {
            ...(cfg.headers || {}),
            Authorization: `Bearer ${token}`,
          };
        }

        return cfg;
      });

      instance.interceptors.response.use(
        (cfg: AxiosResponse<T, D>) => {
          return cfg;
        },
        (err: AxiosError<T, D>) => {
          if (!_.isNil(err.response) && err.response.status === 401) {
            localStorage.removeItem(HEHD_STORAGE_TOKEN);

            navigate(0);
          }

          return Promise.reject(err);
        }
      );

      return instance.request({ ...baseConfig, ...config });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return [loading, request];
};
