import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ADMIN_USER } from '@/constants/apis';
import { Country } from '@/enums/CountryState';
import { User, UserPlan } from '@/models/User';
import { useApi } from '@/utils/axios';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const DashboardUserDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, setGlobalLoading] = useLoading();

  const [user, setUser] = useState<User | null>(null);

  const [, getUser] = useApi<
    {
      id: number;
      full_name: string;
      email: string;
      plan: UserPlan;
      country: Country;
      postcode: string;
      is_info_entered: boolean;
      is_admin: boolean;
      swapping_methods: {
        id: number;
        name: string;
        is_required_address: boolean;
      }[];
      address: string;
    },
    unknown
  >({
    url: `${ADMIN_USER}/${id}`,
  });

  useEffect(() => {
    loadUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = async () => {
    setGlobalLoading(true);

    const { data } = await getUser();

    setUser({
      id: data.id,
      fullName: data.full_name,
      email: data.email,
      plan: data.plan,
      country: data.country,
      postcode: data.postcode,
      isInfoEntered: data.is_info_entered,
      isAdmin: data.is_admin,
      swappingMethods: _(data.swapping_methods)
        .map((s) => ({
          id: s.id,
          name: s.name,
          isRequiredAddress: s.is_required_address,
        }))
        .value(),
      address: data.address,
    });

    setGlobalLoading(false);
  };

  return (
    <div className="p-5">
      <div role="button" className="mb-5 d-flex align-items-center" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="ms-2 fw-bold">Back</span>
      </div>
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">User Detail</PageTitle>
      </div>
      {user && (
        <>
          <Row className="mb-3">
            <Col lg={4}>Name</Col>
            <Col className="text-truncate">{user!.fullName}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Email</Col>
            <Col className="text-truncate">{user!.email}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Country</Col>
            <Col className="text-truncate">{user!.country || '---'}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Postcode</Col>
            <Col className="text-truncate">{user!.postcode || '---'}</Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>Swapping Method</Col>
            <Col className="text-truncate">
              {user!.swappingMethods!.map((s) => s.name).join(', ')}
            </Col>
          </Row>
          {_.some(user!.swappingMethods, (s) => s.isRequiredAddress) && (
            <Row className="mb-3">
              <Col lg={4}>Address</Col>
              <Col className="text-truncate">{user!.address || '---'}</Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardUserDetailPage;
