import ReactPaginate from 'react-paginate';

const Paginate = ({
  pageCount,
  onPageChange,
}: {
  pageCount: number;
  onPageChange: (n: number) => void;
}) => {
  return (
    <ReactPaginate
      className="m-0 btn-group list-unstyled bg-white"
      activeClassName="btn-warning"
      pageClassName="btn btn-outline-warning p-0"
      previousClassName="btn btn-outline-warning p-0"
      nextClassName="btn btn-outline-warning p-0"
      pageLinkClassName="px-3 py-2 d-inline-block text-dark"
      previousLinkClassName="px-3 py-2 d-inline-block text-dark"
      nextLinkClassName="px-3 py-2 d-inline-block text-dark"
      breakClassName="btn btn-outline-warning p-0"
      breakLinkClassName="px-3 py-2 d-inline-block text-dark"
      pageCount={pageCount}
      onPageChange={({ selected }) => {
        onPageChange(selected + 1);
      }}
    />
  );
};

export default Paginate;
