import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ImgBgSignUp from '@/assets/images/bg_signup.png';
import ImgLogo from '@/assets/images/logo.png';
import { AUTH_SIGNUP } from '@/constants/apis';
import { HEHD_STORAGE_TOKEN } from '@/constants/skeys';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';

const FormWrapper = styled.div`
  padding-left: 5em;
  padding-right: 5em;

  @media (max-width: 767.98px) {
    padding-left: 3em;
    padding-right: 3em;
  }
`;

const FormRightImage = styled.div`
  width: 200px;
  background-color: #dddddd;
  background-image: url(${ImgBgSignUp});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

type SignUpFormData = {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  isTerms: boolean;
};

const SignUpPage = () => {
  const [, setGlobalLoading] = useLoading();

  const alert = useAlert();

  const navigate = useNavigate();

  const { authStore } = useStore();

  const [formData, setFormData] = useState<SignUpFormData>({
    fullName: process.env.NODE_ENV === 'development' ? 'Toan Doan' : '',
    email: process.env.NODE_ENV === 'development' ? 'toandev.95@gmail.com' : '',
    password: process.env.NODE_ENV === 'development' ? '123456' : '',
    passwordConfirmation: process.env.NODE_ENV === 'development' ? '123456' : '',
    isTerms: false,
  });
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordConfirmation, setIsShowPasswordConfirmation] = useState<boolean>(false);

  const [loading, doSignUp] = useApi<
    {
      success: boolean;
      token: {
        type: string;
        token: string;
      };
    },
    {
      full_name: string;
      email: string;
      password: string;
      password_confirmation: string;
    }
  >({
    method: 'POST',
    url: AUTH_SIGNUP,
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setGlobalLoading(true);

    try {
      const { data } = await doSignUp({
        data: {
          full_name: formData.fullName,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.passwordConfirmation,
        },
      });

      if (data.success) {
        authStore.setToken(data.token.token);

        localStorage.setItem(HEHD_STORAGE_TOKEN, data.token.token);

        navigate('/sign-up/info');
      }
    } catch (error: any) {
      console.error(error);

      if (!_.isNil(error.response) && !_.isNil(error.response.data.errors)) {
        const errors: {
          rule: string;
          field: string;
        }[] = error.response.data.errors;

        switch (errors[0].rule) {
          case 'email':
            switch (errors[0].field) {
              default:
                alert.error('Email address is not valid.');

                break;
            }
            break;
          case 'confirmed':
            switch (errors[0].field) {
              case 'password_confirmation':
                alert.error('Confirmation password does not match.');

                break;
              default:
                break;
            }
            break;
          case 'unique':
            switch (errors[0].field) {
              case 'email':
                alert.error('Email already used.');

                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="py-4 py-md-5">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} lg={8}>
            <div className="d-flex bg-white shadow">
              <FormWrapper className="flex-fill py-4">
                <Form onSubmit={handleFormSubmit}>
                  <div className="mb-4 text-center">
                    <div className="mb-3">
                      <img src={ImgLogo} width={66} alt="" />
                    </div>
                    <div className="h2 fw-bold">Sign Up Today</div>
                    <div>Create an account, it's free</div>
                  </div>
                  <div>
                    <Form.Group className="mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            fullName: e.target.value,
                          })
                        }
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          })
                        }
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <InputGroup>
                        <Form.Control
                          type={isShowPassword ? 'text' : 'password'}
                          placeholder="Password"
                          value={formData.password}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            })
                          }
                          disabled={loading}
                          required
                        />
                        <InputGroup.Text
                          role="button"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                          <FontAwesomeIcon icon={isShowPassword ? 'eye' : 'eye-slash'} />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <InputGroup>
                        <Form.Control
                          type={isShowPasswordConfirmation ? 'text' : 'password'}
                          placeholder="Password Confirmation"
                          value={formData.passwordConfirmation}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              passwordConfirmation: e.target.value,
                            })
                          }
                          disabled={loading}
                          required
                        />
                        <InputGroup.Text
                          role="button"
                          onClick={() => setIsShowPasswordConfirmation(!isShowPasswordConfirmation)}
                        >
                          <FontAwesomeIcon
                            icon={isShowPasswordConfirmation ? 'eye' : 'eye-slash'}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex mb-4">
                      <Form.Check
                        type="checkbox"
                        label={
                          <div className="ms-2">
                            By siging up, you agree to the{' '}
                            <span className="fw-bold text-decoration-underline">
                              Terms and Conditions
                            </span>
                          </div>
                        }
                        checked={formData.isTerms}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            isTerms: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="text-center">
                      <div className="mb-4">
                        <Button
                          type="submit"
                          variant="dark"
                          disabled={loading || !formData.isTerms}
                        >
                          Create Account
                        </Button>
                      </div>
                      <div>
                        Already have an account?{' '}
                        <Link to="/sign-in" className="fw-bold text-decoration-none text-black">
                          Sign In
                        </Link>
                      </div>
                    </div>
                  </div>
                </Form>
              </FormWrapper>
              <FormRightImage className="d-none d-md-block" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUpPage;
