import _ from 'lodash';
import { FormEvent } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';

import ImgBgSignUpInfo from '@/assets/images/bg_signup_info.png';
import { GiveSetInfo, ReceiveSetInfo } from '@/components/FirstTimeSetItem';
import { SetForm, SetGiveForm, SetType } from '@/models/Set';

const LeftImage = styled.div`
  height: 100%;
  max-height: 600px;
  background-color: #dddddd;
  background-image: url(${ImgBgSignUpInfo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const FormWrapper = styled.div`
  min-height: 300px;
`;

const EnterSetInfo = ({
  type,
  data,
  onSetNumberChanged,
  onNextClicked,
  onBackClicked,
}: {
  type: SetType;
  data: SetForm[] | SetGiveForm[];
  onSetNumberChanged: (n: number) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
}) => {
  const alert = useAlert();

  const hasEmpty = (predicate: _.ListIterateeCustom<SetForm, boolean>) =>
    _(data).filter(predicate).isEmpty();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (_.isEmpty(data)) {
      alert.info('Please select the number of bundles to create.');

      return;
    }

    const hasSizesEmpty = hasEmpty((v: SetForm) => !_.isNil(v.sizes) && !_.isEmpty(v.sizes));
    const hasTypesOfClothingEmpty = hasEmpty(
      (v: SetForm) => !_.isNil(v.typesOfClothing) && !_.isEmpty(v.typesOfClothing)
    );
    const hasClothingStylesEmpty = hasEmpty(
      (v: SetForm) => !_.isNil(v.clothingStyles) && !_.isEmpty(v.clothingStyles)
    );

    if (hasSizesEmpty) {
      alert.info('You need to choose at least one Sizes.');

      return;
    }

    if (hasTypesOfClothingEmpty) {
      alert.info('You need to choose at least one Types Of Clothing.');

      return;
    }

    if (hasClothingStylesEmpty) {
      alert.info('You need to choose at least one Clothing Styles.');

      return;
    }

    if (type === SetType.give) {
      const hasGiveGalleriesEmpty = hasEmpty(
        (v: SetGiveForm) => !_.isNil(v.giveGalleries) && !_.isEmpty(v.giveGalleries)
      );

      if (hasGiveGalleriesEmpty) {
        alert.info('You need to upload at least 1 photo for each set!');

        return;
      }
    }

    onNextClicked();
  };

  return (
    <Row>
      <Col>
        <LeftImage />
      </Col>
      <Col lg={7}>
        <Form onSubmit={handleFormSubmit}>
          <FormWrapper>
            <Form.Group className="mb-4">
              <Form.Label>
                How many bundles of clothes do you want to{' '}
                {type === SetType.receive ? 'Receive' : 'Give'}?
              </Form.Label>
              <Form.Select
                defaultValue={data.length}
                onChange={(e) => onSetNumberChanged(parseInt(e.currentTarget.value, 10))}
              >
                <option>Select Number</option>
                {[1, 2, 3, 4].map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {type === SetType.receive &&
              data.map((item, i) => (
                <div className="mb-5" key={i}>
                  <ReceiveSetInfo data={item} />
                </div>
              ))}
            {type === SetType.give &&
              data.map((item, i) => (
                <div className="mb-5" key={i}>
                  <GiveSetInfo data={item} />
                </div>
              ))}
          </FormWrapper>
          <div className="mt-3 text-end">
            <Button variant="outline-dark" className="me-2" onClick={onBackClicked}>
              Go Back
            </Button>
            <Button type="submit" variant="dark">
              Continue
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default EnterSetInfo;
