import { forwardRef, ReactNode } from 'react';
import { Button, Container, Dropdown, Nav, Navbar, NavLinkProps } from 'react-bootstrap';
import { NavbarToggleProps } from 'react-bootstrap/esm/NavbarToggle';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ImgLogo from '@/assets/images/logo.png';

const LogoText = styled.div`
  font-family: 'Lobster Two', sans-serif;
`;

const CustomNavLink = styled(Nav.Link)<NavLinkProps>`
  text-decoration: none;

  &.active {
    font-weight: 700;

    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #f8c400;
    }
  }

  @media (max-width: 767.98px) {
    &.active {
      font-weight: 700;

      ::after {
        width: 3px;
        height: 100%;
      }
    }
  }
`;

const NavbarToggle = styled(Navbar.Toggle)<NavbarToggleProps>`
  font-size: 2rem;
  box-shadow: none !important;
`;

const CustomToggle = forwardRef<
  unknown,
  {
    children: ReactNode;
    onClick: (e: any) => void;
  }
>(({ children, onClick }, ref: any) => (
  <span ref={ref} onClick={onClick} role="button">
    {children}
  </span>
));
const Header = () => {
  const navigate = useNavigate();

  return (
    <Navbar bg="light" expand="lg" className="py-4">
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex">
          <div className="me-0 me-md-3">
            <img src={ImgLogo} width={66} alt="" />
          </div>
          <LogoText className="d-none d-md-inline">
            High End
            <br />
            Hammy downs
          </LogoText>
        </Navbar.Brand>
        <NavbarToggle className="border-0 p-0" />
        <Navbar.Collapse id="navbar">
          <Nav className="mt-4 mt-md-0 mx-auto">
            <CustomNavLink className="position-relative px-4 py-2 text-black" as={NavLink} to="/">
              Home
            </CustomNavLink>
            <Dropdown className="position-relative px-4 py-2 text-black">
              <Dropdown.Toggle as={CustomToggle}>About</Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 mt-md-0 shadow-sm bg-light">
                <Dropdown.Item as={NavLink} to="/about">
                  About Us
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/events">
                  Events
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/blog">
                  Blog / News
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <CustomNavLink
              className="position-relative px-4 py-2 text-black"
              as={NavLink}
              to="/terms-and-conditions"
            >
              T&amp;Cs
            </CustomNavLink>
            <CustomNavLink
              className="position-relative px-4 py-2 text-black"
              as={NavLink}
              to="/faqs"
            >
              FAQs
            </CustomNavLink>
          </Nav>
          <div className="mt-4 mt-md-0 d-flex flex-column flex-md-row">
            <Button
              variant="outline-dark"
              size="sm"
              className="px-3 py-1 mb-2 mb-md-0 me-md-3 fw-bold"
              onClick={() => navigate('/sign-up')}
            >
              Sign Up
            </Button>
            <Button
              variant="warning"
              size="sm"
              className="px-3 py-1 fw-bold"
              onClick={() => navigate('/sign-in')}
            >
              Sign In
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
