import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ImgIcSocialFacebook from '@/assets/images/ic_social_facebook.png';
import ImgIcSocialInstagram from '@/assets/images/ic_social_instagram.png';
import ImgIcSocialLinkedin from '@/assets/images/ic_social_linkedin.png';

const FooterWrapper = styled.div`
  background-color: #555555;
`;

const LogoText = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 1.5rem;
`;

const FooterWithLogo = () => {
  return (
    <FooterWrapper className="py-4">
      <Container>
        <div className="d-flex flex-column-reverse flex-md-row align-items-center text-white">
          <div>
            <a
              href="https://www.linkedin.com/in/high-end-hammy-downs-38b077219"
              target="_blank"
              rel="noreferrer"
              className="me-4"
            >
              <img src={ImgIcSocialLinkedin} width={30} alt="" />
            </a>
            <a
              href="https://www.facebook.com/highendhammys"
              target="_blank"
              rel="noreferrer"
              className="me-4"
            >
              <img src={ImgIcSocialFacebook} width={30} alt="" />
            </a>
            <a
              href="https://www.instagram.com/highendhammys"
              target="_blank"
              rel="noreferrer"
              className="me-4"
            >
              <img src={ImgIcSocialInstagram} width={30} alt="" />
            </a>
          </div>
          <div className="mb-4 mb-md-0 text-center text-md-start">
            <div className="fw-bold">Contact</div>
            <span>
              Email.{' '}
              <a
                href="mailto:hello@highendhammydowns.com"
                className="text-white text-decoration-none"
              >
                hello@highendhammydowns.com
              </a>
            </span>
          </div>
          <Link to="/" className="text-white text-decoration-none ms-auto d-none d-md-block">
            <LogoText>High End Hammy downs</LogoText>
          </Link>
        </div>
      </Container>
    </FooterWrapper>
  );
};

export default FooterWithLogo;
