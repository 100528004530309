import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import styled from 'styled-components';

import ImgHeart from '@/assets/images/heart.png';
import ImgIcCloud from '@/assets/images/ic_cloud.png';
import ImgIcShirt from '@/assets/images/ic_shirt.png';
import ImgIcWater from '@/assets/images/ic_water.png';
import { MATCH_SESSION_STATS } from '@/constants/apis';
import { useApi } from '@/utils/axios';

const IntroHeartBadge = styled.div`
  top: -8px;
  right: -10px;
  width: 38px;
  line-height: 38px;
  font-size: 1.125em;
`;

const IntroHeart = styled.div`
  width: 440px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

const IntroHeartTotalText = styled.span`
  font-size: 1.125em;
`;

const IntroTotalItem = styled.div`
  height: 150px;
  border: 1px solid #707070;
`;

const IntroTotalItemTitle = styled.div`
  min-height: 54px;
`;

const IntroTotalItemIcon = styled.div`
  width: 40px;
  height: 40px;
  line-height: 32px;
  margin-left: -20px;
  margin-bottom: -20px;
  border: 1px solid #707070;
  background-color: #f7d9d3;
`;

const IntroTotal = () => {
  const [, setGlobalLoading] = useLoading();

  const [totalNumOfItems, setTotalNumOfItems] = useState<number>(0);
  const [totalLandfill, setTotalLandfill] = useState<number>(0);

  const [loading, getStats] = useApi<
    {
      total_num_of_items: number;
      total_landfill: number;
    },
    undefined
  >({
    url: MATCH_SESSION_STATS,
  });

  useEffect(() => {
    loadStats();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const water = useMemo(() => {
    const total = (totalNumOfItems as number) || 0;

    if (total >= 1 && total <= 3) {
      return 2 * 2700;
    } else if (total >= 4 && total <= 7) {
      return 5 * 2700;
    } else if (total >= 8 && total <= 10) {
      return 9 * 2700;
    } else if (total >= 11 && total <= 15) {
      return 13 * 2700;
    } else if (total >= 16 && total <= 19) {
      return 17 * 2700;
    } else if (total >= 20) {
      return 20 * 2700;
    }

    return 0;
  }, [totalNumOfItems]);

  const greenhouses = useMemo(() => totalLandfill * 3.169, [totalLandfill]);

  const loadStats = async () => {
    if (loading) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getStats();
    setTotalNumOfItems(data.total_num_of_items);
    setTotalLandfill(data.total_landfill);

    setGlobalLoading(false);
  };

  return (
    <div>
      <div className="py-4 fw-bold">
        High End Hammy Downs' environmental initiatives and positive impact
      </div>
      <div className="d-block d-md-flex">
        <IntroHeart className="d-flex">
          <div className="position-relative">
            <img src={ImgHeart} width={120} alt="" />
            <IntroHeartBadge className="position-absolute bg-white shadow rounded-circle text-center">
              50
            </IntroHeartBadge>
          </div>
          <div className="mt-2 ms-3">
            <IntroHeartTotalText>/100 tones</IntroHeartTotalText>
            <br />
            saved from landfill
          </div>
        </IntroHeart>
        <div className="flex-fill">
          <Row>
            <Col xs={12} md={4} className="mb-4 mb-md-0">
              <IntroTotalItem className="position-relative bg-white p-3 shadow rounded-3 text-center">
                <IntroTotalItemTitle className="mb-2">
                  Total Number of
                  <br />
                  Swapped Items
                </IntroTotalItemTitle>
                <div className="mb-2 h6 fw-bold">{totalNumOfItems}</div>
                <IntroTotalItemIcon className="position-absolute start-50 bottom-0 rounded-circle">
                  <img src={ImgIcShirt} width={20} alt="" />
                </IntroTotalItemIcon>
              </IntroTotalItem>
            </Col>
            <Col xs={12} md={4} className="mb-4 mb-md-0">
              <IntroTotalItem className="position-relative bg-white p-3 shadow rounded-3 text-center">
                <IntroTotalItemTitle className="mb-2">
                  Total
                  <br />
                  Water Saved
                </IntroTotalItemTitle>
                <div className="mb-2 h6 fw-bold">{numeral(water).format()}</div>
                <IntroTotalItemIcon className="position-absolute start-50 bottom-0 rounded-circle">
                  <img src={ImgIcWater} width={18} alt="" />
                </IntroTotalItemIcon>
              </IntroTotalItem>
            </Col>
            <Col xs={12} md={4} className="mb-md-0">
              <IntroTotalItem className="position-relative bg-white p-3 shadow rounded-3 text-center">
                <IntroTotalItemTitle className="mb-2">
                  Total Kilograms of
                  <br />
                  Greenhouses Avoided
                </IntroTotalItemTitle>
                <div className="mb-2 h6 fw-bold">{numeral(greenhouses).format()}</div>
                <IntroTotalItemIcon className="position-absolute start-50 bottom-0 rounded-circle">
                  <img src={ImgIcCloud} width={20} alt="" />
                </IntroTotalItemIcon>
              </IntroTotalItem>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default IntroTotal;
