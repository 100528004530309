import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ImgLogo from '@/assets/images/logo.png';
import { FORGOT_PASSWORD_RESET_PASSWORD, FORGOT_PASSWORD_VERIFY } from '@/constants/apis';
import { useApi } from '@/utils/axios';

const FormWrapper = styled.div`
  padding-left: 5em;
  padding-right: 5em;
`;

const FormRightImage = styled.div`
  width: 200px;
  background-color: #dddddd;
`;

type ComfirmPasswordForm = {
  newPassword: string;
  newPasswordConfirmation: string;
};

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const [, setGlobalLoading] = useLoading();

  const alert = useAlert();

  const { token } = useParams() as { token: string };

  const [formData, setFormData] = useState<ComfirmPasswordForm>({
    newPassword: '',
    newPasswordConfirmation: '',
  });

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordConfirmation, setIsShowPasswordConfirmation] = useState<boolean>(false);

  const [, doVerifyToken] = useApi<{ success: boolean }, { token: string }>({
    method: 'POST',
    url: FORGOT_PASSWORD_VERIFY,
  });

  const [loadingResetPassword, doResetPassword] = useApi<
    { success: boolean },
    {
      token: string;
      new_password: string;
    }
  >({
    method: 'POST',
    url: FORGOT_PASSWORD_RESET_PASSWORD,
  });

  useEffect(() => {
    (async () => {
      alert.removeAll();

      setGlobalLoading(true);

      try {
        await doVerifyToken({ data: { token } });
      } catch (error) {
        console.error(error);

        navigate('/forgot-password');

        alert.error('Your password reset request is invalid or has expired.');
      }

      setGlobalLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewPasswordSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    alert.removeAll();

    if (!_.isEqual(formData.newPassword, formData.newPasswordConfirmation)) {
      alert.error('New password confirmation does not match.');

      return;
    }

    setGlobalLoading(true);

    try {
      await doResetPassword({
        data: {
          token,
          new_password: formData.newPassword,
        },
      });

      navigate('/sign-in');
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="py-5">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs lg={7}>
            <div className="d-flex bg-white shadow">
              <FormWrapper className="flex-fill py-4">
                <div className="mb-5 text-center">
                  <div className="mb-3">
                    <img src={ImgLogo} width={66} alt="" />
                  </div>
                  <div className="h2 fw-bold">Reset Password</div>
                  <div>Almost done, enter the new password you need us to reset.</div>
                </div>
                <Form onSubmit={handleNewPasswordSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={isShowPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        value={formData.newPassword}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            newPassword: e.target.value,
                          })
                        }
                        disabled={loadingResetPassword}
                        required
                      />
                      <InputGroup.Text
                        role="button"
                        onClick={() => setIsShowPassword(!isShowPassword)}
                      >
                        <FontAwesomeIcon icon={isShowPassword ? 'eye' : 'eye-slash'} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-5">
                    <Form.Label>New Password Confirmation</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={isShowPasswordConfirmation ? 'text' : 'password'}
                        placeholder="New Password Confirmation"
                        value={formData.newPasswordConfirmation}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            newPasswordConfirmation: e.target.value,
                          })
                        }
                        disabled={loadingResetPassword}
                        required
                      />
                      <InputGroup.Text
                        role="button"
                        onClick={() => setIsShowPasswordConfirmation(!isShowPasswordConfirmation)}
                      >
                        <FontAwesomeIcon icon={isShowPasswordConfirmation ? 'eye' : 'eye-slash'} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <div className="text-center">
                    <div className="mb-4">
                      <Button type="submit" variant="dark" disabled={loadingResetPassword}>
                        Change Password
                      </Button>
                    </div>
                  </div>
                </Form>
              </FormWrapper>
              <FormRightImage />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPasswordPage;
