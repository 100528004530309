import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Divider = styled.div`
  width: 40px;
  height: 2px;
  background-color: #dddddd;
`;

const Testimonials = () => {
  return (
    <div className="d-flex flex-column align-items-center py-5">
      <p className="mb-2">What do people say about us?</p>
      <div className="mb-4 h3">Testimonials</div>
      <Row>
        <Col xs={12} md={4} className="mb-3">
          <div className="h-100 p-4 bg-white shadow rounded d-flex flex-column align-items-center">
            <FontAwesomeIcon icon="quote-left" className="text-warning" size="2x" />
            <div className="flex-fill py-3 text-center">
              Thanks so much! What a fantastic initiative this is.
            </div>
            <Divider className="mb-4 mx-auto" />
            <div className="text-muted">@highendhammys</div>
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <div className="h-100 p-4 bg-white shadow rounded d-flex flex-column align-items-center">
            <FontAwesomeIcon icon="quote-left" className="text-warning" size="2x" />
            <div className="flex-fill py-3 text-center">
              Thank you! I have received the parcel already thank you heaps my little one loved
              them.
            </div>
            <Divider className="mb-4 mx-auto" />
            <div className="text-muted">@highendhammys</div>
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <div className="h-100 p-4 bg-white shadow rounded d-flex flex-column align-items-center">
            <FontAwesomeIcon icon="quote-left" className="text-warning" size="2x" />
            <div className="flex-fill py-3 text-center">
              Thank you! I just received your package with the clothes you make me happy, I will
              prepare package for you and send out tomorrow.
            </div>
            <Divider className="mb-4 mx-auto" />
            <div className="text-muted">@highendhammys</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Testimonials;
