import { Col, Container, Image, Ratio, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ImgClothesBox from '@/assets/images/clothes_box.png';
import ImgBanner from '@/assets/images/yellow_clothes.png';

const Banner = styled.div`
  padding: 4em 0;
  color: #ffffff;
  background-color: #fee164;
  background-image: url(${ImgBanner});
  background-position: right 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const BannerTitle = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 4.5rem;
  line-height: normal;
`;

const BannerSubTitle = styled.div`
  font-size: 2.5rem;
  line-height: normal;
`;

const BlogPostsPage = () => {
  return (
    <>
      <Banner>
        <Container>
          <div className="d-inline-block text-dark">
            <BannerTitle className="mb-1 fw-bold">Blog</BannerTitle>
            <BannerSubTitle className="fw-normal">Some of our articles</BannerSubTitle>
          </div>
        </Container>
      </Banner>
      <Container>
        <div className="my-5 py-5">
          <div className="mb-5 h5">
            Hammys is building and inspirational community of savvy and sustainable parents. Here
            you will find the latest articles or media that we feature in and a go-to-guides
            regarding kids hand-me-downs.
          </div>
          <div className="mb-3 h5 fw-bold">See some articles from our past events:</div>
          <Row className="g-0 shadow-sm position-relative bg-white">
            <Col xs={4}>
              <Ratio aspectRatio="4x3">
                <Image src={ImgClothesBox} alt="" className="object-fit-cover" />
              </Ratio>
            </Col>
            <Col className="p-4 d-flex flex-column position-static">
              {/* <strong className="d-inline-block mb-3 text-dark">Events</strong> */}
              <div className="mb-3 fw-bold h3">Swapping Clothes For Children</div>
              <div className="mb-1 text-dark">Wednesday, 15th of March 2023</div>
              <div className="mb-3 text-dark">
                7:00AM – 10:00AM - ＠Emporium Hotels, South Brisbane
              </div>
              {/* <p className="card-text mb-auto">
                It’s that time again! Our sell-out International Women’s Day event is just around
                the corner and we would love to celebrate with you.
              </p>
              <Link to="/blog" className="stretched-link text-dark">
                Learn more »
              </Link> */}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default BlogPostsPage;
