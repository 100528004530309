import { action, makeObservable, observable } from 'mobx';

import { User } from '@/models/User';
import { RootStore } from '@/stores';

export default class ProfileStore {
  @observable
  public currentUser?: User;

  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  @action
  public setCurrentUser(user: User): void {
    this.currentUser = user;
  }

  @action
  public clearCurrentUser(): void {
    this.rootStore.authStore.clearToken();

    this.currentUser = undefined;
  }
}
