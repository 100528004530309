import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import styled from 'styled-components';

import { PROFILE_MATCH_SESSION } from '@/constants/apis';
import { useApi } from '@/utils/axios';

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const HeartWrapper = styled.div`
  width: 120px;
`;

const Heart = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const ProfileStatsPage = () => {
  const [, setGlobalLoading] = useLoading();

  const [totalNumOfItems, setTotalNumOfItems] = useState<number>(0);
  const [totalLandfill, setTotalLandfill] = useState<number>(0);

  const [loading, getStats] = useApi<
    {
      total_num_of_items: number;
      total_landfill: number;
    },
    undefined
  >({
    url: `${PROFILE_MATCH_SESSION}/_stats`,
  });

  useEffect(() => {
    loadStats();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { water, landfill } = useMemo(() => {
    const total = (totalNumOfItems as number) || 0;

    if (total >= 1 && total <= 3) {
      return {
        water: 2 * 2700,
        landfill: 0.5,
      };
    } else if (total >= 4 && total <= 7) {
      return {
        water: 5 * 2700,
        landfill: 1,
      };
    } else if (total >= 8 && total <= 10) {
      return {
        water: 9 * 2700,
        landfill: 2,
      };
    } else if (total >= 11 && total <= 15) {
      return {
        water: 13 * 2700,
        landfill: 5,
      };
    } else if (total >= 16 && total <= 19) {
      return {
        water: 17 * 2700,
        landfill: 6,
      };
    } else if (total >= 20) {
      return {
        water: 20 * 2700,
        landfill: 8,
      };
    }

    return {
      water: 0,
      landfill: 0,
    };
  }, [totalNumOfItems]);

  const greenhouses = useMemo(() => landfill * 3.169, [landfill]);
  const heartBottom = useMemo(() => (totalLandfill < 100 ? totalLandfill : 100), [totalLandfill]);

  const loadStats = async () => {
    if (loading) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getStats();

    setTotalNumOfItems(data.total_num_of_items);
    setTotalLandfill(data.total_landfill);

    setGlobalLoading(false);
  };

  return (
    <div className="p-5">
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Sustainability Stats</PageTitle>
      </div>
      <div className="mb-5">
        Use our Sustainability Tracker to calculate the carbon emissions you saved and see how much
        you contributed to the reduction of landfill waste by swapping your kids' clothes.
      </div>
      <Row className="mb-5">
        <Col>
          <Row className="align-items-center">
            <Col>Total number of items swapped</Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Control value={totalNumOfItems} readOnly />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Row className="mb-3 align-items-center">
            <Col>Water saved (Litre)</Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Control value={numeral(water).format()} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col>Kilograms of greenhouses avoided</Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Control value={greenhouses} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col>Kilograms from landfill</Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Control value={landfill} readOnly />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="mb-3 align-items-center">
            <Col>Total number of kilograms saved from landfill</Col>
            <Col lg={5}>
              <div className="d-flex justify-content-center">
                <span className="px-3 py-1 h4 border-bottom">{totalLandfill}</span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <small>
                Once you save <div className="h6 d-inline">100 kilograms</div> from landfill, you
                will receive an awesome reward so keep swapping!
              </small>
            </Col>
            <Col lg={5}>
              <div className="d-flex justify-content-center">
                <HeartWrapper className="position-relative">
                  <svg viewBox="0 0 32 29.6">
                    <path
                      fill="#F8C400"
                      d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2,c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                    />
                  </svg>
                  <Heart className="position-absolute" style={{ bottom: `${heartBottom}%` }}>
                    <svg viewBox="0 0 32 29.6">
                      <path
                        fill="#2D3335"
                        d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2,c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                      />
                    </svg>
                  </Heart>
                </HeartWrapper>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileStatsPage;
