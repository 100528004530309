import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import styled from 'styled-components';

import ImgLogo from '@/assets/images/logo.png';
import { FORGOT_PASSWORD } from '@/constants/apis';
import { useApi } from '@/utils/axios';

const FormWrapper = styled.div`
  padding-left: 5em;
  padding-right: 5em;
`;

const FormRightImage = styled.div`
  width: 200px;
  background-color: #dddddd;
`;

type ForgotPasswordFormData = {
  email: string;
};

const ForgotPasswordPage = () => {
  const [, setGlobalLoading] = useLoading();

  const alert = useAlert();

  const [formData, setFormData] = useState<ForgotPasswordFormData>({
    email: '',
  });

  const [loading, doForgotPassword] = useApi<{ success: boolean }, ForgotPasswordFormData>({
    method: 'POST',
    url: FORGOT_PASSWORD,
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    alert.removeAll();

    setGlobalLoading(true);

    try {
      await doForgotPassword({ data: formData });

      setFormData({ email: '' });

      alert.show('There is an email sent to you. Please click on the link to reset your password.');
    } catch (err) {
      console.error(err);

      alert.error('Sorry, the request cannot be submitted at this time.');
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="py-5">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs lg={7}>
            <div className="d-flex bg-white shadow">
              <FormWrapper className="flex-fill py-4">
                <div className="mb-5 text-center">
                  <div className="mb-3">
                    <img src={ImgLogo} width={66} alt="" />
                  </div>
                  <div className="h2 fw-bold">Forgot Password</div>
                  <div>
                    Don't worry, we will help you to reset your password. But first you need to
                    enter your email.
                  </div>
                </div>
                <Form onSubmit={handleFormSubmit}>
                  <Form.Group className="mb-5">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                      disabled={loading}
                      required
                    />
                  </Form.Group>
                  <div className="text-center">
                    <div className="mb-4">
                      <Button type="submit" variant="dark" disabled={loading}>
                        Reset Password
                      </Button>
                    </div>
                  </div>
                </Form>
              </FormWrapper>
              <FormRightImage />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPasswordPage;
