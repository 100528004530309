import moment from 'moment';

import { Set, SetGive, SetType } from '@/models/Set';

export enum MatchLevel {
  perfect = 'perfect',
  happyHammy = 'happy_hammy',
  mini = 'mini',
  longDistance = 'long_distance',
}

export enum MatchSessionStatus {
  waiting = 'waiting',
  waitingApproval = 'waiting_approval',
  swapping = 'swapping',
  done = 'done',
  canceled = 'canceled',
  decline = 'decline',
}

export type MatchSession = {
  id: number;
  level: MatchLevel;
  status: MatchSessionStatus;
  type: SetType;
  unitAmount?: number | null;
  currency?: string;
  setReceive: Set;
  setGive: SetGive;
  totalMessages?: number;
  totalAllMessages?: number;
  isMatchSwappingMethod?: boolean;
  isPayRequired?: boolean;
  createdAt?: moment.Moment;
};
