import AuthStore from '@/stores/AuthStore';
import ProfileStore from '@/stores/ProfileStore';
import WsStore from '@/stores/WsStore';

export class RootStore {
  public authStore: AuthStore;
  public profileStore: ProfileStore;
  public wsStore: WsStore;

  constructor() {
    this.authStore = new AuthStore();
    this.profileStore = new ProfileStore(this);
    this.wsStore = new WsStore(this);
  }
}

const rootStore = new RootStore();

export default rootStore;
