import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import ImgKids from '@/assets/images/kids.png';
import ImgBanner from '@/assets/images/yellow_clothes.png';

const Banner = styled.div`
  padding: 4em 0;
  color: #ffffff;
  background-color: #fee164;
  background-image: url(${ImgBanner});
  background-position: right 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const BannerTitle = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 4.5rem;
  line-height: normal;
`;

const BannerSubTitle = styled.div`
  font-size: 2.5rem;
  line-height: normal;
`;

const ImgFAQKids = styled.img`
  width: 600px;
  max-width: 50%;
`;

const FAQPage = () => {
  const faqs: {
    question: string;
    answer: string;
  }[] = [
    {
      question: 'How is a match made?',
      answer:
        'Using the criteria you entered for your kids clothes, we match this data using our ‘Exchange Algorithm’. Depending on the criteria matched, will depend on the type of match you will receive. You will receive one match for the clothes you are giving and another match for the clothes you wish to receive. You are more then likely receive clothes before you give yours.',
    },
    {
      question: 'What is the difference between Basic and High End swap type?',
      answer:
        'Basic is non-branded run-of-the-mill clothing, think Kmart, Big W, H&M, Cotton On Wallmart clothes that you would spend less than $10-15 on. A little bit worn, but not worn out. High End – is exactly that, what you generally consider branded clothing, think Country Road, Myer, DJ’s, Billabong, Calvin Klein etc. If you are unsure please email <a href="mailto:hello@highendhammydowns.com">hello@highendhammydowns.com</a>',
    },
    {
      question: 'Why don’t I pay for clothes?',
      answer:
        "Your kids clothes are the currency! For Basic swaps, the swapping service is free, we want consumers to reap the benefits of trading second hand clothing. High End swaps attract a fee, as this guarantees you, that you will receive the same type of quality clothing that you give. Additionally long distance swaps attract charges to cover postage costs. The average 5kg parcel costs $20 to ship around Australia. Once a match has been accepted by both parties, We'll help organise posting for you!",
    },
    {
      question: 'What if I have a mixed bundle of Basic and Branded clothing?',
      answer:
        'Split them into 2 different swap bundles or you can stay tuned for our Concierge service to be launching soon, where you (are too busy or can’t be bothered to sort through hand-me-downs) can outsource the sorting, matching and swapping with the click of a few buttons.',
    },
    {
      question: 'What if I have several sizes to swap?',
      answer:
        'If you have up to 2 sizes this is acceptable for one bundle in order to achieve a fair swap, if you have more then 2 sizes we suggest creating another bundle for that extra size/sizes. This is to ensure that both you and your match receive a similar amount of clothes in the correct size you are after or wish to give. If you have too many clothes and you just can’t deal with it, reach out to <a href="mailto:hello@highendhammydowns.com">hello@highendhammydowns.com</a> to enquire about our concierge service.',
    },
    {
      question: 'When will I receive the clothes?',
      answer:
        'The Giver has two weeks to forward their bundle of clothes. After two weeks we will follow them up on your behalf.',
    },
    {
      question: 'How do I know I will get the same quality and quantity of clothes in return?',
      answer:
        'The data from completing the questionnaire is matched and you will only receive the quality and quantity that you give. For example if you have Country Road and Review kids clothes, you will select a ‘High End’ match, meaning you will only be matched with clothing that is of the same quality make.',
    },
    {
      question: 'Can I get a refund for my high end swap?',
      answer:
        'Yes this can be discussed, please contact <a href="mailto:hello@highendhammydowns.com">hello@highendhammydowns.com</a>',
    },
    {
      question: 'What condition do clothes need to be in?',
      answer:
        'Firstly please WASH all clothes before giving away. Clothes should be mostly in good condition, but at Hammy’s we understand Kids are active, so clothes can be a tiny bit worn, but not worn out! Members at Hammys understand that some items may not be in perfect condition, but if its good quality, and their kids will happily wear it, then all good. Remember when giving your clothes away, think about if you would accept the condition they are in. Be mindful. If it has holes, badly stained or full of odour, throw it out! Because if you send it to Salvos, that is what they will do with it….rubbish. Alternatively you can source a textile recycler near you. We are working on connecting you with recycling partners, so please stay tuned!',
    },
    {
      question: 'What if I don’t like the clothes?',
      answer:
        'If the style or type of clothes were not what you expected from the photographs provided then that would be a little unusual. However, if the clothes do not fit your child, we are happy for you to return. Please contact <a href="mailto:hello@highendhammydowns.com">hello@highendhammydowns.com</a>. If you have completed a Basic swap, then we will re-match you and your match will be reported. If you have completed a High End match, we will happily refund you and also re-match.',
    },
    {
      question: 'Can my teenagers or tweens join?',
      answer:
        'We are working on a dual log in for kids to manage their own wardrobes – stay tuned!',
    },
  ];

  return (
    <>
      <Banner>
        <Container>
          <div className="d-inline-block text-dark">
            <BannerTitle className="mb-1 fw-bold">FAQs</BannerTitle>
            <BannerSubTitle className="fw-normal">Frequently Asked Questions</BannerSubTitle>
          </div>
        </Container>
      </Banner>
      <Container>
        <div className="my-5 py-5">
          <ImgFAQKids src={ImgKids} alt="" className="float-end ms-3 mb-3 rounded-3" />
          {faqs.map((item, i) => (
            <div className={classNames(i < faqs.length - 1 && 'mb-5')} key={i}>
              <div className="mb-3 h4 fw-bold">{item.question}</div>
              <div dangerouslySetInnerHTML={{ __html: item.answer }} />
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default FAQPage;
