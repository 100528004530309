import _ from 'lodash';
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Form } from 'react-bootstrap';

import { GiveSetInfo, ReceiveSetInfo } from '@/components/FirstTimeSetItem';
import { SET } from '@/constants/apis';
import { SetForm, SetGiveForm, SetType } from '@/models/Set';
import { useApi } from '@/utils/axios';

const CreateSetForm = ({
  type,
  onDone,
  onCancel,
}: {
  type: SetType;
  onDone: () => void;
  onCancel: () => void;
}) => {
  const alert = useAlert();

  const [form, setForm] = useState<SetForm | SetGiveForm>({});

  const [loading, doCreateSet] = useApi<
    { success: boolean },
    | {
        type: SetType;
        gender: string;
        sizes: string[];
        num_of_items: string;
        types_of_clothing: string[];
        clothing_styles: string[];
        is_hehd: boolean;
      }
    | FormData
  >({
    method: 'POST',
    url: SET,
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (loading) {
      return;
    }

    const hasSizesEmpty = _(form.sizes).isEmpty();
    const hasTypesOfClothingEmpty = _(form.typesOfClothing).isEmpty();
    const hasClothingStylesEmpty = _(form.clothingStyles).isEmpty();

    if (hasSizesEmpty) {
      alert.info('You need to choose at least one Sizes.');

      return;
    }

    if (hasTypesOfClothingEmpty) {
      alert.info('You need to choose at least one Types Of Clothing.');

      return;
    }

    if (hasClothingStylesEmpty) {
      alert.info('You need to choose at least one Clothing Styles.');

      return;
    }

    if (type === SetType.receive) {
      await doCreateSet({
        data: {
          type: SetType.receive,
          gender: form.gender!,
          sizes: form.sizes!,
          num_of_items: form.numOfItems!,
          types_of_clothing: form.typesOfClothing!,
          clothing_styles: form.clothingStyles!,
          is_hehd: form.isHEHD!,
        },
      });
    } else {
      const formx = form as SetGiveForm;

      const hasGiveGalleriesEmpty = _(formx.giveGalleries).isEmpty();

      if (hasGiveGalleriesEmpty) {
        alert.info('You need to upload at least 1 photo for each set!');

        return;
      }

      const formData = new FormData();

      formData.append('type', 'give');
      formData.append('gender', formx.gender!);

      formx.sizes!.forEach((s) => {
        formData.append('sizes[]', s);
      });

      formData.append('num_of_items', formx.numOfItems!);

      formx.typesOfClothing!.forEach((s) => {
        formData.append('types_of_clothing[]', s);
      });

      formx.clothingStyles!.forEach((s) => {
        formData.append('clothing_styles[]', s);
      });

      formx.giveGalleries!.forEach((s) => {
        formData.append('give_galleries[]', s);
      });

      formData.append('is_hehd', _.toString(formx.isHEHD));

      await doCreateSet({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }

    alert.success('Successfully created, a match will be found for you shortly.');

    onDone();
  };

  const handleCancelClicked = () => {
    setForm({});

    onCancel();
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <div className="h4 fw-bold">Create Bundle</div>
      <div className="my-5">
        {type === SetType.receive ? <ReceiveSetInfo data={form} /> : <GiveSetInfo data={form} />}
      </div>
      <div className="text-end">
        <Button variant="outline-dark" className="me-3" onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button type="submit" variant="dark">
          Create
        </Button>
      </div>
    </Form>
  );
};

export default CreateSetForm;
