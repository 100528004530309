import _ from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import ImgBanner from '@/assets/images/banner.png';
// import IntroStatsTotal from '@/pages/Home/IntroStatsTotal';
import IntroSteps from '@/pages/Home/IntroSteps';
import IntroTotal from '@/pages/Home/IntroTotal';
import SignUpForm from '@/pages/Home/SignUpForm';
import Testimonials from '@/pages/Home/Testimonials';
import { useStore } from '@/store';

const Banner = styled.div`
  padding: 4em 0;
  color: #ffffff;
  background-color: #fda8a6;
  background-image: url(${ImgBanner});
  background-position: right 0;
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 767.98px) {
    padding: 2em 0;
    background-size: cover;
    background-position: 0 bottom;
  }
`;

const BannerTitle = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 4.5rem;
  line-height: normal;

  @media (max-width: 767.98px) {
    font-size: 3rem;
  }
`;

const BannerSubTitle = styled.div`
  font-size: 2.5rem;
  line-height: normal;

  @media (max-width: 767.98px) {
    font-size: 1.5rem;
  }
`;

const SignUp = styled.div`
  margin-top: -290px;
`;

const HomePage = () => {
  const { authStore } = useStore();

  return (
    <div>
      <Banner className="mb-4">
        <Container>
          <div className="d-inline-block">
            <BannerTitle className="mb-1 fw-bold">Swap</BannerTitle>
            <BannerSubTitle className="fw-normal">
              Your Kids Second
              <br />
              Hand Clothes
            </BannerSubTitle>
          </div>
        </Container>
      </Banner>
      <Container>
        <Row>
          <Col>
            <div className="py-2 py-md-5">
              <div className="mb-4">
                <Row className="flex-row-reverse">
                  <Col xs={12} md={6}>
                    <div className="mb-4 fw-bold d-block d-md-none">
                      The new ‘digital clothes swap’ for kids! High End Hammy Downs is the first of
                      its kind, a personalised online exchange platform for kids' hand-me-downs.
                    </div>
                    <IntroSteps />
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="mb-4 fw-bold d-none d-md-block">
                      The new ‘digital clothes swap’ for kids! High End Hammy Downs is the first of
                      its kind, a personalised online exchange platform for kids' hand-me-downs.
                    </div>
                    <div className="mb-3">
                      Give your child's outgrown clothes to another parent, and in exchange, receive
                      second-hand kids' clothes for your own child now and for the future. It can be
                      either a one-off match or a life-time's supply of hand-me-downs!
                    </div>
                    <div className="mb-5 mb-md-0">
                      <u>Example</u>: Your child is size 6 - you give size 6 clothes (giving match)
                      and want to receive size 7 clothes (receiving match). Children grow every 6-12
                      months for 18 years! This is the perfect way to update their wardrobe
                      sustainably and economically.
                    </div>
                    <div className="d-block d-md-none">
                      <SignUpForm />
                    </div>
                  </Col>
                </Row>
              </div>
              <IntroTotal />
            </div>
          </Col>
          {_.isNil(authStore.token) && (
            <Col lg={4} className="d-none d-md-block">
              <SignUp>
                <SignUpForm />
              </SignUp>
            </Col>
          )}
        </Row>
        <Testimonials />
      </Container>
    </div>
  );
};

export default HomePage;
