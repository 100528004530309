import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Bluebird from 'bluebird';
import _ from 'lodash';
import { autorun } from 'mobx';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Alert, Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Image from '@/components/Image';
import { MatchSessionCheckList } from '@/components/MatchSessionItem';
import { MATCH_SESSION } from '@/constants/apis';
import { Country } from '@/enums/CountryState';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { MatchLevel, MatchSession, MatchSessionStatus } from '@/models/MatchSession';
import { SetGender, SetGiveGallery, SetType } from '@/models/Set';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';
import {
  getAusCurrency,
  getMatchLevelDescription,
  getMatchLevelName,
  getMatchSessionStatus,
} from '@/utils/hehd';

const GallerySmallItem = styled.div`
  border: 2px solid transparent;

  &.active {
    border-color: #f8c400;
  }
`;

const Divider = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: #000000;
  }
`;

const BadgeType = styled.div`
  background-color: #f7d9d3;
`;

const BadgeStatus = styled.div`
  border-bottom: 2px solid #f8c400;
`;

const MatchSessionDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [, setGlobalLoading] = useLoading();

  const alert = useAlert();

  const { wsStore } = useStore();

  const [matchSession, setMatchSession] = useState<MatchSession | undefined>();
  const [gallerySelected, setGallerySelected] = useState<SetGiveGallery | undefined>();

  const [loadingGetMatchSession, getMatchSession] = useApi<
    {
      id: number;
      level: MatchLevel;
      status: MatchSessionStatus;
      type: SetType;
      unit_amount: number | null;
      total_messages: number;
      is_match_swapping_method: boolean;
      is_pay_required: boolean;
      set_receive: {
        id: number;
        type: SetType;
        gender: SetGender;
        num_of_items: NumOfItems;
        sizes: Sizes[];
        types_of_clothing: TypesOfClothing[];
        clothing_styles: ClothingStyles[];
        is_hehd: boolean;
        user: {
          id: number;
          full_name: string;
          email: string;
          postcode: string;
          country: string;
          swapping_methods: {
            id: number;
            name: string;
            is_required_address: boolean;
          }[];
        };
      };
      set_give: {
        id: number;
        type: SetType;
        gender: SetGender;
        num_of_items: NumOfItems;
        sizes: Sizes[];
        types_of_clothing: TypesOfClothing[];
        clothing_styles: ClothingStyles[];
        is_hehd: boolean;
        user: {
          id: number;
          full_name: string;
          email: string;
          postcode: string;
          country: string;
          swapping_methods: {
            id: number;
            name: string;
            is_required_address: boolean;
          }[];
        };
        give_galleries: { id: number; url: string }[];
      };
    },
    unknown
  >({
    url: `${MATCH_SESSION}/${id}`,
  });

  const [loadingUpdateMatchSession, updateMatchSession] = useApi<
    { success: boolean },
    { status: MatchSessionStatus }
  >({
    method: 'PUT',
    url: `${MATCH_SESSION}/${id}`,
  });

  const [loadingConnectMatchSession, connectMatchSession] = useApi<
    {
      success: boolean;
      data: { url: string };
    },
    unknown
  >({
    url: `${MATCH_SESSION}/${id}/pay/checkout`,
  });

  const [loadingAcceptMatchSession, acceptMatchSession] = useApi<{ success: boolean }, unknown>({
    url: `${MATCH_SESSION}/${id}/pay/capture`,
  });

  useEffect(() => {
    autorun(() => {
      if (wsStore.connected) {
        wsStore.join(_.toInteger(id));
      }
    });

    loadMatchSession();

    return () => {
      if (wsStore.joined) {
        wsStore.leave(_.toInteger(id));
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    autorun(() => {
      if (_.isNil(wsStore.newStatus) || _.isNil(matchSession)) {
        return;
      }

      setMatchSession({
        ..._.clone(matchSession),
        status: wsStore.newStatus.status,
      });

      wsStore.setNewStatus(undefined);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchSession]);

  const loadMatchSession = async () => {
    if (loadingGetMatchSession) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getMatchSession();

    setGlobalLoading(false);

    const {
      //
      id,
      level,
      status,
      type,
      unit_amount: unitAmount,
      set_receive: setReceive,
      set_give: setGive,
      total_messages: totalMessages,
      is_match_swapping_method: isMatchSwappingMethod,
      is_pay_required: isPayRequired,
    } = data;

    const result: MatchSession = {
      id,
      level,
      status,
      type,
      unitAmount,
      totalMessages,
      isMatchSwappingMethod,
      isPayRequired,
      setReceive: {
        id: setReceive.id,
        type: setReceive.type,
        gender: setReceive.gender,
        numOfItems: setReceive.num_of_items,
        sizes: setReceive.sizes,
        typesOfClothing: setReceive.types_of_clothing,
        clothingStyles: setReceive.clothing_styles,
        isHEHD: setReceive.is_hehd,
        user: {
          id: setReceive.user.id,
          fullName: setReceive.user.full_name,
          email: setReceive.user.email,
          postcode: setReceive.user.postcode,
          country: setReceive.user.country as Country,
          swappingMethods: _.map(setReceive.user.swapping_methods, (s) => ({
            id: s.id,
            name: s.name,
            isRequiredAddress: s.is_required_address,
          })),
        },
      },
      setGive: {
        id: setGive.id,
        type: setGive.type,
        gender: setGive.gender,
        numOfItems: setGive.num_of_items,
        sizes: setGive.sizes,
        typesOfClothing: setGive.types_of_clothing,
        clothingStyles: setGive.clothing_styles,
        isHEHD: setGive.is_hehd,
        user: {
          id: setGive.user.id,
          fullName: setGive.user.full_name,
          email: setGive.user.email,
          postcode: setReceive.user.postcode,
          country: setReceive.user.country as Country,
          swappingMethods: _.map(setGive.user.swapping_methods, (s) => ({
            id: s.id,
            name: s.name,
            isRequiredAddress: s.is_required_address,
          })),
        },
        giveGalleries: setGive.give_galleries,
      },
    };

    setMatchSession(result);
    setGallerySelected(result.setGive.giveGalleries[0]);
  };

  const handleConnectClick = async () => {
    alert.removeAll();

    const isOk = window.confirm('Are you sure you want to connect?');

    if (!isOk) {
      return;
    }

    if (matchSession!.isPayRequired) {
      alert.success('Redirecting to payment page ...');

      setGlobalLoading(true);

      const { data } = await connectMatchSession();

      setGlobalLoading(false);

      if (data.success === true) {
        await Bluebird.delay(1000);

        window.location.href = data.data.url;
      }
    } else {
      setGlobalLoading(true);

      const { data } = await updateMatchSession({
        data: { status: MatchSessionStatus.waitingApproval },
      });

      setGlobalLoading(false);

      if (data.success === true) {
        loadMatchSession();
      }

      alert.success('Successfully connected!');
    }
  };

  const handleAcceptClick = async () => {
    alert.removeAll();

    const isOk = window.confirm('Are you sure you want to accept this connection?');

    if (!isOk) {
      return;
    }

    if (matchSession!.isPayRequired) {
      setGlobalLoading(true);

      const { data } = await acceptMatchSession();

      setGlobalLoading(false);

      if (data.success === true) {
        // loadMatchSession();
      }
    } else {
      setGlobalLoading(true);

      const { data } = await updateMatchSession({
        data: { status: MatchSessionStatus.swapping },
      });

      setGlobalLoading(false);

      if (data.success === true) {
        // loadMatchSession();
      }
    }

    alert.success('Successfully accepted!');
  };

  const handleDeclineClick = async () => {
    const isOk = window.confirm('Are you sure you want to decline this match?');

    if (!isOk) {
      return;
    }

    alert.removeAll();

    setGlobalLoading(true);

    const { data } = await updateMatchSession({
      data: { status: MatchSessionStatus.decline },
    });

    setGlobalLoading(false);

    if (data.success === true) {
      // loadMatchSession();
    }

    alert.success('Successfully declined!');
  };

  const handleCancelClick = async () => {
    const isOk = window.confirm('Are you sure you want to cancel this match?');

    if (!isOk) {
      return;
    }

    alert.removeAll();

    setGlobalLoading(true);

    const { data } = await updateMatchSession({
      data: { status: MatchSessionStatus.canceled },
    });

    setGlobalLoading(false);

    if (data.success === true) {
      // loadMatchSession();
    }

    alert.success('Successfully canceled!');
  };

  return (
    <div className="bg-white">
      <Container>
        <div
          role="button"
          className="d-flex align-items-center"
          onClick={() => navigate('/matches')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="ms-2 fw-bold">Back</span>
        </div>
        <div className="py-5">
          <div className="h2 fw-bold">Match Details</div>
        </div>
        {!_.isNil(matchSession) ? (
          <div className="py-5">
            <div className="position-relative">
              <Row className="align-items-center">
                <Col lg={8}>
                  <Row className="align-items-center">
                    <Col lg={7}>
                      <Row>
                        <Col lg={3}>
                          <div className="d-flex flex-column">
                            {matchSession.setGive.giveGalleries.map((gallery, i) => (
                              <GallerySmallItem
                                className={`mb-3 ratio ratio-1x1 bg-light ${
                                  gallerySelected === gallery ? 'active' : ''
                                }`}
                                onClick={() => setGallerySelected(gallery)}
                                key={i}
                              >
                                <Image src={gallery.url} className="w-100 p-2" />
                              </GallerySmallItem>
                            ))}
                          </div>
                        </Col>
                        <Col>
                          <div className="ratio ratio-1x1 bg-light">
                            {gallerySelected && (
                              <Image src={gallerySelected.url} className="w-100" />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <div className="ps-4">
                        <div className="mb-3 fw-bold">
                          {matchSession.type === SetType.give
                            ? matchSession.setReceive.user!.fullName
                            : matchSession.setGive.user!.fullName}
                        </div>
                        <MatchSessionCheckList type={matchSession.type} data={matchSession} />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Divider />
                  <div className="ps-5 d-flex align-items-start flex-column">
                    <div className="mb-4 d-flex align-items-end justify-content-between w-100">
                      <BadgeType className="px-5 py-2 rounded-pill">
                        {matchSession.type === SetType.give ? 'Giving' : 'Receiving'}
                      </BadgeType>
                      <BadgeStatus className="py-1">
                        {getMatchSessionStatus(matchSession.status)}
                      </BadgeStatus>
                    </div>
                    <div className="h5 mb-4 fw-bold">
                      This is a {getMatchLevelName(matchSession.level)}
                    </div>
                    <div className="mb-5">{getMatchLevelDescription(matchSession.level)}</div>
                    {matchSession.type === SetType.receive &&
                      matchSession.status === MatchSessionStatus.waiting && (
                        <div className="d-flex flex-column w-100">
                          <Button
                            variant="dark"
                            className="mb-2 flex-fill"
                            disabled={loadingConnectMatchSession}
                            onClick={handleConnectClick}
                          >
                            {matchSession.isPayRequired
                              ? `Accept Match & Connect (${getAusCurrency(
                                  matchSession.unitAmount! / 100
                                )})`
                              : 'Connect'}
                          </Button>
                          <Button
                            variant="outline-dark"
                            className="flex-fill"
                            disabled={loadingUpdateMatchSession}
                            onClick={handleCancelClick}
                          >
                            Decline
                          </Button>
                        </div>
                      )}
                    {matchSession.type === SetType.give &&
                      matchSession.status === MatchSessionStatus.waitingApproval && (
                        <div className="d-flex w-100">
                          <Button
                            variant="dark"
                            className="me-3 flex-fill"
                            disabled={loadingAcceptMatchSession}
                            onClick={handleAcceptClick}
                          >
                            Accept
                          </Button>
                          <Button
                            variant="outline-dark"
                            className="flex-fill"
                            disabled={loadingUpdateMatchSession}
                            onClick={handleDeclineClick}
                          >
                            Decline
                          </Button>
                        </div>
                      )}
                    {matchSession.type === SetType.receive &&
                      matchSession.status === MatchSessionStatus.waitingApproval && (
                        <Alert variant="warning" className="d-block w-100">
                          Waiting for the giver to accept.
                        </Alert>
                      )}
                    {matchSession.type === SetType.receive &&
                      matchSession.status === MatchSessionStatus.swapping && (
                        <Alert variant="warning" className="d-block w-100">
                          {/* To complete this match, please click the done button in the email we sent */}
                          Once arrangements for swapping or postage have been organised, please
                          click the Done button in the chat function. This will complete the match
                          you.
                        </Alert>
                      )}
                    {(matchSession.status === MatchSessionStatus.swapping ||
                      matchSession.status === MatchSessionStatus.done) && (
                      <Button
                        variant="dark"
                        className="w-100"
                        disabled={loadingUpdateMatchSession}
                        onClick={() => navigate(`/match-sessions/${id}/messages`)}
                      >
                        <span>Send Message</span>
                        {!_.isNil(matchSession.totalMessages) && matchSession.totalMessages > 0 && (
                          <Badge bg="warning" className="ms-2">
                            {matchSession.totalMessages}
                          </Badge>
                        )}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div>{/* Loader */}</div>
        )}
      </Container>
    </div>
  );
};

export default MatchSessionDetailPage;
