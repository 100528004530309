import styled from 'styled-components';

import ImgIcCheckCircle from '@/assets/images/ic_check_circle.png';
import ImgIcHeartCircle from '@/assets/images/ic_heart_circle.png';
import ImgIcSwapCircle from '@/assets/images/ic_swap_circle.png';

const ItemWrapper = styled.div`
  ::before {
    content: '';
    position: absolute;
    left: calc(46px / 2);
    top: 0;
    bottom: 0;
    width: 2px;
    border-left: 2px dashed black;
  }

  :first-child {
    ::before {
      top: 46px;
    }
  }

  :last-child {
    ::before {
      bottom: 100%;
    }
  }

  @media (max-width: 767.98px) {
    ::before {
      display: none;
    }
  }
`;

const IntroSteps = () => {
  return (
    <div className="d-flex d-md-block justify-content-between">
      <ItemWrapper className="position-relative d-flex flex-column flex-md-row align-items-center align-items-md-start pb-4">
        <div className="me-md-4">
          <img src={ImgIcCheckCircle} width={46} alt="" className="position-relative" />
        </div>
        <div>
          <div className="mb-1 fw-bold">Sign Up For Free</div>
          <div className="d-none d-md-block">Sign up and complete questionnaire</div>
        </div>
      </ItemWrapper>
      <ItemWrapper className="position-relative d-flex flex-column flex-md-row align-items-center align-items-md-start pb-4">
        <div className="me-md-4">
          <img src={ImgIcHeartCircle} width={46} alt="" className="position-relative" />
        </div>
        <div>
          <div className="mb-1 fw-bold">Match</div>
          <div className="d-none d-md-block">
            Let our Exchange Algorithm match your kids' clothing needs. For every bundle you give,
            you will receive another bundle in return, which means you will have:
            <ul>
              <li>One match for clothes size your are giving</li>
              <li>Another match for clothes size you wish to receive</li>
            </ul>
          </div>
        </div>
      </ItemWrapper>
      <ItemWrapper className="position-relative d-flex flex-column flex-md-row align-items-center align-items-md-start">
        <div className="me-md-4">
          <img src={ImgIcSwapCircle} width={46} alt="" className="position-relative" />
        </div>
        <div>
          <div className="mb-1 fw-bold">Swap</div>
          <div className="d-none d-md-block">
            Once your matches appear, select your match, with the preferred drop-off/pick up
            location, connect or chat and start swapping your kid's clothes. Easy!
          </div>
        </div>
      </ItemWrapper>
    </div>
  );
};

export default IntroSteps;
