import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

const TimeAgo = ({ value }: { value: moment.Moment }) => {
  const timerRef = useRef<NodeJS.Timer>();

  const [display, setDisplay] = useState<String>(value.fromNow());

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(timerRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setDisplay(value.fromNow());
    }, 1000);
  };

  return <>{display}</>;
};

export default TimeAgo;
