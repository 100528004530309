import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ImgBgSignUpInfo from '@/assets/images/bg_signup_info.png';

const LeftImage = styled.div`
  height: 100%;
  max-height: 600px;
  background-color: #dddddd;
  background-image: url(${ImgBgSignUpInfo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const FormWrapper = styled.div`
  min-height: 300px;
`;

const CompletedInfo = () => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col lg={5}>
        <LeftImage />
      </Col>
      <Col>
        <FormWrapper className="d-flex flex-column justify-content-center align-items-center text-center">
          <div className="h5 fw-bold mb-5">Account Setup Complete</div>
          <div className="mb-5">
            Thanks for filling in your details.
            <br />
            It’s time to see your matches!
          </div>
          <Button variant="dark" onClick={() => navigate('/')}>
            See Matches
          </Button>
        </FormWrapper>
      </Col>
    </Row>
  );
};

export default CompletedInfo;
