import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { Link } from 'react-router-dom';

import Paginate from '@/components/Paginate';
import { ADMIN_MATCH_SESSION } from '@/constants/apis';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { MatchLevel, MatchSession, MatchSessionStatus } from '@/models/MatchSession';
import { SetGender, SetType } from '@/models/Set';
import { useApi } from '@/utils/axios';
import { getMatchSessionStatus } from '@/utils/hehd';

const MatchSessionTable = () => {
  const [, setGlobalLoading] = useLoading();

  const [matchSessions, setMatchSessions] = useState<MatchSession[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [loading, getMatchSessions] = useApi<
    {
      meta: { last_page: number };
      data: {
        id: number;
        level: MatchLevel;
        status: MatchSessionStatus;
        type: SetType;
        set_receive: {
          id: number;
          type: SetType;
          gender: SetGender;
          num_of_items: NumOfItems;
          sizes: Sizes[];
          types_of_clothing: TypesOfClothing[];
          clothing_styles: ClothingStyles[];
          is_hehd: boolean;
          user: {
            id: number;
            full_name: string;
            email: string;
          };
        };
        set_give: {
          id: number;
          type: SetType;
          gender: SetGender;
          num_of_items: NumOfItems;
          sizes: Sizes[];
          types_of_clothing: TypesOfClothing[];
          clothing_styles: ClothingStyles[];
          is_hehd: boolean;
          user: {
            id: number;
            full_name: string;
            email: string;
          };
          give_galleries: { id: number; url: string }[];
        };
        total_messages: number;
        total_all_messages: number;
        created_at: string;
      }[];
    },
    unknown
  >({
    url: ADMIN_MATCH_SESSION,
    params: { page },
  });

  useEffect(() => {
    loadMatchSessions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadMatchSessions = async () => {
    if (loading) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getMatchSessions();

    setGlobalLoading(false);

    setTotalPage(data.meta.last_page);
    setMatchSessions(
      _(data.data)
        .map<MatchSession>(
          ({
            //
            id,
            level,
            status,
            type,
            set_receive: setReceive,
            set_give: setGive,
            total_messages: totalMessages,
            total_all_messages: totalAllMessages,
            created_at: createdAt,
          }) => ({
            id,
            level,
            status,
            type,
            setReceive: {
              id: setReceive.id,
              type: setReceive.type,
              gender: setReceive.gender,
              numOfItems: setReceive.num_of_items,
              sizes: setReceive.sizes,
              typesOfClothing: setReceive.types_of_clothing,
              clothingStyles: setReceive.clothing_styles,
              isHEHD: setReceive.is_hehd,
              user: {
                id: setReceive.user.id,
                fullName: setReceive.user.full_name,
                email: setReceive.user.email,
              },
            },
            setGive: {
              id: setGive.id,
              type: setGive.type,
              gender: setGive.gender,
              numOfItems: setGive.num_of_items,
              sizes: setGive.sizes,
              typesOfClothing: setGive.types_of_clothing,
              clothingStyles: setGive.clothing_styles,
              isHEHD: setGive.is_hehd,
              user: {
                id: setGive.user.id,
                fullName: setGive.user.full_name,
                email: setGive.user.email,
              },
              giveGalleries: [],
            },
            totalMessages,
            totalAllMessages,
            createdAt: moment(createdAt),
          })
        )
        .value()
    );
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <td className="border-0 px-4">Date</td>
            {/* <td className="border-0 px-4">Type</td> */}
            <td className="border-0 px-4">Status</td>
            <td className="border-0 px-4">Receiver</td>
            <td className="border-0 px-4">Giver</td>
          </tr>
        </thead>
        <tbody>
          {_(matchSessions)
            .orderBy((ms) => ms.createdAt, 'desc')
            .map((matchSession, i) => (
              <tr key={i} className="mb-1">
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {matchSession.createdAt!.format('lll')}
                </td>
                {/* <td className="bg-light border-0 align-middle px-4 py-3">
                  {matchSession.type === SetType.receive ? 'Receive' : 'Give'}
                </td> */}
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {getMatchSessionStatus(matchSession.status)}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {matchSession.setReceive && matchSession.setReceive.user
                    ? matchSession.setReceive.user.fullName
                    : '---'}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  {matchSession.setGive && matchSession.setGive.user
                    ? matchSession.setGive.user.fullName
                    : '---'}
                </td>
                <td className="bg-light border-0 align-middle px-4 py-3">
                  <div className="d-flex gap-2">
                    <Link to={`/admin/matches/${matchSession.id}`}>
                      <Button variant="outline-dark" size="sm">
                        Details
                      </Button>
                    </Link>
                  </div>
                </td>
              </tr>
            ))
            .value()}
        </tbody>
      </Table>
      {matchSessions.length > 0 && totalPage > 0 && (
        <div className="mt-5 d-flex justify-content-center">
          <Paginate
            pageCount={totalPage}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      )}
    </>
  );
};

export default MatchSessionTable;
