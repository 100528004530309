import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ImgLogo from '@/assets/images/logo.png';
import { AUTH_SIGNIN } from '@/constants/apis';
import { HEHD_STORAGE_TOKEN } from '@/constants/skeys';
import { useStore } from '@/store';
import { useApi } from '@/utils/axios';

const FormWrapper = styled.div`
  padding-left: 5em;
  padding-right: 5em;

  @media (max-width: 767.98px) {
    padding-left: 3em;
    padding-right: 3em;
  }
`;

const FormRightImage = styled.div`
  width: 200px;
  background-color: #dddddd;
`;

type SignInFormData = {
  email: string;
  password: string;
};

const SignInPage = () => {
  const navigate = useNavigate();

  const { authStore } = useStore();

  const [, setGlobalLoading] = useLoading();

  const alert = useAlert();

  const [formData, setFormData] = useState<SignInFormData>({
    email: process.env.NODE_ENV === 'development' ? 'toan.doan@blackbook.ai' : '',
    password: process.env.NODE_ENV === 'development' ? '123456' : '',
  });
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const [loading, doSignIn] = useApi<
    {
      type: string;
      token: string;
    },
    SignInFormData
  >({
    method: 'POST',
    url: AUTH_SIGNIN,
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    alert.removeAll();

    setGlobalLoading(true);

    try {
      const { data } = await doSignIn({ data: formData });

      authStore.setToken(data.token);

      localStorage.setItem(HEHD_STORAGE_TOKEN, data.token);

      navigate('/');
    } catch (error: any) {
      console.error(error);

      if (!_.isNil(error.response) && !_.isNil(error.response.data.errors)) {
        alert.show('Incorrect email or password.');
      }
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="py-4 py-md-5">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} lg={7}>
            <div className="d-flex bg-white shadow">
              <FormWrapper className="flex-fill py-4">
                <div>
                  <div className="mb-5 text-center">
                    <div className="mb-3">
                      <img src={ImgLogo} width={66} alt="" />
                    </div>
                    <div className="h2 fw-bold">Sign In</div>
                    <div>Welcome back! Log in with your credentials</div>
                  </div>
                  <div>
                    <Form onSubmit={handleFormSubmit}>
                      <Form.Group className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              email: e.target.value,
                            })
                          }
                          disabled={loading}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-5">
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={isShowPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={formData.password}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              })
                            }
                            disabled={loading}
                            required
                          />
                          <InputGroup.Text
                            role="button"
                            onClick={() => setIsShowPassword(!isShowPassword)}
                          >
                            <FontAwesomeIcon icon={isShowPassword ? 'eye' : 'eye-slash'} />
                          </InputGroup.Text>
                        </InputGroup>
                        <div className="mt-3">
                          <Link to="/forgot-password" className="text-black">
                            Forgot Your Password?
                          </Link>
                        </div>
                      </Form.Group>
                      <div className="text-center">
                        <div className="mb-4">
                          <Button type="submit" variant="dark" disabled={loading}>
                            Sign In
                          </Button>
                        </div>
                        <>
                          Don't have an account?{' '}
                          <Link to="/sign-up" className="fw-bold text-decoration-none text-black">
                            Sign Up
                          </Link>
                        </>
                      </div>
                    </Form>
                  </div>
                </div>
              </FormWrapper>
              <FormRightImage className="d-none d-md-block" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignInPage;
