import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import ImgBanner from '@/assets/images/yellow_clothes.png';
import ContactForm from '@/components/ContactForm';

const Banner = styled.div`
  padding: 4em 0;
  color: #ffffff;
  background-color: #fee164;
  background-image: url(${ImgBanner});

  background-position: right 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const BannerTitle = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 4.5rem;
  line-height: normal;
`;

const BannerSubTitle = styled.div`
  font-size: 2.5rem;
  line-height: normal;
`;

const TermsAndConditionsPage = () => {
  return (
    <>
      <Banner>
        <Container>
          <div className="d-inline-block text-dark">
            <BannerTitle className="mb-1 fw-bold">Terms and Conditions</BannerTitle>
            <BannerSubTitle className="fw-normal">Before you start swapping</BannerSubTitle>
          </div>
        </Container>
      </Banner>
      <Container>
        <div className="my-5 py-5">
          <div className="mb-5 h4 fw-bold">Rules of Swapping</div>
          <div className="mb-5 pb-5">
            <ul className="px-3">
              <li className="mb-2">
                All garments must be clean, not damaged or stained. Clothes must also be smoke free
                and free of pet dander. Please give clothes in a condition in which you wish to
                receive.
              </li>
              <li className="mb-2">
                All complaints can be sent to{' '}
                <a href="mailto:hello@highendhammydowns.com">hello@highendhammydowns.com</a>
              </li>
              {/* <li className="mb-2">
                Those caught on-selling items will have their membership cancelled.
              </li> */}
              <li className="mb-2">
                High End Hammy Downs is a registered Trade Mark with IP Australia.
              </li>
              {/* <li className="mb-2">
                For School Uniforms all amounts include GST but exclude credit card charges related
                to personal credit cards.
              </li> */}
              <li className="mb-2">
                When discussing meeting locations, please meet during opening hours of
                landmarks/café/businesses etc
              </li>
              <li className="mb-2">
                Members may not share any personal contact or banking information to your matches
                whether in relation to you or any other person (for example, full names, home
                addresses, telephone numbers, URLs, credit/debit card or other banking details). If
                you do choose to reveal any personal information about yourself to other members,
                whether via email or otherwise, it is at your own risk. We encourage you to use the
                same caution in disclosing details about yourself to third parties online as you
                would under any other circumstances.
              </li>
            </ul>
          </div>
          <div className="mb-5 h4 fw-bold">Terms and Conditions</div>
          <div className="mb-5 pb-5">
            <ul className="ps-3">
              <li className="mb-2">
                All data and emails are used for swapping and matching purposes only.
              </li>
              <li className="mb-2">
                Payment functionality is through Stripe, please see their conditions here Security
                at Stripe{' '}
                <a href="https://stripe.com/docs/security/stripe" target="_bank">
                  https://stripe.com/docs/security/stripe
                </a>
              </li>
              <li className="mb-2">
                Classed as a social enterprise we bare no responsibility for the quality and the
                suitability of any garments supplied by members. Additionally, we do not perform
                background checks or endorse any users. By creating a social, environmental and
                cultural service for the community, we expect members to be responsible citizens and
                abide by the laws of our society.
              </li>
            </ul>
            <div className="pt-3">
              <a
                href="/docs/Hammys_Website_Privacy_Policy.pdf"
                download
                title="High End Hammy Downs - Terms and Conditions"
                className="d-inline-block text-dark text-decoration-none px-4 py-3 rounded-2 border bg-white"
              >
                <FontAwesomeIcon icon="file-pdf" />
                <span className="ms-2">
                  Click here to download the full <b>Privacy Policy</b>
                </span>
              </a>
            </div>
          </div>
          <div className="mb-3 h4 fw-bold">Let's Work Together</div>
          <div className="mb-5">
            Get in touch to start an In-kind partnership to cross promote our services
          </div>
          <div className="mb-5">
            <Row>
              <Col lg={8}>
                <ContactForm />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default TermsAndConditionsPage;
