import _ from 'lodash';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FormEvent, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import TimeAgo from '@/components/TimeAgo';
import { MATCH_SESSION } from '@/constants/apis';
import { MatchSessionStatus } from '@/models/MatchSession';
import { MatchSessionMessage } from '@/models/MatchSessionMessage';
import { useStore } from '@/store';
import { MatchSessionMessagePayload } from '@/stores/WsStore';
import { useApi } from '@/utils/axios';

const MessageList = styled.div`
  min-height: 300px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
`;

const MessageItem = styled.div`
  &:first-child {
    margin-bottom: 0px !important;
  }
`;

const MatchSessionMessagePage = observer(() => {
  const navigate = useNavigate();

  const { wsStore } = useStore();
  const { id } = useParams();

  const [, setGlobalLoading] = useLoading();

  const [matchSessionStatus, setMatchSessionStatus] = useState<MatchSessionStatus>();

  const [messages, setMessages] = useState<MatchSessionMessage[]>([]);
  const [formData, setFormData] = useState<{ body: string }>({ body: '' });

  const [loadingGetMatchSession, getMatchSession] = useApi<
    {
      id: number;
      status: MatchSessionStatus;
    },
    unknown
  >({
    url: `${MATCH_SESSION}/${id}`,
  });

  const [loadingUpdateMatchSession, updateMatchSession] = useApi<
    { success: boolean },
    { status: MatchSessionStatus }
  >({
    method: 'PUT',
    url: `${MATCH_SESSION}/${id}`,
  });

  const [loadingGetMessages, getMessages] = useApi<
    {
      data: {
        id: number;
        body: string;
        user: {
          id: number;
          full_name: string;
          email: string;
        };
        is_current_user: boolean;
        created_at: string;
      }[];
    },
    undefined
  >({
    url: `${MATCH_SESSION}/${id}/messages`,
  });

  useEffect(() => {
    autorun(() => {
      if (wsStore.connected) {
        wsStore.join(_.toInteger(id));
      }
    });

    (async () => {
      await loadMatchSession();
      await loadMessages();
    })();

    return () => {
      if (wsStore.joined) {
        wsStore.leave(_.toInteger(id));
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isNil(wsStore.newMessage)) {
      handleNewMessage(wsStore.newMessage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsStore.newMessage]);

  const loadMatchSession = async () => {
    if (loadingGetMatchSession) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getMatchSession();

    setGlobalLoading(false);

    setMatchSessionStatus(data.status);

    if (!_.includes([MatchSessionStatus.swapping, MatchSessionStatus.done], data.status)) {
      navigate(`/match-sessions/${id}`);
    }
  };

  const loadMessages = async () => {
    if (loadingGetMessages) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getMessages();

    setGlobalLoading(false);

    wsStore.setTriggerNotifications('on');

    setMessages(
      _(data.data)
        .map<MatchSessionMessage>((message) => ({
          id: message.id,
          body: message.body,
          isCurrentUser: message.is_current_user,
          user: {
            id: message.user.id,
            fullName: message.user.full_name,
            email: message.user.email,
          },
          createdAt: moment(message.created_at),
        }))
        .value()
    );
  };

  const handleDoneClicked = async () => {
    const isOk = window.confirm('Are you sure you want to complete this Match?');

    if (isOk) {
      setGlobalLoading(true);

      const { data } = await updateMatchSession({
        data: { status: MatchSessionStatus.done },
      });

      setGlobalLoading(false);

      if (data.success === true) {
        loadMatchSession();
      }
    }
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    wsStore.sendMessage(_.toInteger(id), formData.body);

    setFormData({ body: '' });
  };

  const handleNewMessage = (newMessage: MatchSessionMessagePayload) => {
    wsStore.setNewMessage(undefined);

    setMessages((oldMessages: MatchSessionMessage[]) => [
      {
        id: newMessage.id,
        body: newMessage.body,
        user: newMessage.user,
        isCurrentUser: newMessage.isCurrentUser,
        createdAt: newMessage.createdAt,
      },
      ...oldMessages,
    ]);
  };

  return (
    <>
      <div className="bg-white">
        <Container>
          <div className="py-5">
            <div className="h2 fw-bold">Match Message</div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="py-5">
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="p-5 shadow-sm bg-white">
                {matchSessionStatus === MatchSessionStatus.swapping && (
                  <Alert variant="warning">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="p-0 m-0 w-50">
                        Please click the <b>Done</b> button to confirm the match is complete and to
                        continue within this chat to organise the clothes exchange.
                      </p>
                      <Button
                        variant="outline-dark"
                        onClick={handleDoneClicked}
                        disabled={loadingUpdateMatchSession}
                      >
                        Done
                      </Button>
                    </div>
                  </Alert>
                )}
                <MessageList className="mb-4 d-flex flex-column-reverse">
                  {messages.length > 0 ? (
                    messages.map((message: MatchSessionMessage) => (
                      <MessageItem
                        className={`mb-4 w-75 ${
                          message.isCurrentUser ? 'align-self-end text-end' : ''
                        }`}
                        key={message.id}
                      >
                        {!message.isCurrentUser && (
                          <div className="mb-1 fw-bold">{message.user.fullName}</div>
                        )}
                        <div className="mb-1">{message.body}</div>
                        <div className="text-muted">
                          <TimeAgo value={message.createdAt} />
                        </div>
                      </MessageItem>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center m-auto text-muted">
                      No message yet!
                    </div>
                  )}
                </MessageList>
                <Form onSubmit={handleFormSubmit}>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Enter your message"
                      value={formData.body}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          body: e.target.value,
                        })
                      }
                      required
                      disabled={!wsStore.joined}
                    />
                    <Button type="submit" variant="dark" disabled={!wsStore.joined}>
                      Send
                    </Button>
                  </InputGroup>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
});

export default MatchSessionMessagePage;
