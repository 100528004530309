import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import ImgBanner from '@/assets/images/clothes_bag.png';
import ImgHeartColored from '@/assets/images/heart_colored.png';
import ImgIcBowtie from '@/assets/images/ic_bowtie.png';
import ImgIcMatchType from '@/assets/images/ic_match_type.png';
import ContactForm from '@/components/ContactForm';

const Banner = styled.div`
  padding: 4em 0;
  color: #ffffff;
  background-color: #f79fa7;
  background-image: url(${ImgBanner});
  background-position: right 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const BannerTitle = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 4.5rem;
  line-height: normal;
`;

const BannerSubTitle = styled.div`
  font-size: 2.5rem;
  line-height: normal;
`;

const HIWStep = styled.div`
  position: relative;
  width: 250px;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: '';
    top: calc((120px / 2) - 3px);
    width: calc(100% + 50px);
    height: 3px;
    border-bottom: 3px dashed #707070;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`;

const HIWStepNum = styled.div`
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  font-size: 26px;
`;

const AboutPage = () => {
  return (
    <>
      <Banner>
        <Container>
          <div className="d-inline-block text-dark">
            <BannerTitle className="mb-1 fw-bold">About Us</BannerTitle>
            <BannerSubTitle className="fw-normal">Who we are and what we do</BannerSubTitle>
          </div>
        </Container>
      </Banner>
      <Container>
        <div className="my-5 py-5">
          <div className="pb-4">
            <div className="mb-5 h4 fw-bold">Matching App</div>
            <div className="mb-4">Like a dating app for your kids hand-me-down clothes.</div>
            <div>
              Launching a new community of socially and environmentally aware parents and families.
              Be connected instantly with parents and start receiving a brand new wardrobe for your
              children today! Did you know globally almost 80% of donated clothes end up in landfill
              or are incinerated? Good quality clothes were not meant for landfill, they were meant
              for closets. By swapping your kids clothes at High End Hammy Downs this will extend
              their life and guarantees your kids hand-me-downs will be loved again. One time
              matches or a lifetime of hand-me-downs for all future occasions.
            </div>
          </div>
          <div className="my-5 pb-4">
            <div className="mb-5 h4 fw-bold">How It Works</div>
            <div className="d-flex justify-content-between">
              <HIWStep className="text-center">
                <div className="position-relative">
                  <img src={ImgHeartColored} height={120} alt="" />
                  <HIWStepNum className="position-absolute top-50 start-50 fw-bold">1</HIWStepNum>
                </div>
                <div className="mt-4 h5">Sign up</div>
              </HIWStep>
              <HIWStep className="text-center">
                <div className="position-relative">
                  <img src={ImgHeartColored} height={120} alt="" />
                  <HIWStepNum className="position-absolute top-50 start-50 fw-bold">2</HIWStepNum>
                </div>
                <div className="mt-4 h5">Enter clothing details</div>
              </HIWStep>
              <HIWStep className="text-center">
                <div className="position-relative">
                  <img src={ImgHeartColored} height={120} alt="" />
                  <HIWStepNum className="position-absolute top-50 start-50 fw-bold">3</HIWStepNum>
                </div>
                <div className="mt-4 h5">
                  Matched instantly based on clothing size, style, gender and location
                </div>
              </HIWStep>
              <HIWStep className="text-center">
                <div className="position-relative">
                  <img src={ImgHeartColored} height={120} alt="" />
                  <HIWStepNum className="position-absolute top-50 start-50 fw-bold">4</HIWStepNum>
                </div>
                <div className="mt-4 h5">Start swapping</div>
              </HIWStep>
            </div>
          </div>
          <div className="my-5 pb-4">
            <div className="mb-5 h4 fw-bold">Why Join The High End Hammy Downs Family</div>
            <Row>
              <Col lg={6}>
                <div className="mb-4">
                  <img src={ImgIcBowtie} alt="" width={60} />
                  <span className="ms-4">Contribute to a circular economy</span>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-4">
                  <img src={ImgIcBowtie} alt="" width={60} />
                  <span className="ms-4">Reduce reliance on overseas clothes manufactures</span>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-4">
                  <img src={ImgIcBowtie} alt="" width={60} />
                  <span className="ms-4">Reduce costs and recycling costs</span>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-4">
                  <img src={ImgIcBowtie} alt="" width={60} />
                  <span className="ms-4">Promote clothes swapping to the next generation</span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="my-5 pb-4">
            <div className="mb-5 h4 fw-bold">Match Types</div>
            <div>
              <div className="mb-4">
                <img src={ImgIcMatchType} alt="" width={60} />
                <span className="ms-4 fw-bold">Perfect match</span>: everything for giving or
                receiving matches
              </div>
              <div className="mb-4">
                <img src={ImgIcMatchType} alt="" width={60} />
                <span className="ms-4 fw-bold">Happy match</span>: matches everything except style
                of clothing and swapping method
              </div>
              <div className="mb-4">
                <img src={ImgIcMatchType} alt="" width={60} />
                <span className="ms-4 fw-bold">Mini match</span>: matches everything except number
                of items, types of clothing, style of clothing, and swapping method
              </div>
              <div className="mb-4">
                <img src={ImgIcMatchType} alt="" width={60} />
                <span className="ms-4 fw-bold">Long distance match</span>: matches everything except
                location and swapping method
              </div>
            </div>
          </div>
          <div className="mb-3 h4 fw-bold">Let's Work Together</div>
          <div className="mb-5">
            Get in touch to start an In-kind partnership to cross promote our services
          </div>
          <div className="mb-5">
            <Row>
              <Col lg={8}>
                <ContactForm />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AboutPage;
