import Bluebird from 'bluebird';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ImgBanner from '@/assets/images/banner.png';
import MatchSessionItem from '@/components/MatchSessionItem';
import Paginate from '@/components/Paginate';
import { MATCH_SESSION, SET } from '@/constants/apis';
import { Country } from '@/enums/CountryState';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { MatchLevel, MatchSession, MatchSessionStatus } from '@/models/MatchSession';
import { Set, SetGender, SetGive, SetType } from '@/models/Set';
import NoMatchSession from '@/pages/MatchSession/NoMatchSession';
import { useApi } from '@/utils/axios';
import { getMatchLevelName, getSetGender } from '@/utils/hehd';

const Header = styled.div`
  border-bottom-color: #555555 !important;
`;
const Banner = styled.div`
  padding: 4em 0;
  color: #ffffff;
  background-color: #fba9a4;
  background-image: url(${ImgBanner});
  background-position: right 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const BannerTitle = styled.div`
  font-family: 'Lobster Two', sans-serif;
  font-size: 2.5rem;
  line-height: normal;
`;

const BannerSubTitle = styled.div`
  font-size: 1.5rem;
  line-height: normal;
`;

const TabBarItem = styled.div`
  color: #313638;
  border-color: #313638 !important;
  border-radius: 14px 14px 0px 0px;

  &.active,
  &:hover {
    color: #f7d9d3;
    background-color: #313638;
  }
`;

const MatchSessionPage = () => {
  const navigate = useNavigate();

  const [, setGlobalLoading] = useLoading();

  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [type, setType] = useState<SetType>(SetType.receive);
  const [sets, setSets] = useState<Set[] | SetGive[]>([]);
  const [matchSessions, setMatchSessions] = useState<MatchSession[]>([]);

  const [filterSets, setFilterSets] = useState<number[]>([]);
  const [filterMatchLevels, setFilterMatchLevels] = useState<MatchLevel[]>(_.values(MatchLevel));

  const filterMatchSessions = matchSessions
    .filter((matchSession) =>
      _.includes(
        filterSets,
        matchSession.type === SetType.give ? matchSession.setGive.id : matchSession.setReceive.id
      )
    )
    .filter((matchSession) => _.includes(filterMatchLevels, matchSession.level));

  const [loading, getMatchSessions] = useApi<
    {
      meta: {
        last_page: number;
      };
      data: {
        id: number;
        level: MatchLevel;
        status: MatchSessionStatus;
        type: SetType;
        unit_amount: number | null;
        currency: string;
        is_match_swapping_method: boolean;
        is_pay_required: boolean;
        set_receive: {
          id: number;
          type: SetType;
          gender: SetGender;
          num_of_items: NumOfItems;
          sizes: Sizes[];
          types_of_clothing: TypesOfClothing[];
          clothing_styles: ClothingStyles[];
          is_hehd: boolean;
          user: {
            id: number;
            full_name: string;
            email: string;
            postcode: string;
            country: string;
            swapping_methods: {
              id: number;
              name: string;
              is_required_address: boolean;
            }[];
          };
        };
        set_give: {
          id: number;
          type: SetType;
          gender: SetGender;
          num_of_items: NumOfItems;
          sizes: Sizes[];
          types_of_clothing: TypesOfClothing[];
          clothing_styles: ClothingStyles[];
          is_hehd: boolean;
          user: {
            id: number;
            full_name: string;
            email: string;
            postcode: string;
            country: string;
            swapping_methods: {
              id: number;
              name: string;
              is_required_address: boolean;
            }[];
          };
          give_galleries: { id: number; url: string }[];
        };
      }[];
    },
    unknown
  >({
    url: MATCH_SESSION,
  });

  const [loadingSets, getSets] = useApi<
    {
      id: number;
      type: SetType;
      gender: SetGender;
      num_of_items: NumOfItems;
      sizes: Sizes[];
      types_of_clothing: TypesOfClothing[];
      clothing_styles: ClothingStyles[];
      give_galleries?: { id: number; url: string }[];
      is_hehd: boolean;
    }[],
    unknown
  >({
    url: SET,
  });

  useEffect(() => {
    loadSets();

    setMatchSessions([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    loadMatchSessions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, page]);

  const isFilterSetDisabled = useMemo(() => {
    if (_.isEmpty(sets) || _.isEmpty(matchSessions)) {
      return true;
    }

    return !sets.some((s) =>
      matchSessions
        .map((m) => (type === SetType.give ? m.setGive.id : m.setReceive.id))
        .includes(s.id)
    );
  }, [type, sets, matchSessions]);

  const loadSets = async () => {
    if (loadingSets) {
      return;
    }

    setSets([]);

    await Bluebird.delay(10);

    const { data } = await getSets({
      params: { type },
    });

    setFilterSets(
      _(data)
        .map((s) => s.id)
        .value()
    );
    await Bluebird.delay(10);

    if (type === SetType.receive) {
      setSets(
        _(data)
          .map<Set>((set) => ({
            id: set.id,
            type: set.type,
            gender: set.gender,
            numOfItems: set.num_of_items,
            sizes: set.sizes,
            typesOfClothing: set.types_of_clothing,
            clothingStyles: set.clothing_styles,
            isHEHD: set.is_hehd,
          }))
          .value()
      );
    } else {
      setSets(
        _(data)
          .map<SetGive>((set) => ({
            id: set.id,
            type: set.type,
            gender: set.gender,
            numOfItems: set.num_of_items,
            sizes: set.sizes,
            typesOfClothing: set.types_of_clothing,
            clothingStyles: set.clothing_styles,
            giveGalleries: (set.give_galleries || []).map((g) => ({
              id: g.id,
              url: g.url,
            })),
            isHEHD: set.is_hehd,
          }))
          .value()
      );
    }
  };

  const loadMatchSessions = async () => {
    if (loading) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getMatchSessions({
      params: {
        type,
        statuses: [
          MatchSessionStatus.waitingApproval,
          ...(type === SetType.receive ? [MatchSessionStatus.waiting] : []),
        ],
        page,
        limit: 9,
      },
    });

    setGlobalLoading(false);

    setTotalPage(data.meta.last_page);
    setMatchSessions(
      _(data.data)
        .map<MatchSession>(
          ({
            //
            id,
            level,
            status,
            type,
            unit_amount: unitAmount,
            set_receive: setReceive,
            set_give: setGive,
            is_match_swapping_method: isMatchSwappingMethod,
            is_pay_required: isPayRequired,
          }) => ({
            id,
            level,
            status,
            type,
            unitAmount,
            isMatchSwappingMethod,
            isPayRequired,
            setReceive: {
              id: setReceive.id,
              type: setReceive.type,
              gender: setReceive.gender,
              numOfItems: setReceive.num_of_items,
              sizes: setReceive.sizes,
              typesOfClothing: setReceive.types_of_clothing,
              clothingStyles: setReceive.clothing_styles,
              isHEHD: setReceive.is_hehd,
              user: {
                id: setReceive.user.id,
                fullName: setReceive.user.full_name,
                email: setReceive.user.email,
                postcode: setReceive.user.postcode,
                country: setReceive.user.country as Country,
                swappingMethods: _.map(setReceive.user.swapping_methods, (s) => ({
                  id: s.id,
                  name: s.name,
                  isRequiredAddress: s.is_required_address,
                })),
              },
            },
            setGive: {
              id: setGive.id,
              type: setGive.type,
              gender: setGive.gender,
              numOfItems: setGive.num_of_items,
              sizes: setGive.sizes,
              typesOfClothing: setGive.types_of_clothing,
              clothingStyles: setGive.clothing_styles,
              isHEHD: setGive.is_hehd,
              user: {
                id: setGive.user.id,
                fullName: setGive.user.full_name,
                email: setGive.user.email,
                postcode: setGive.user.postcode,
                country: setGive.user.country as Country,
                swappingMethods: _.map(setGive.user.swapping_methods, (s) => ({
                  id: s.id,
                  name: s.name,
                  isRequiredAddress: s.is_required_address,
                })),
              },
              giveGalleries: setGive.give_galleries,
            },
          })
        )
        .value()
    );
  };

  const handleSetTypeChange = (type: SetType) => {
    setType(type);
  };

  const handleFilterSetChange = (val: number) => {
    setFilterSets(
      _([...filterSets, val])
        .filter((v) => (_.includes(filterSets, val) ? v !== val : true))
        .union()
        .value()
    );
  };

  const handleFilterMatchLevelChange = (val: MatchLevel) => {
    setFilterMatchLevels(
      _([...filterMatchLevels, val])
        .filter((v) => (_.includes(filterMatchLevels, val) ? v !== val : true))
        .union()
        .value()
    );
  };

  return (
    <>
      <Header className="bg-white border-bottom">
        <Banner className="mb-4">
          <Container>
            <div className="d-inline-block text-dark">
              <BannerTitle className="mb-1 fw-bold">Your Matches</BannerTitle>
              <BannerSubTitle className="fw-normal">
                Select your match, connect, and start swapping your kid's clothes
              </BannerSubTitle>
            </div>
          </Container>
        </Banner>
        <Container>
          <div className="d-flex">
            <TabBarItem
              role="button"
              className={`me-4 px-4 py-3 border border-bottom-0${
                type === SetType.receive ? ' active' : ''
              }`}
              onClick={() => handleSetTypeChange(SetType.receive)}
            >
              Receiving
            </TabBarItem>
            <TabBarItem
              role="button"
              className={`px-4 py-3 border border-bottom-0${
                type === SetType.give ? ' active' : ''
              }`}
              onClick={() => handleSetTypeChange(SetType.give)}
            >
              Giving
            </TabBarItem>
          </div>
        </Container>
      </Header>
      <div className="position-relative">
        <Container>
          <div className="py-5">
            <Row>
              <Col lg={3}>
                <div className="d-flex flex-column p-4 border bg-white">
                  <Link
                    to={`/me/bundles?type=${type === SetType.receive ? 'receive' : 'give'}`}
                    state={{ open: true }}
                    className="d-block text-decoration-none"
                  >
                    <Button variant="dark" size="sm" className="w-100 p-2">
                      Create a Bundle
                    </Button>
                  </Link>
                  <hr />
                  <div className="mt-3 flex-fill">
                    <div className={classNames(type === SetType.receive && 'mb-4')}>
                      <div className="mb-3 fw-bold">Pending Bundles</div>
                      {_(sets)
                        .map((set, i) => (
                          <div className={classNames(i !== sets.length - 1 && 'mb-3')} key={i}>
                            <Form.Check
                              id={`set_${set.type}_${set.id}`}
                              type="checkbox"
                              checked={filterSets.includes(set.id)}
                              label={[
                                `Set #${i + 1}`,
                                `${getSetGender(set.gender)}`,
                                `Size ${set.sizes.join(', ')}`,
                              ].join(' - ')}
                              onChange={() => handleFilterSetChange(set.id)}
                              disabled={isFilterSetDisabled}
                            />
                          </div>
                        ))
                        .value()}
                      {!loading && sets.length === 0 && (
                        <div className="text-muted">No bundles yet!</div>
                      )}
                    </div>
                    {type === SetType.receive && (
                      <>
                        <div className="mb-3 fw-bold">Filter Types</div>
                        {_(MatchLevel)
                          .values()
                          .map((level, i) => (
                            <div
                              className={classNames(i !== _(MatchLevel).size() - 1 && 'mb-3')}
                              key={level.toLowerCase()}
                            >
                              <Form.Check
                                id={`level_${level.toLowerCase()}`}
                                type="checkbox"
                                checked={filterMatchLevels.includes(level)}
                                label={getMatchLevelName(level)}
                                onChange={() => handleFilterMatchLevelChange(level)}
                              />
                            </div>
                          ))
                          .value()}
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col>
                {filterMatchSessions.length > 0 && (
                  <Row className="g-4">
                    {_(filterMatchSessions)
                      .map((matchSession) => (
                        <Col lg={4} key={matchSession.id}>
                          <MatchSessionItem
                            type={type}
                            data={matchSession}
                            onButtonClicked={() => navigate(`/match-sessions/${matchSession.id}`)}
                          />
                        </Col>
                      ))
                      .value()}
                  </Row>
                )}
                {matchSessions.length > 0 && totalPage > 0 ? (
                  <div className="mt-5 d-flex justify-content-center">
                    <Paginate
                      pageCount={totalPage}
                      onPageChange={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                ) : (
                  <NoMatchSession />
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MatchSessionPage;
