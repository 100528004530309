import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLoading } from 'react-hook-loading';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Image from '@/components/Image';
import { MatchSessionCheckList } from '@/components/MatchSessionItem';
import { ADMIN_MATCH_SESSION } from '@/constants/apis';
import { Country } from '@/enums/CountryState';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { MatchLevel, MatchSession, MatchSessionStatus } from '@/models/MatchSession';
import { SetGender, SetGiveGallery, SetType } from '@/models/Set';
import { useApi } from '@/utils/axios';
import { getMatchSessionStatus } from '@/utils/hehd';

const GallerySmallItem = styled.div`
  border: 2px solid transparent;

  &.active {
    border-color: #f8c400;
  }
`;

const PageTitle = styled.span`
  border-bottom-width: 2px !important;
  border-bottom-color: #f8c400 !important;
`;

const DashboardMatchDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [, setGlobalLoading] = useLoading();

  const [matchSession, setMatchSession] = useState<MatchSession | undefined>();
  const [gallerySelected, setGallerySelected] = useState<SetGiveGallery | undefined>();

  const [loadingGetMatchSession, getMatchSession] = useApi<
    {
      id: number;
      level: MatchLevel;
      status: MatchSessionStatus;
      type: SetType;
      unit_amount: number | null;
      total_messages: number;
      is_match_swapping_method: boolean;
      is_pay_required: boolean;
      set_receive: {
        id: number;
        type: SetType;
        gender: SetGender;
        num_of_items: NumOfItems;
        sizes: Sizes[];
        types_of_clothing: TypesOfClothing[];
        clothing_styles: ClothingStyles[];
        is_hehd: boolean;
        user: {
          id: number;
          full_name: string;
          email: string;
          postcode: string;
          country: string;
          swapping_methods: {
            id: number;
            name: string;
            is_required_address: boolean;
          }[];
        };
      };
      set_give: {
        id: number;
        type: SetType;
        gender: SetGender;
        num_of_items: NumOfItems;
        sizes: Sizes[];
        types_of_clothing: TypesOfClothing[];
        clothing_styles: ClothingStyles[];
        is_hehd: boolean;
        user: {
          id: number;
          full_name: string;
          email: string;
          postcode: string;
          country: string;
          swapping_methods: {
            id: number;
            name: string;
            is_required_address: boolean;
          }[];
        };
        give_galleries: { id: number; url: string }[];
      };
    },
    unknown
  >({
    url: `${ADMIN_MATCH_SESSION}/${id}`,
  });

  useEffect(() => {
    loadMatchSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMatchSession = async () => {
    if (loadingGetMatchSession) {
      return;
    }

    setGlobalLoading(true);

    const { data } = await getMatchSession();

    setGlobalLoading(false);

    const {
      //
      id,
      level,
      status,
      type,
      unit_amount: unitAmount,
      set_receive: setReceive,
      set_give: setGive,
      total_messages: totalMessages,
      is_match_swapping_method: isMatchSwappingMethod,
      is_pay_required: isPayRequired,
    } = data;

    const result: MatchSession = {
      id,
      level,
      status,
      type,
      unitAmount,
      totalMessages,
      isMatchSwappingMethod,
      isPayRequired,
      setReceive: {
        id: setReceive.id,
        type: setReceive.type,
        gender: setReceive.gender,
        numOfItems: setReceive.num_of_items,
        sizes: setReceive.sizes,
        typesOfClothing: setReceive.types_of_clothing,
        clothingStyles: setReceive.clothing_styles,
        isHEHD: setReceive.is_hehd,
        user: {
          id: setReceive.user.id,
          fullName: setReceive.user.full_name,
          email: setReceive.user.email,
          postcode: setReceive.user.postcode,
          country: setReceive.user.country as Country,
          swappingMethods: _.map(setReceive.user.swapping_methods, (s) => ({
            id: s.id,
            name: s.name,
            isRequiredAddress: s.is_required_address,
          })),
        },
      },
      setGive: {
        id: setGive.id,
        type: setGive.type,
        gender: setGive.gender,
        numOfItems: setGive.num_of_items,
        sizes: setGive.sizes,
        typesOfClothing: setGive.types_of_clothing,
        clothingStyles: setGive.clothing_styles,
        isHEHD: setGive.is_hehd,
        user: {
          id: setGive.user.id,
          fullName: setGive.user.full_name,
          email: setGive.user.email,
          postcode: setReceive.user.postcode,
          country: setReceive.user.country as Country,
          swappingMethods: _.map(setGive.user.swapping_methods, (s) => ({
            id: s.id,
            name: s.name,
            isRequiredAddress: s.is_required_address,
          })),
        },
        giveGalleries: setGive.give_galleries,
      },
    };

    setMatchSession(result);
    setGallerySelected(result.setGive.giveGalleries[0]);
  };

  return (
    <div className="p-5">
      <div role="button" className="mb-5 d-flex align-items-center" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="ms-2 fw-bold">Back</span>
      </div>
      <div className="mb-5">
        <PageTitle className="pb-2 pe-5 h5 fw-bold border-bottom">Match Detail</PageTitle>
      </div>
      {!_.isNil(matchSession) && (
        <div className="position-relative">
          <Row>
            <Col xs={12} lg={6}>
              <Row>
                <Col xs={3}>
                  <div className="d-flex flex-column">
                    {matchSession.setGive.giveGalleries.map((gallery, i) => (
                      <GallerySmallItem
                        className={`mb-3 ratio ratio-1x1 bg-light ${
                          gallerySelected === gallery ? 'active' : ''
                        }`}
                        onClick={() => setGallerySelected(gallery)}
                        key={i}
                      >
                        <Image src={gallery.url} className="w-100 p-2" />
                      </GallerySmallItem>
                    ))}
                  </div>
                </Col>
                <Col>
                  <div className="ratio ratio-1x1 bg-light">
                    {gallerySelected && <Image src={gallerySelected.url} className="w-100" />}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <div className="ps-0 ps-md-4 mt-5 mt-md-0">
                <div className="mb-3">
                  <Row className="mb-2">
                    <Col lg={4}>Status</Col>
                    <Col className="text-truncate">
                      {getMatchSessionStatus(matchSession.status)}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg={4}>Giver</Col>
                    <Col className="text-truncate">{matchSession.setGive.user!.fullName}</Col>
                  </Row>
                  {matchSession.status !== MatchSessionStatus.waiting && (
                    <Row className="mb-2">
                      <Col lg={4}>Receiver</Col>
                      <Col className="text-truncate">{matchSession.setReceive.user!.fullName}</Col>
                    </Row>
                  )}
                </div>
                <hr />
                <MatchSessionCheckList type={matchSession.type} data={matchSession} />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default DashboardMatchDetailPage;
