import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FileUploader } from 'react-drag-drop-files';
import Select, { MultiValue } from 'react-select';
import styled from 'styled-components';

import Image from '@/components/Image';
import { ClothingStyles, NumOfItems, Sizes, TypesOfClothing } from '@/enums/SetReceiveGive';
import { SetForm, SetGender, SetGiveForm, SetGiveGallery } from '@/models/Set';
import { getSetGender } from '@/utils/hehd';

const ImageSelector = styled.div`
  min-height: 120px;
  border: 1px dashed #ddd;
  background-color: #f9f9f9;
`;

const RemoveFileBtn = styled.button`
  margin-top: -13px;
  margin-right: -13px;
  width: 36px;
  height: 36px;
  line-height: 39px;
  outline: none;
`;

const ReceiveSetInfo = ({ data }: { data: SetForm }) => {
  const [sizes, setSizes] = useState<string[]>(data.sizes || []);
  const [typesOfClothing, setTypesOfClothing] = useState<string[]>(data.typesOfClothing || []);
  const [clothingStyles, setClothingStyles] = useState<string[]>(data.clothingStyles || []);

  const handleSizesChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const v = e.map((e) => e.value);

    if (v.length > 2) {
      return;
    }

    data.sizes = v;

    setSizes(() => v);
  };

  const handleTypesOfClothingChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const v = e.map((e) => e.value);

    if (v.length > 3) {
      return;
    }

    data.typesOfClothing = v;

    setTypesOfClothing(() => v);
  };

  const handleClothingStylesChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const v = e.map((e) => e.value);

    if (v.length > 3) {
      return;
    }

    data.clothingStyles = v;

    setClothingStyles(() => v);
  };

  return (
    <>
      {data.name && <div className="h5 mb-3 fw-bold">{data.name}</div>}
      <Form.Group className="mb-2">
        <Form.Check
          type="radio"
          id="basic"
          name="hehd"
          label="Basic Bundle"
          defaultChecked={!data.isHEHD}
          onChange={() => (data.isHEHD = false)}
        />
        <Form.Text>Non-branded clothing, such as Kmart, Target, Big W, H&M etc</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="radio"
          id="hehd"
          name="hehd"
          label="High End Bundle"
          defaultChecked={data.isHEHD}
          onChange={() => (data.isHEHD = true)}
        />
        <Form.Text>
          Brand clothing only such as items from Myer, DJ’s, Australian made or Boutique (Sprout,
          Ollie etc)
        </Form.Text>
      </Form.Group>
      <Row>
        <Col lg={7}>
          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <div className="d-flex">
              {_.values(SetGender).map((val, i) => (
                <Form.Check
                  id={`gender_${data.index}_${i}`}
                  key={i}
                  inline
                  name={`gender_${data.index}`}
                  type="radio"
                  label={getSetGender(val)}
                  className="flex-fill"
                  defaultChecked={data.gender === val}
                  onChange={() => (data.gender = val)}
                  required
                />
              ))}
            </div>
          </Form.Group>
        </Col>
        <Col lg={5}></Col>
      </Row>
      <div className="mb-3 fw-bold">Clothing Filters</div>
      <Row>
        <Col lg={5}>
          <Form.Group className="mb-3">
            <Form.Label>Sizes</Form.Label>
            <Select
              isMulti
              value={(sizes || []).map((s) => ({ label: s, value: s }))}
              options={_(Sizes)
                .map((val: string) => ({
                  value: val,
                  label: val,
                }))
                .value()}
              onChange={handleSizesChanged}
            />
          </Form.Group>
        </Col>
        <Col lg={7}>
          <Form.Group className="mb-3">
            <Form.Label>Number of Items</Form.Label>
            <Row>
              {_.values(NumOfItems).map((val, i) => (
                <Col lg={4} key={i}>
                  <div className="mb-2">
                    <Form.Check
                      id={`num_of_items_${data.index}_${i}`}
                      inline
                      name={`num_of_items_${data.index}`}
                      type="radio"
                      label={`${val} items`}
                      className="flex-fill"
                      defaultChecked={data.numOfItems === val}
                      onChange={() => (data.numOfItems = val)}
                      required
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={5}>
          <Form.Group className="mb-3">
            <Form.Label>Types of Clothing</Form.Label>
            <Select
              isMulti
              value={(typesOfClothing || []).map((s) => ({ label: s, value: s }))}
              options={_(TypesOfClothing)
                .map((val: string) => ({
                  value: val,
                  label: val,
                }))
                .value()}
              onChange={handleTypesOfClothingChanged}
            />
          </Form.Group>
        </Col>
        <Col lg={7}>
          <Form.Group className="mb-3">
            <Form.Label>Clothing Style</Form.Label>
            <Select
              isMulti
              value={(clothingStyles || []).map((s) => ({ label: s, value: s }))}
              options={_(ClothingStyles)
                .map((val: string) => ({
                  value: val,
                  label: val,
                }))
                .value()}
              onChange={handleClothingStylesChanged}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const GiveSetInfo = ({ data }: { data: SetGiveForm }) => {
  const galleryRef = useRef<any | undefined>();
  const cameraRef = useRef<any | undefined>();

  const [files, setFiles] = useState<File[]>(data.giveGalleries || []);
  const [oldGalleries, setOldGalleries] = useState<SetGiveGallery[]>(data.oldGiveGalleries || []);

  const [sizes, setSizes] = useState<string[]>(data.sizes || []);
  const [typesOfClothing, setTypesOfClothing] = useState<string[]>(data.typesOfClothing || []);
  const [clothingStyles, setClothingStyles] = useState<string[]>(data.clothingStyles || []);

  const handleSizesChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const v = e.map((e) => e.value);

    if (v.length > 2) {
      return;
    }

    data.sizes = v;

    setSizes(() => v);
  };

  const handleTypesOfClothingChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const v = e.map((e) => e.value);

    if (v.length > 3) {
      return;
    }

    data.typesOfClothing = v;

    setTypesOfClothing(() => v);
  };

  const handleClothingStylesChanged = (e: MultiValue<{ label: string; value: string }>) => {
    const v = e.map((e) => e.value);

    if (v.length > 3) {
      return;
    }

    data.clothingStyles = v;

    setClothingStyles(() => v);
  };

  const handleFilePicked = (file: File) => {
    setFiles((oldFiles) => [...oldFiles, file]);

    data.giveGalleries = _.concat([...(data.giveGalleries || []), file]);
  };

  const handleRemoveFileClicked = (file: File) => {
    setFiles((oldFiles) => [..._.filter(oldFiles, (f) => f !== file)]);

    data.giveGalleries = _.filter(data.giveGalleries || [], (f) => f !== file);
  };

  const handleRemoveOldGalleryClicked = (gallery: SetGiveGallery) => {
    setOldGalleries((oldGalleries) => [..._.filter(oldGalleries, (g) => g !== gallery)]);

    data.destroyGiveGalleries = _(data.oldGiveGalleries || [])
      .filter((g) => g === gallery)
      .map((g) => g.id)
      .value();
  };

  return (
    <>
      {data.name && <div className="h5 mb-3 fw-bold">{data.name}</div>}
      <Form.Group className="mb-2">
        <Form.Check
          type="radio"
          id="basic"
          name="hehd"
          label="Basic Bundle"
          defaultChecked={!data.isHEHD}
          onChange={() => (data.isHEHD = false)}
        />
        <Form.Text>Non-branded clothing, such as Kmart, Target, Big W, H&M etc</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="radio"
          id="hehd"
          name="hehd"
          label="High End Bundle"
          defaultChecked={data.isHEHD}
          onChange={() => (data.isHEHD = true)}
        />
        <Form.Text>
          Brand clothing only such as items from Myer, DJ’s, Australian made or Boutique (Sprout,
          Ollie etc)
        </Form.Text>
      </Form.Group>
      <Row>
        <Col lg={7}>
          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <div className="d-flex">
              {_.values(SetGender).map((val, i) => (
                <Form.Check
                  id={`gender_${data.index}_${i}`}
                  key={i}
                  inline
                  name={`gender_${data.index}`}
                  type="radio"
                  label={getSetGender(val)}
                  className="flex-fill"
                  defaultChecked={data.gender === val}
                  onChange={() => (data.gender = val)}
                  required
                />
              ))}
            </div>
          </Form.Group>
        </Col>
        <Col lg={5}></Col>
      </Row>
      <div className="mb-3 fw-bold">Clothing Category</div>
      <Row>
        <Col lg={5}>
          <Form.Group className="mb-3">
            <Form.Label>Sizes</Form.Label>
            <Select
              isMulti
              value={(sizes || []).map((s) => ({ label: s, value: s }))}
              options={_(Sizes)
                .map((val: string) => ({
                  value: val,
                  label: val,
                }))
                .value()}
              onChange={handleSizesChanged}
            />
          </Form.Group>
        </Col>
        <Col lg={7}>
          <Form.Group className="mb-3">
            <Form.Label>Number of Items</Form.Label>
            <Row>
              {_.values(NumOfItems).map((val, i) => (
                <Col lg={4} key={i}>
                  <div className="mb-2">
                    <Form.Check
                      id={`num_of_items_${data.index}_${i}`}
                      inline
                      name={`num_of_items_${data.index}`}
                      type="radio"
                      label={`${val} items`}
                      className="flex-fill"
                      defaultChecked={data.numOfItems === val}
                      onChange={() => (data.numOfItems = val)}
                      required
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={5}>
          <Form.Group className="mb-3">
            <Form.Label>Types of Clothing</Form.Label>
            <Select
              isMulti
              value={(typesOfClothing || []).map((s) => ({ label: s, value: s }))}
              options={_(TypesOfClothing)
                .map((val: string) => ({
                  value: val,
                  label: val,
                }))
                .value()}
              onChange={handleTypesOfClothingChanged}
            />
          </Form.Group>
        </Col>
        <Col lg={7}>
          <Form.Group className="mb-3">
            <Form.Label>Clothing Style</Form.Label>
            <Select
              isMulti
              value={(clothingStyles || []).map((s) => ({ label: s, value: s }))}
              options={_(ClothingStyles)
                .map((val: string) => ({
                  value: val,
                  label: val,
                }))
                .value()}
              onChange={handleClothingStylesChanged}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="my-3">
        Load up to 3 photos of items you’re donating, either all together or individually
      </div>
      {isMobile && (
        <Row>
          <Col xs={5}>
            <ImageSelector
              role="button"
              className="d-flex justify-content-center align-items-center"
              onClick={() =>
                files.length + oldGalleries.length < 3 ? cameraRef.current.click() : null
              }
            >
              <div className="text-center text-muted">
                <FontAwesomeIcon icon="camera" size="2x" />
                <p className="m-0 mt-1 p-0">Camera</p>
              </div>
              <input
                ref={cameraRef}
                type="file"
                className="d-none"
                accept="image/*"
                capture={'environment'}
                onChange={(e) =>
                  !_.isNil(e.target.files) && e.target.files.length
                    ? handleFilePicked(e.target.files[0])
                    : null
                }
              />
            </ImageSelector>
          </Col>
          <Col>
            <ImageSelector
              role="button"
              className="p-4 d-flex justify-content-center align-items-center"
              onClick={() =>
                files.length + oldGalleries.length < 3 ? galleryRef.current.click() : null
              }
            >
              <div className="text-center text-muted">Browse in your gallery</div>
              <input
                ref={galleryRef}
                type="file"
                className="d-none"
                accept="image/*"
                capture={false}
                onChange={(e) =>
                  !_.isNil(e.target.files) ? handleFilePicked(e.target.files[0]) : null
                }
              />
            </ImageSelector>
          </Col>
        </Row>
      )}
      {!isMobile && (
        <FileUploader
          handleChange={(file: any) => {
            handleFilePicked(file);
          }}
          name="file"
          types={['jpg', 'jpeg', 'png']}
          classes="w-100"
        >
          <ImageSelector role="button" className="d-flex justify-content-center align-items-center">
            <div className="text-center text-muted">Browse or drop your image here</div>
          </ImageSelector>
        </FileUploader>
      )}
      <div className="mt-4">
        <Row className="d-flex">
          {!_.isEmpty(oldGalleries) &&
            oldGalleries.map((gallery, i) => (
              <Col lg={3} key={i}>
                <div className="position-relative mb-3 ratio ratio-1x1 bg-light">
                  <div className="position-absolute">
                    <Image src={gallery.url} className="w-100" />
                    <RemoveFileBtn
                      type="button"
                      className="position-absolute top-0 end-0 bg-white shadow-sm border-0 rounded-circle"
                      onClick={() => handleRemoveOldGalleryClicked(gallery)}
                    >
                      <FontAwesomeIcon icon="close" />
                    </RemoveFileBtn>
                  </div>
                </div>
              </Col>
            ))}
          {!_.isEmpty(files) &&
            files.map((file, i) => (
              <Col lg={3} key={i}>
                <div className="position-relative mb-3 ratio ratio-1x1 bg-light">
                  <div className="position-absolute">
                    <Image src={URL.createObjectURL(file)} className="w-100" />
                    <RemoveFileBtn
                      type="button"
                      className="position-absolute top-0 end-0 bg-white shadow-sm border-0 rounded-circle"
                      onClick={() => handleRemoveFileClicked(file)}
                    >
                      <FontAwesomeIcon icon="close" />
                    </RemoveFileBtn>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export { GiveSetInfo, ReceiveSetInfo };
